import { IonicColor } from './ionic-color';

export class ToastMessage {
    text: string;
    type: IonicColor | string;
    position?: ToastPosition = ToastPosition.TOP_RIGHT;
    duration?: number;
    css?: string;

    constructor(text?: string, type?: IonicColor | string, position?: ToastPosition, duration?: number) {
        this.text = text;
        this.type = type;
        this.position = position ? position : ToastPosition.TOP_RIGHT;
        this.duration = duration ? duration : 60000;
    }
}

export enum ToastPosition {
    TOP_CENTER = 'toast-top-center',
    TOP_LEFT = 'toast-top-left',
    TOP_RIGHT = 'toast-top-right',
    BOTTOM_CENTER = 'toast-bottom-center',
    BOTTOM_LEFT = 'toast-bottom-left',
    BOTTOM_RIGHT = 'toast-bottom-right',
}
