import { AccountType } from './account-type';
import { Genders } from './gender';
import { CoverageType } from './user';
import { ContactCategory } from './contact-category';
import { UserCustomPropertyDto } from './user-custom-property';

export class LoginUserWithoutPasswordDto {
    accountType: AccountType;
    username: string;
    title: string;
    firstname: string;
    lastname: string;
    birthdate: string;
    gender: string = Genders.NONE;
    maritalStatus: string;
    email: string;
    healthInsurer: string;
    healthInsurerType: CoverageType; // siehe https://simplifier.net/basisprofil-de-r4/versicherungsart-de-basis
    healthInsuranceNumber: string;
    generalPractitionerName: string;
    generalPractitionerPhone: string;
    isEmployed: boolean; // type prüfen -> null oder undefined heißt keine Angabe
    job: string;
    company: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    addressAddition?: string;
    country?: string;
    phone: string;
    mobilePhone: string;
    nationality: string;
    contactCategory: ContactCategory;
    pseudonym?: string;
    usePseudonym: boolean;
    customPropertyDtos?: UserCustomPropertyDto[];
}
