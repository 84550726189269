import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { User } from '../../../auth/entities/user';
import { StyleService } from '../../../common/services/style/style.service';
import { BasePageConfig } from '../../../config/entities';
import { ConfigService } from '../../../config/services';
import { format, parseISO } from 'date-fns';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-user-short-info',
    templateUrl: './user-short-info.component.html',
    styleUrls: ['./user-short-info.component.scss'],
})
export class UserShortInfoComponent implements OnInit {
    @Input() nameOnly = false;
    isMobile = false;
    pageConfig: BasePageConfig;
    userForm: FormGroup;

    constructor(
        private styleService: StyleService,
        private configService: ConfigService,
        private formBuilder: FormBuilder,
    ) {}

    private _user: User;

    get user(): User {
        return this._user;
    }

    @Input() set user(user: User) {
        this._user = user;
        this.initUserForm();
    }

    ngOnInit() {
        this.isMobile = this.styleService.isMobile$;
        if (this.configService.config.pageConfigs) {
            this.pageConfig = this.configService.config.pageConfigs.find((page) => page.pageId === 'PATIENT_DETAIL');
        }
        this.initUserForm();
    }

    initUserForm() {
        const userName = this.user == null ? '' : this.user.username;
        const firstName = this.user == null ? '' : this.user.firstname;
        const lastName = this.user == null ? '' : this.user.lastname;
        const birthDate =
            this.user == null
                ? null
                : this.user.birthdate
                  ? `${format(parseISO(this.user.birthdate), 'dd.MM.yyyy')}`
                  : null;
        const name = this.user == null ? '' : `${this.user.lastname}, ${this.user.firstname}`;

        this.userForm = this.formBuilder.group({
            username: new FormControl({ value: userName, disabled: true }),
            firstName: new FormControl({ value: firstName, disabled: true }),
            lastName: new FormControl({ value: lastName, disabled: true }),
            birthDate: new FormControl({ value: birthDate, disabled: true }),
            name: new FormControl({ value: name, disabled: true }),
        });
    }
}
