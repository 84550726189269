// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  font-size: 90% !important;
}

.margin-bottom-segment-button {
  margin-bottom: 0 !important;
}

.border-bottom {
  border-bottom: 2px solid var(--ion-color-primary);
}

.dropdown-button {
  height: 80px;
}

ion-segment-button {
  min-width: auto;
}

.segment-name {
  white-space: pre-wrap;
  text-transform: initial;
  margin: 8px;
  font-size: 1rem !important;
  letter-spacing: inherit;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-segment/curafida-segment.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACI,iDAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,qBAAA;EACA,uBAAA;EACA,WAAA;EACA,0BAAA;EACA,uBAAA;EACA,kBAAA;AACJ","sourcesContent":["ion-label {\n    font-size: 90% !important;\n}\n\n.margin-bottom-segment-button {\n    margin-bottom: 0 !important;\n}\n\n.border-bottom {\n    border-bottom: 2px solid var(--ion-color-primary);\n}\n\n.dropdown-button {\n    height: 80px;\n}\n\nion-segment-button {\n    min-width: auto;\n}\n\n.segment-name {\n    white-space: pre-wrap;\n    text-transform: initial;\n    margin: 8px;\n    font-size: 1rem !important;\n    letter-spacing: inherit;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
