// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-subtitle {
  transform: translateY(0);
  white-space: normal;
  overflow: initial;
  font-size: smaller !important;
}

.mobile-label {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 11px !important;
  opacity: 0.55;
  margin-left: 20px;
  padding-top: 10px;
}

.radio-label {
  padding-top: 10px;
}

.radio-font-size {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-radio-button-input/curafida-radio-button-input.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,mBAAA;EACA,iBAAA;EACA,6BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".button-subtitle {\n    transform: translateY(0);\n    white-space: normal;\n    overflow: initial;\n    font-size: smaller !important;\n}\n\n.mobile-label {\n    margin-top: 0;\n    margin-bottom: 25px;\n    font-size: 11px !important;\n    opacity: 0.55;\n    margin-left: 20px;\n    padding-top: 10px;\n}\n\n.radio-label {\n    padding-top: 10px;\n}\n\n.radio-font-size {\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
