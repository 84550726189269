import { ActionType } from './action-type';

export class ActionEmitter<T> {
    actionType: ActionType;
    item: T;

    constructor(actionType?: ActionType, item?: T) {
        this.actionType = actionType;
        this.item = item;
    }
}
