import { Pipe, PipeTransform } from '@angular/core';
import { PageConfig } from '../entities';
import { CurafidaFeatureEnum } from '../entities/base-page-config';
import { FeatureConfigs } from '../../common/entities/curafida-frontend-configuration';

@Pipe({
    name: 'checkFeaturePipe',
    standalone: true,
})
export class CheckFeaturesPipe implements PipeTransform {
    transform(menuItems: PageConfig[], featuresConfiguration: FeatureConfigs) {
        if (!menuItems) {
            return menuItems;
        }
        return menuItems.filter((menuItem) => {
            const hasSubPage = menuItem.childPageConfigs?.length > 0;
            if (hasSubPage) {
                return menuItem.role.length === 0;
            } else {
                if (
                    featuresConfiguration[menuItem?.module]?.enabled ||
                    featuresConfiguration[menuItem?.module]?.enabled === undefined ||
                    menuItem.module === CurafidaFeatureEnum.common
                ) {
                    return menuItem.role.length === 0;
                }
            }
        });
    }
}
