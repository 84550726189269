import { Pipe, PipeTransform } from '@angular/core';
import { authorizationAction, HttpMethod, HypermediaResource } from './hateoas.model';

/**
 * Pipe a HypermediaResource to check for specific authorizations.
 */
@Pipe({
    name: 'authorization',
})
export class AuthorizationPipe implements PipeTransform {
    /**
     * Checks whether the permission for the desired action on the desired resource is available in the given HypermediaResource.
     * @param hypermediaResource - The current resource with the references (_links) to other potential resources.
     * @param name - The name of the hypermedia link you are looking for.
     * @param action - The action you are looking for. Can be one specific http method, or one wildcard of 'read' (GET) or 'write' (POST, PUT, PATCH, DELETE).
     */
    transform(hypermediaResource: HypermediaResource, name: string, action: string | authorizationAction): boolean {
        if (!hypermediaResource?._links) {
            return false;
        }
        const methods = hypermediaResource?._links[name]?.methods;
        if (!methods || methods.length < 1) {
            return false;
        }
        if (action === 'read') {
            return methods.includes(HttpMethod.GET);
        }
        if (action === 'write') {
            return (
                methods.includes(HttpMethod.POST) ||
                methods.includes(HttpMethod.PUT) ||
                methods.includes(HttpMethod.PATCH) ||
                methods.includes(HttpMethod.DELETE)
            );
        }
        if (action === 'readonly') {
            return (
                this.transform(hypermediaResource, name, 'read') && !this.transform(hypermediaResource, name, 'write')
            );
        }
        return methods.includes(<HttpMethod>action?.toUpperCase());
    }
}

/**
 * The negated variant of the \@AuthorizationPipe.
 * An easy way to check for missing Authorizations in html views (e. g. [disabled]="hypermediaResource|noAuthorization:'endpoint':'write'").
 */
@Pipe({
    name: 'noAuthorization',
})
export class NoAuthorizationPipe implements PipeTransform {
    constructor(private readonly authorizationPipe: AuthorizationPipe) {}

    transform(resource: HypermediaResource, name: string, action: string): boolean {
        return !this.authorizationPipe.transform(resource, name, action);
    }
}
