import { Exercise } from '../exercise';

export class TherapyExerciseDto {
    therapy_id: number;
    exercise_id: number;
    exercise?: Exercise;
    order: number;
    startOffset?: string;
    dueOffset?: string;
    endOffset?: string;

    constructor(therapy_id: number, exercise_id: number, order: number) {
        this.therapy_id = therapy_id;
        this.exercise_id = exercise_id;
        this.order = order;
    }
}

export class TherapyExercise extends TherapyExerciseDto {
    id: number;
    created_at: Date;
    updated_at: Date;
}
