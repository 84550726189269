// noinspection ES6UnusedImports

import { PatientCareRelationshipResource } from '../components/user-main-info/patient-care-relationship.resource';
import { AuthorizationPipe } from '../../hateoas/authorization.pipe';
import { HttpClient } from '@angular/common/http';
import { HypermediaResource } from '../../hateoas/hateoas.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PatientDossierService {
    constructor(
        private readonly authorizationPipe: AuthorizationPipe,
        private readonly httpClient: HttpClient,
    ) {}

    getPatientDossier$(patientDossier: HypermediaResource, endpoint: string) {
        if (!this.authorizationPipe.transform(patientDossier, endpoint, 'read')) {
            return;
        }

        return this.httpClient.get<PatientCareRelationshipResource[]>(patientDossier._links[endpoint].href);
    }

    deletePatientCareRelationships$(patientDossier: HypermediaResource) {
        return this.httpClient.delete(patientDossier._links.self.href);
    }

    createPatientCareRelationships$(
        patientDossier: HypermediaResource,
        endpoint: string,
        requesterUsername: string,
        patientUsername: string,
    ) {
        return this.httpClient.post<PatientCareRelationshipResource>(patientDossier._links[endpoint].href, {
            username: requesterUsername,
            patient: patientUsername,
        });
    }
}
