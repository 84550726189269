import { Pipe, PipeTransform } from '@angular/core';
import { UserRoles } from '../../auth/entities/user';

@Pipe({
    standalone: true,
    name: 'userRoleFilter',
    pure: true,
})
export class UserRoleFilterPipe implements PipeTransform {
    transform(roles: UserRoles[]): UserRoles[] {
        const validRoles = [
            UserRoles.PATIENT,
            UserRoles.CAREGIVER,
            UserRoles.CATALOG_MANAGER,
            UserRoles.USER_MANAGER,
            UserRoles.SUPERVISOR,
            UserRoles.KNOWLEDGE_MANAGER,
        ];
        return roles.filter((role) => validRoles.includes(role));
    }
}
