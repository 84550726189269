import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Logger, LoggingService } from '../logging/logging.service';
import { SplashScreenStateService } from '../common/services/splash-screen-state.service';

@Injectable({
    providedIn: 'root',
})
export class SplashGuardService {
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private splashScreenStateService: SplashScreenStateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async canActivate(): Promise<boolean | UrlTree> {
        this.log.debug('canActivate()');
        this.splashScreenStateService.stop();
        return true;
    }
}
