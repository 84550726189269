import { User } from '../../../auth/entities/user';
import { ExerciseType } from '../exerciseSession';
import { TherapyGoal } from '../therapy-goal/therapy-goal';
import { Therapy } from './therapy';

export interface TherapyPatchDto {
    title: string;
    description: string;
    startDate?: Date;
    endDate?: Date;
    registrationDeadline?: Date;
    notes?: string;
}

export class TherapyDto {
    title: string;
    description: string;
    templateId: number;
    startDate?: Date;
    endDate?: Date;
    registrationDeadline?: Date;
    therapists?: User[];
    therapySessionsPerWeek: number;
    notes: string;
    exerciseType: ExerciseType;
    isLocked: boolean;
    autoLock: boolean;
    trainingRestDays: number;
    therapyGoal?: TherapyGoal | string;
    parentId?: number;
    childOrder?: number;
}

export class TherapyDtoBuilder {
    private createTherapyDto = new TherapyDto();

    copyFromTherapy(therapy: Therapy): TherapyDtoBuilder {
        this.createTherapyDto.title = therapy.title;
        this.createTherapyDto.description = therapy.description;
        this.createTherapyDto.templateId = therapy.templateId;
        this.createTherapyDto.startDate = therapy.startDate;
        this.createTherapyDto.endDate = therapy.endDate;
        this.createTherapyDto.therapists = therapy.therapists;
        this.createTherapyDto.therapySessionsPerWeek = therapy.therapySessionsPerWeek;
        this.createTherapyDto.notes = therapy.notes;
        this.createTherapyDto.exerciseType = therapy.exerciseType;
        this.createTherapyDto.isLocked = therapy.isLocked;
        return this;
    }

    build(): TherapyDto {
        return this.createTherapyDto;
    }
}
