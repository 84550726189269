// noinspection ES6UnusedImports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SortTherapyModuleListService } from '../sort-therapy-module-list.service';
import { Content, ContentDto } from '../../entities/content';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileContentService } from '../../../common/services/content/file-content.service';
import { SafeUrl } from '@angular/platform-browser';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class ExerciseContentsService {
    constructor(
        public router: Router,
        protected http: HttpClient,
        private fileContentService: FileContentService,
    ) {}

    async getExerciseContents(
        offset?: number,
        limit?: number,
        filter?: string,
        sortOrder?: SortOrder,
        sortBy?: SortBy,
        categoryId?: string,
    ): Promise<PaginatedResponse<Content[]>> {
        return this.http
            .get<
                PaginatedResponse<Content[]>
            >(SortTherapyModuleListService.setSortParam(`${ApiService.url}exerciseContents`, offset, limit, filter, sortOrder, null, sortBy, categoryId, null), ApiService.options)
            .toPromise();
    }

    async getTempDownloadUrl(contentId: string, isInline?: boolean): Promise<string> {
        const cacheResponse = await this.fileContentService.getContentResponseFromCache(contentId);
        if (cacheResponse) {
            return URL.createObjectURL(await cacheResponse.blob());
        }
        const inlineQueryParam = isInline ? '?isInline=true' : '?isInline=false';
        const url = `${ApiService.url}exerciseContents/${contentId}/download/temp`;
        const tempDownload = await this.http.post<{ downloadUrl: string }>(url, null, ApiService.options).toPromise();
        tempDownload.downloadUrl = tempDownload.downloadUrl.replace('contents', 'exerciseContents');
        return ApiService.url + tempDownload.downloadUrl + inlineQueryParam;
    }

    async getExerciseContentUuid(contentUuid: string): Promise<Content> {
        return this.http
            .get<Content>(`${ApiService.url}exerciseContents/${contentUuid}`, ApiService.options)
            .toPromise();
    }

    async updateExerciseContent(contentUuid: string, contentDto: ContentDto): Promise<Content> {
        return this.http
            .put<Content>(`${ApiService.url}exerciseContents/${contentUuid}`, contentDto, ApiService.options)
            .toPromise();
    }

    async deleteExerciseContentUuid(contentUuid: string) {
        return this.http.delete(`${ApiService.url}exerciseContents/${contentUuid}`, ApiService.options).toPromise();
    }

    async postContentFileUpload(file: FormData): Promise<Content> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const options = { headers, withCredentials: true };
        return this.http.post<Content>(`${ApiService.url}exerciseContents/file-upload`, file, options).toPromise();
    }

    createContentFileWithProgressState(formData: FormData): Observable<any> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http
            .post<Content>(`${ApiService.url}exerciseContents/file-upload`, formData, {
                reportProgress: true,
                observe: 'events',
                headers,
                withCredentials: true,
            })
            .pipe(catchError(this.errorMgmt));
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

    async createExerciseContent(contentDto: ContentDto): Promise<Content> {
        return this.http.post<Content>(`${ApiService.url}exerciseContents`, contentDto, ApiService.options).toPromise();
    }

    async getObjectURLContentFromUrl(contentUuid: string, preferredImageFormat?: ContentFormatType) {
        const cacheResponse = await this.fileContentService.getContentResponseFromCache(contentUuid);
        if (cacheResponse) {
            return URL.createObjectURL(await cacheResponse.blob());
        }
        const url = new URL(`${ApiService.url}exerciseContents/${contentUuid}/download`);
        return this.fileContentService.createSafeObjectUrlFromUrl(url.toString(), null, preferredImageFormat);
    }

    async openAttachmentObjectURLinNewWindow(content: Content, preferredImageFormat = ContentFormatType.THUMBNAIL) {
        const url = new URL(`${ApiService.url}exerciseContents/${content.uuid}/download`);
        if (Capacitor.isNativePlatform()) await this.fileContentService.openObjectURLinMobileDevice(url.toString());
        if (!Capacitor.isNativePlatform()) {
            await this.fileContentService.openObjectURLinNewWindow(url.toString(), null, preferredImageFormat);
        }
    }

    async downloadObjectURLinNewWindow(content: Content) {
        const url = new URL(`${ApiService.url}exerciseContents/${content.uuid}/download`);
        if (Capacitor.isNativePlatform()) await this.fileContentService.openObjectURLinMobileDevice(url.toString());
        if (!Capacitor.isNativePlatform()) {
            await this.fileContentService.downloadObjectURLinBrowser(url.toString(), content.origFileName);
        }
    }

    getObservableContent(content: Content) {
        return this.fileContentService.getObservableBlobFromUrl(
            new URL(`${ApiService.url}exerciseContents/${content.uuid}/download`).toString(),
        );
    }
}
