import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueParameter',
})
export class ValueParameterPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string {
        if (value === undefined) {
            return 'Nicht gemessen';
        } else {
            return value.toString();
        }
    }
}
