import { MigrationInterface, QueryRunner } from 'typeorm';

export class AddActiveFlagToQuestionnaire1645171018030 implements MigrationInterface {
    name = 'AddActiveFlagToQuestionnaire1645171018030';

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`ALTER TABLE CurafidaQuestionnaire ADD active BOOLEAN;`);
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query('ALTER TABLE CurafidaQuestionnaire DROP COLUMN active;');
    }
}
