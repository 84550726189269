import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginUserWithoutPasswordDto, User } from '../../../auth/entities/user';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { UsersService } from '../../services/user';
import { SelfRegistrationService } from '../../services/self-registration';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { CheckoutPageSteps } from '../../../shop/components/checkout/checkout-page-steps.enum';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { Platform } from '@ionic/angular';
import { isEMail } from '../../../common/validators/curafida-validators';
import { StyleService } from '../../../common/services/style/style.service';
import { HttpClient } from '@angular/common/http';
import { AuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { UserCustomPropertyDto } from '../../../auth/entities/user/user-custom-property';

@Component({
    selector: 'lib-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    readonly user = new User();
    @Output()
    readonly registerState: EventEmitter<{ step: CheckoutPageSteps; username: string }> = new EventEmitter<{
        step: CheckoutPageSteps;
        username: string;
    }>();

    @Input()
    isMobile: boolean;
    @Input()
    step: CheckoutPageSteps;

    registerForm: FormGroup;

    constructor(
        protected toastService: ToastService,
        protected formBuilder: FormBuilder,
        public router: Router,
        private usersService: UsersService,
        readonly selfRegistrationService: SelfRegistrationService,
        private platform: Platform,
        private styleService: StyleService,
        private readonly httpClient: HttpClient,
        private readonly authorizationPipe: AuthorizationPipe,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    async ngOnInit(): Promise<void> {
        await this.initRegisterForm();
        this.subscriptions.push(
            this.platform.resize.subscribe(() => {
                this.isMobile = this.styleService.isMobile$;
            }),
            this.registerForm.controls.emailConfirmation.valueChanges.pipe(debounceTime(400)).subscribe(async () => {
                if (this.registerForm.controls.email.value !== this.registerForm.controls.emailConfirmation.value) {
                    this.registerForm.controls.emailConfirmation.setErrors({ same: true });
                }
            }),
            this.registerForm.controls.username.valueChanges.pipe(debounceTime(400)).subscribe(async (value) => {
                if (this.registerForm.controls.username.dirty && this.registerForm.controls.username.valid) {
                    const usernameCheck = await this.usersService.isUsername(value);
                    if (usernameCheck.isUsername) {
                        this.registerForm.controls.username.setErrors({ incorrect: true });
                    }
                }
            }),
            this.usersService
                .getUsernameSuggestion(this.registerForm, 'firstname', 'lastname')
                .subscribe(async (value) => {
                    this.registerForm.controls.username.setValue(value);
                }),
            this.selfRegistrationService.selected$
                .pipe(
                    tap(() => {
                        this.selfRegistrationService.model$.value.customFormProps?.forEach((prop) =>
                            this.registerForm.removeControl(prop.getFormControlName()),
                        );
                    }),
                    filter((it) => this.authorizationPipe.transform(it, 'customFormConfig', 'read')),
                )
                .subscribe((it) => this.selfRegistrationService.fetchCustomProps(it)),
            this.selfRegistrationService.customFormProperties$.subscribe((it) => {
                it.forEach((property) =>
                    this.registerForm.addControl(
                        property.getFormControlName(),
                        new FormControl(
                            {
                                value: undefined,
                                disabled: false,
                            },
                            property.required ? [Validators.required] : [],
                        ),
                    ),
                );
            }),
        );
        this.selfRegistrationService.init();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((it) => it.unsubscribe());
    }

    async saveUserInformation(): Promise<void> {
        const registerUser = await this.selfRegistrationService.postPatientSelfRegistration(
            this.createLoginUserWithoutPasswordDto(),
        );

        if (this.router.url.includes(RoutingSegment.REGISTER)) {
            this.router.navigate([RoutingSegment.REGISTER, RoutingSegment.SUCCESSFUL]);
        } else {
            this.registerState.emit({ step: CheckoutPageSteps.REQUIREMENTS, username: registerUser.username });
        }
    }

    private initRegisterForm() {
        const username = this.user?.username || '';
        const firstname = this.user?.firstname || '';
        const lastname = this.user?.lastname || '';
        const birthdate = this.user?.birthdate;
        const email = this.user?.email || '';
        const emailConfirmation = this.user?.email || '';
        const phone = this.user?.phone || '';
        this.registerForm = this.formBuilder.group({
            username: new FormControl(
                {
                    value: username,
                    disabled: true,
                },
                Validators.required,
            ),
            firstname: new FormControl(
                {
                    value: firstname,
                    disabled: false,
                },
                Validators.required,
            ),
            lastname: new FormControl(
                {
                    value: lastname,
                    disabled: false,
                },
                Validators.required,
            ),
            birthdate: new FormControl({
                value: birthdate,
                disabled: false,
            }),
            email: new FormControl(
                {
                    value: email,
                    disabled: false,
                },
                [Validators.required, Validators.email, isEMail],
            ),
            emailConfirmation: new FormControl(
                {
                    value: emailConfirmation,
                    disabled: false,
                },
                [Validators.required, Validators.email, isEMail],
            ),
            phone: new FormControl({
                value: phone,
                disabled: false,
            }),
        });
    }

    private createLoginUserWithoutPasswordDto() {
        const userWithoutPasswordDto = new LoginUserWithoutPasswordDto();
        userWithoutPasswordDto.username = this.registerForm.controls.username.value;
        userWithoutPasswordDto.firstname = this.registerForm.value.firstname;
        userWithoutPasswordDto.lastname = this.registerForm.value.lastname;
        userWithoutPasswordDto.gender = this.registerForm.value.gender;
        userWithoutPasswordDto.birthdate = this.registerForm.value.birthdate ? this.registerForm.value.birthdate : null;
        userWithoutPasswordDto.phone = this.registerForm.value.phone;
        userWithoutPasswordDto.email = this.registerForm.value.email;
        userWithoutPasswordDto.customPropertyDtos = this.selfRegistrationService.customFormProperties?.map(
            (it) => new UserCustomPropertyDto(it.uuid, this.registerForm.controls[it.getFormControlName()].value),
        );
        return userWithoutPasswordDto;
    }
}
