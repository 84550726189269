import { ExerciseSession } from './exercise-session';
import { ExerciseSessionState, ExerciseSessionUserState } from './exercise-session-state';
import { ExerciseSessionOfUser } from './exercise-session-of-user';
import { ExerciseSessionOfTherapist } from './exercise-session-of-therapist';

export class ExerciseSessionUserFrontend extends ExerciseSession {
    startTime: string;
    disabled = false;
    titleWithTherapyName;
    exerciseSessionUserState: ExerciseSessionState;
}

export class ExerciseSessionOfUserFrontend extends ExerciseSessionOfUser {
    startTime: string;
    disabled = false;
    titleWithTherapyName;
    duration: string;
    therapy_id: string;
    exerciseSessionUserState: ExerciseSessionUserState;
}

export class ExerciseTherapistOfUserFrontend extends ExerciseSessionOfTherapist {
    startTime: string;
    disabled = false;
    titleWithTherapyName;
    duration: string;
    exerciseSessionUserState: ExerciseSessionState;
}
