// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BuildType } from '../../../../libs/config/entities/build-type';
import { CurafidaFrontendConfiguration } from '../../../../libs/common/entities/curafida-frontend-configuration';

export const environment: CurafidaFrontendConfiguration = {
    appDisplayName: undefined,
    appId: undefined,
    features: undefined,
    developmentStage: BuildType.dev,
    keycloak: {
        url: undefined,
        realm: undefined,
        clientId: undefined,
        kcAdapterOnMobile: 'cordova',
    },
    theme: {
        logoUrl: 'assets/extra/logo.png',
        faviconUrl: 'assets/extra/favicon.png',
        colors: {
            primary: '#0096C8',
            primaryContrast: '#ffffff',
        },
    },
};
