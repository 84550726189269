import { Content } from '../../therapy/entities/content';
import { MyMedaxQuestionnaireSubmission } from '../../my-medax/entities/my-medax-questionnaire-submission';
import { Order } from './order';
import { OrderQuestionnaire } from './order-questionnaire';

export class OrderContent {
    uuid: string;
    orderUuid: string;
    order?: Order;
    contentUuid: string;
    content?: Content;
    parentUuid?: string;
    orderQuestionnaireUuid?: string;
    orderQuestionnaire?: OrderQuestionnaire;
    creatorUsername: string;
    isFindingContent?: boolean;
    myMedaxQuestionnaireSubmissionUuid?: string;
    myMedaxQuestionnaireSubmission?: MyMedaxQuestionnaireSubmission;
    created_at: string;
    updated_at: string;
    name: string;
    mimeType: string;
    writeLock?: string;
    isPdfReport: boolean;
}
