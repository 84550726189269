import { Capacitor } from '@capacitor/core';

export class MyMedaxRedirection {
    private readonly baseUrl: URL;

    private _isActive: boolean;

    constructor(baseUrl: string, isActive = true) {
        this.baseUrl = new URL(baseUrl.slice(-1) === '/' ? baseUrl.slice(0, -1) : baseUrl);
        this._isActive = isActive;
    }

    get isActive() {
        return this._isActive;
    }

    url() {
        if (!this.isActive) {
            throw new Error('Mymedax redirection is inactive and you are building a redirection url!');
        }
        const copy = new URL(this.baseUrl);
        copy.searchParams.append('fromMyMedax', 'true');
        return copy;
    }

    leadingToTask(id: number | string) {
        this.baseUrl.searchParams.delete('taskId');
        this.baseUrl.searchParams.append('taskId', id.toString());
        return this;
    }

    withActivationByCapacitor() {
        this._isActive = !Capacitor.isNativePlatform();
        return this;
    }

    static forCurrentLocation() {
        return new MyMedaxRedirection(window.location.href);
    }
}
