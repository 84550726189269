// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  white-space: pre-wrap;
}

.h3,
h3 {
  padding-top: 1rem;
  font-size: 120% !important;
  font-weight: bolder;
}

.h5,
h5 {
  padding-top: 2rem;
  font-size: 110% !important;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-json-read/curafida-json-reader.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;;EAEI,iBAAA;EACA,0BAAA;EACA,mBAAA;AACJ;;AACA;;EAEI,iBAAA;EACA,0BAAA;EACA,mBAAA;AAEJ","sourcesContent":["p {\n    white-space: pre-wrap;\n}\n\n.h3,\nh3 {\n    padding-top: 1rem;\n    font-size: 120% !important;\n    font-weight: bolder;\n}\n.h5,\nh5 {\n    padding-top: 2rem;\n    font-size: 110% !important;\n    font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
