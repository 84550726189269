import { Questionnaire, QuestionnaireResponse } from 'fhir/r4';
import { CommentMeasurements } from '../examination-states';
import { MeasurementType } from './measurement-type';
import { ButtonMeasurementInfo } from '../../../../apps/mia/src/app/pages/member/examination/button-measurement-info';
import { plainToInstance } from 'class-transformer';
import { SensorType } from '@danyalwe/capacitor-sensors';

export enum CosinussMeasurementType {
    HEARTRATE = 'HEARTRATE',
    TEMPERATURE = 'TEMPERATURE',
    SPO2 = 'SPO2',
}

export enum PulseOximeterMeasurementType {
    PULSE = 'PULSE',
    SPO2 = 'SPO2',
}

export enum MediaType {
    ECG = 'EKG',
    NOTES = 'Notizen',
    FORMS = 'Formulare',
    VIDEOS = 'Videos',
    MWT = '6 Minuten Gehtest',
}

export abstract class MeasurementContent {
    type: MeasurementType;
    hasAssociatedFiles: boolean;
    notMeasurable?: boolean;
    notMeasured?: boolean;

    // Override this method in all subclasses which store files
    async getFileNames(): Promise<string[]> {
        return [];
    }

    abstract createButton(measurement: IMeasurement): ButtonMeasurementInfo;

    abstract isMeasured(): boolean;

    svgRadioPath(measurement: IMeasurement): string {
        let res = 'assets/Mona-Icons/b-w_Nicht_gemessen.svg';
        if (this.isMeasured()) {
            res = 'assets/Mona-Icons/b-w_Gemessen.svg';
        } else if (measurement?.notMeasurable) {
            res = 'assets/Mona-Icons/b-w_Nicht_messbar.svg';
        } else if (measurement?.notMeasured) {
            res = 'assets/Mona-Icons/b-w_Nicht_messbar_grau.svg';
        }
        return res;
    }

    stateDescription(measurement: IMeasurement): string {
        let res: string = CommentMeasurements.noMeasured;
        if (this.isMeasured()) {
            res = this.getValueString();
        } else if (measurement?.notMeasurable) {
            res = CommentMeasurements.noMeasurable;
        } else if (measurement?.notMeasured) {
            res = CommentMeasurements.noMeasured;
        }
        return res;
    }

    abstract getValueString(): string;
}

export class MeasurementContentFactory {
    static create(measurementContent: MeasurementContent, type: MeasurementType) {
        if (!measurementContent) {
            measurementContent = {} as MeasurementContent;
        }
        switch (type) {
            case MeasurementType.BLOOD_PRESSURE:
                return plainToInstance(MeasurementContentBloodPressure, measurementContent);
            case MeasurementType.BODY_WEIGHT:
                return plainToInstance(MeasurementContentBodyWeight, measurementContent);
            case MeasurementType.ECG:
                return plainToInstance(MeasurementContentECG, measurementContent);
            case MeasurementType.SPO2:
                return plainToInstance(MeasurementContentSPO2, measurementContent);
            case MeasurementType.EMPTY:
                return plainToInstance(MeasurementContentNote, measurementContent);
            case MeasurementType.BLOOD_SUGAR_LEVEL:
                return plainToInstance(MeasurementContentBloodSugarLevel, measurementContent);
            case MeasurementType.HEIGHT:
                return plainToInstance(MeasurementContentHeight, measurementContent);
            case MeasurementType.OTOSCOPE:
                return plainToInstance(MeasurementContentOtoscope, measurementContent);
            case MeasurementType.SPIROMETRY:
                return plainToInstance(MeasurementContentSpirometry, measurementContent);
            case MeasurementType.STETHOSCOPE:
                return plainToInstance(MeasurementContentStethoscope, measurementContent);
            case MeasurementType.TEMPERATURE:
                return plainToInstance(MeasurementContentTemperature, measurementContent);
            case MeasurementType.WOUND_DOC:
                return plainToInstance(MeasurementContentWoundDoc, measurementContent);
            case MeasurementType.FHIR_QUESTIONNAIRE:
                return plainToInstance(MeasurementContentFhirQuestionnaire, measurementContent);
            case MeasurementType.PULSE:
                return plainToInstance(MeasurementContentPulse, measurementContent);
            case MeasurementType.DOCUMENT:
                return plainToInstance(MeasurementContentDocument, measurementContent);
            case MeasurementType.WALKING_TEST:
                return plainToInstance(MeasurementContentWalkingTest, measurementContent);
            default:
                throw Error(`MeasurementContentFactory: unknown type: ${type}`);
        }
    }
}

export class MeasurementContentHeight extends MeasurementContent {
    readonly type = MeasurementType.HEIGHT;
    readonly hasAssociatedFiles = false;

    // cm
    height: number;

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Körpergröße';
        info.svgName = 'assets/Mona-Icons/b_Koerpergroesse.svg';
        info.uriRedirection = 'member/measurements/body-height/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.height;
    }

    getValueString(): string {
        return this.height + ' cm';
    }
}

export class MeasurementContentBodyWeight extends MeasurementContent {
    readonly type = MeasurementType.BODY_WEIGHT;
    readonly hasAssociatedFiles = false;
    // kg
    weight: number | null;

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Körpergewicht';
        info.svgName = 'assets/Mona-Icons/b-w_Koerpergewicht.svg';
        info.uriRedirection = 'member/measurements/body-weight/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.weight;
    }

    getValueString(): string {
        return this.weight + ' kg';
    }
}

export class MeasurementContentTemperature extends MeasurementContent {
    readonly type = MeasurementType.TEMPERATURE;
    readonly hasAssociatedFiles = false;
    // degree celsius
    temperature: number;

    static getExample(): MeasurementContentTemperature {
        return { type: MeasurementType.TEMPERATURE, temperature: 37.5 } as MeasurementContentTemperature;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Temperatur';
        info.svgName = 'assets/Mona-Icons/b_Temperatur.svg';
        info.uriRedirection = 'member/measurements/temperature/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.temperature;
    }

    getValueString(): string {
        return this.temperature + ' °C';
    }
}

export class MeasurementQuestionnaireResponse {
    questionnaireResponse?: QuestionnaireResponse;
    responsePdfContentUuid?: string;
    questionnaire?: Questionnaire;
    questionnaireTitle?: string;
}

export class MeasurementContentFhirQuestionnaire extends MeasurementContent {
    readonly type = MeasurementType.FHIR_QUESTIONNAIRE;
    readonly hasAssociatedFiles = false;
    measurementQuestionnaireResponses: MeasurementQuestionnaireResponse[];

    static getExample(): MeasurementContentFhirQuestionnaire {
        return {
            type: MeasurementType.FHIR_QUESTIONNAIRE,
            measurementQuestionnaireResponses: [],
        } as MeasurementContentFhirQuestionnaire;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Fragebogen';
        info.buttonSubtitle = 'Nicht erfasst';
        info.svgName = 'assets/Mona-Icons/b-w_Fragebogen.svg';
        info.uriRedirection = `member/measurements/questionnaire`;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.measurementQuestionnaireResponses?.length;
    }

    getValueString(): string {
        return 'Erfasst';
    }
}

export class MeasurementContentOtoscope extends MeasurementContent {
    readonly type = MeasurementType.OTOSCOPE;
    readonly hasAssociatedFiles = true;
    private images: OtoscopeImage[] = [];

    public addOrReplaceImage(otoscopeImage: OtoscopeImage) {
        if (this.getImageByLocation(otoscopeImage.location)) {
            this.removeImage(otoscopeImage.location);
        }
        this.images.push(otoscopeImage);
    }

    public getImageByLocation(location: EarPart) {
        return this.images.find((otoscopeImageStored) => otoscopeImageStored.location === location);
    }

    public removeImage(location: EarPart) {
        this.images = this.images.filter((image) => {
            return image.location !== location;
        });
    }

    public getImages(): OtoscopeImage[] {
        return this.images;
    }

    public removeImageFromFilename(filename: string) {
        this.images = this.images.filter((image) => {
            return image.uploadFilename !== filename;
        });
    }

    public removeTempImages() {
        this.images = this.images.filter((image) => {
            return !image.isTemporary;
        });
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Otoskop';
        info.svgName = 'assets/Mona-Icons/b-w_Otoskop.svg';
        info.uriRedirection = 'member/measurements/otoscope/';
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.getImages()?.length;
    }

    getValueString(): string {
        return this.getImages().length + ' Bilder';
    }
}

export class MeasurementContentStethoscope extends MeasurementContent {
    public readonly type = MeasurementType.STETHOSCOPE;
    public readonly hasAssociatedFiles = true;
    private recordings: StethoscopeRecording[] = [];

    public addOrReplaceRecording(recording: StethoscopeRecording) {
        if (this.getRecordingByLocation(recording.location)) {
            this.removeRecording(recording.location);
        }
        this.recordings.push(recording);
    }

    public getRecordingByLocation(location: HeartZone | FrontLungZone | BackLungZone | TracheaZone) {
        return this.recordings.find((recordStored) => recordStored.location === location);
    }

    public removeRecording(location: HeartZone | FrontLungZone | BackLungZone | TracheaZone) {
        this.recordings = this.recordings.filter((recording) => {
            return recording.location !== location;
        });
    }

    public getRecordings(): StethoscopeRecording[] {
        return this.recordings;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Stethoskop';
        info.svgName = 'assets/Mona-Icons/b_Stethoskop.svg';
        info.uriRedirection = 'member/measurements/stethoscope/';
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.recordings?.length;
    }

    getValueString(): string {
        return 'Aufgezeichnet';
    }
}

export class MeasurementContentBloodPressure extends MeasurementContent {
    // TODO: define attributes
    readonly type = MeasurementType.BLOOD_PRESSURE;
    readonly hasAssociatedFiles = false;
    systolic: number | null;
    diastolic: number | null;

    static getExample(): MeasurementContentBloodPressure {
        return {
            type: MeasurementType.BLOOD_PRESSURE,
            systolic: 120,
            diastolic: 80,
        } as MeasurementContentBloodPressure;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Blutdruck';
        info.svgName = 'assets/Mona-Icons/b_Blutdruck.svg';
        info.uriRedirection = 'member/measurements/blood-pressure/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!(this.diastolic && this.systolic);
    }

    getValueString(): string {
        return this.systolic + ' / ' + this.diastolic + ' mmHg';
    }
}

export class MeasurementContentBloodSugarLevel extends MeasurementContent {
    // TODO: define attributes
    readonly type = MeasurementType.BLOOD_SUGAR_LEVEL;
    readonly hasAssociatedFiles = false;
    glucose: number | null;
    unit: string; // 'mg/dl' | 'mmol/l';

    static getExample(): MeasurementContentBloodSugarLevel {
        return { type: MeasurementType.BLOOD_SUGAR_LEVEL, glucose: 37.5 } as MeasurementContentBloodSugarLevel;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Blutzucker';
        info.svgName = 'assets/Mona-Icons/b_Blutzucker.svg';
        info.uriRedirection = 'member/measurements/blood-sugar/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.glucose;
    }

    getValueString(): string {
        return this.glucose + ' ' + this.unit;
    }
}

export class MeasurementContentSPO2 extends MeasurementContent {
    // TODO: define attributes
    readonly type = MeasurementType.SPO2;
    readonly hasAssociatedFiles = false;
    oxygenSaturation: number | null;

    static getExample(): MeasurementContentSPO2 {
        return { type: MeasurementType.SPO2, oxygenSaturation: 37.5 } as MeasurementContentSPO2;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'SpO2';
        info.svgName = 'assets/Mona-Icons/b-w_SPO2.svg';
        info.uriRedirection = 'member/measurements/pulse-oximeter/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.oxygenSaturation;
    }

    getValueString(): string {
        return this.oxygenSaturation + ' %';
    }
}

export class EcgRecord {
    uploadFilename: string;
    note?: string;
}

export class MeasurementContentECG extends MeasurementContent {
    readonly type = MeasurementType.ECG;
    readonly hasAssociatedFiles = true;
    ecgFileName: string;

    /**
     * Returns a single item list with the ECG data filename
     */
    async getFileNames(): Promise<string[]> {
        return [this.ecgFileName];
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'EKG';
        info.disabled = false;
        info.svgName = 'assets/Mona-Icons/b_EKG.svg';
        info.uriRedirection = 'member/measurements/ecg/';
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.ecgFileName;
    }

    getValueString(): string {
        return 'Aufgezeichnet';
    }
}

export class MeasurementContentDocument extends MeasurementContent {
    readonly type = MeasurementType.DOCUMENT;
    readonly hasAssociatedFiles = true;
    images: string[];

    /**
     * Returns a list of wound doc image file names
     */
    async getFileNames(): Promise<string[]> {
        return this.images;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        // TODO: Define attributes for this button
        return null;
    }

    isMeasured(): boolean {
        return !!this.images?.length;
    }

    getValueString(): string {
        return this.images?.length + ' Dokumente';
    }
}

export class MeasurementContentSpirometry extends MeasurementContent {
    // TODO: define attributes
    readonly type = MeasurementType.SPIROMETRY;
    readonly hasAssociatedFiles = false;
    fev1: number;

    static getExample(): MeasurementContentSpirometry {
        return { type: MeasurementType.SPIROMETRY, fev1: 37.5 } as MeasurementContentSpirometry;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Spirometrie';
        info.svgName = 'assets/Mona-Icons/b-w_Spirometrie.svg';
        info.uriRedirection = 'member/measurements/spirometer/';
        info.simpleMeasurement = true;
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.fev1;
    }

    getValueString(): string {
        return this.fev1 + ' l';
    }
}

export class MeasurementContentPulse extends MeasurementContent {
    readonly type = MeasurementType.PULSE;
    readonly hasAssociatedFiles = false;
    bpm: number;

    static getExample(): MeasurementContentPulse {
        return { type: MeasurementType.PULSE, bpm: 60 } as MeasurementContentPulse;
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        // TODO: Define attributes for this button
        return null;
    }

    isMeasured(): boolean {
        return !!this.bpm;
    }

    getValueString(): string {
        return this.bpm + ' puls';
    }
}

export class MeasurementContentWoundDoc extends MeasurementContent {
    readonly type = MeasurementType.WOUND_DOC;
    readonly hasAssociatedFiles = true;
    images: WoundDocImage[];

    /**
     * Returns a list of wound doc image file names
     */
    async getFileNames(): Promise<string[]> {
        return this.images.map((woundDocImg: WoundDocImage) => woundDocImg.uploadFilename);
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Wunddoku';
        info.svgName = 'assets/Mona-Icons/b-w_Wunddoku.svg';
        info.uriRedirection = 'member/measurements/wound-doc/';
        info.measurementType = this.type;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.images?.length;
    }

    getValueString(): string {
        return this.images?.length + ' Bilder';
    }
}

export class MeasurementContentNote extends MeasurementContent {
    // TODO: define attributes
    readonly type = MeasurementType.EMPTY;
    readonly hasAssociatedFiles = false;
    noteMeasurement: IMeasurement;

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        const info = new ButtonMeasurementInfo();
        info.buttonTitle = 'Notizen';
        info.svgName = 'assets/Mona-Icons/b-w_Fragebogen.svg';
        info.uriRedirection = 'member/measurements/questionnaires/';
        info.measurementType = this.type;
        this.noteMeasurement = measurement;
        info.buttonSubtitle = this.stateDescription(measurement);
        info.svgRadio = this.svgRadioPath(measurement);
        return info;
    }

    isMeasured(): boolean {
        return !!this.noteMeasurement?.note;
    }

    getValueString(): string {
        return 'Erfasst';
    }
}

export class MeasurementContentWalkingTest extends MeasurementContent {
    readonly type = MeasurementType.WALKING_TEST;
    readonly hasAssociatedFiles = true;
    steps: SensorDto;
    accelerometer: SensorDto;
    linAccelerometer: SensorDto;
    gyroscope: SensorDto;
    //orientation?: SensorDto;
    magnetometer?: SensorDto;
    geolocation?: SensorDto;

    async getFileNames(): Promise<string[]> {
        return [
            this.steps.uploadFilename,
            this.accelerometer.uploadFilename,
            this.linAccelerometer.uploadFilename,
            this.gyroscope.uploadFilename,
            //this.orientation?.uploadFilename,
            this.magnetometer?.uploadFilename,
            this.geolocation?.uploadFilename,
        ];
    }

    getDTOs(): SensorDto[] {
        return [
            this.steps,
            this.accelerometer,
            this.linAccelerometer,
            this.gyroscope,
            //this.orientation,
            this.magnetometer,
            this.geolocation,
        ];
    }

    createButton(measurement: IMeasurement): ButtonMeasurementInfo {
        // TODO
        return null;
    }

    isMeasured(): boolean {
        return !!this.getFileNames?.length;
    }

    getValueString(): string {
        return this.getFileNames?.length + ' Dokumente';
    }
}

export enum AdditionalSensorTypes {
    GEOLOCATION = 23,
}

/**
 * Encapsulates sensor data files
 */
export class SensorDto {
    contentUuid?: string;
    uploadFilename: string;
    sensorType: SensorType | AdditionalSensorTypes; // since SensorType of external plugin does not provide all sensors
}

export interface IMeasurement {
    type: MeasurementType;
    note?: string;
    notMeasurable?: boolean;
    notMeasured?: boolean;
}

export class MeasurementGeneric<Type extends MeasurementContent> {
    measurementContent: Type;

    public setParameter = (measurementType) => {
        // eslint-disable-next-line no-console
    };
}

/**
 * Wunddoku - Gewebetyp
 */
export enum SkinTissue {
    LOOSE = 'LOOSE', // LOCKER
    ADHESIVE = 'ADHESIVE', // HAFTEND
    SOFT = 'SOFT', // WEICH
    FIRM = 'FIRM', // HART
    HEALED = 'HEALED', // GEHEILT
}

/**
 * Wunddoku - Granulationsgewebe
 */
export enum GranulationTissue {
    LIGHT = 'LIGHT', // HELL,
    FLESHLY_RED = 'FLESHLY_RED', // FEISCHIG_ROT
    ALTROSA = 'ALTROSA', // ALTROSA
}

/**
 * Wunddoku - Exsudat und Geruch
 */
export enum Exudate {
    BLOODY = 'BLOODY', // BLUTIG
    SEROUS = 'SEROUS', // SERÖS
    WATERY_CLEAR = 'WATERY_CLEAR', // WÄSSRIG_KLAR
    WATERY_LIGHT = 'WATERY_LIGHT', // WÄSSRIG_HELL
    WATERY_YELLOWISH = 'WATERY_YELLOWISH', // WÄSSRIG_GELBLICH
    PURULENT = 'PURULENT', // EITRIG
    OPAQUE = 'OPAQUE', // UNDURCHSICHTIG
    FOUL_BAD_SMELL = 'FOUL_BAD_SMELL', // FAULIGER_SCHLECHTER_GERUCH
}

export enum BodyPart {
    FRONT_RIGHT_HEAD = 'FRONT_RIGHT_HEAD',
    FRONT_RIGHT_UPPER_ARM = 'FRONT_RIGHT_UPPER_ARM',
    FRONT_RIGHT_LOWER_ARM = 'FRONT_RIGHT_LOWER_ARM',
    FRONT_RIGHT_CORE = 'FRONT_RIGHT_CORE',
    FRONT_RIGHT_UPPER_LEG = 'FRONT_RIGHT_UPPER_LEG',
    FRONT_RIGHT_LOWER_LEG = 'FRONT_RIGHT_LOWER_LEG',
    FRONT_LEFT_HEAD = 'FRONT_LEFT_HEAD',
    FRONT_LEFT_UPPER_ARM = 'FRONT_LEFT_UPPER_ARM',
    FRONT_LEFT_LOWER_ARM = 'FRONT_LEFT_LOWER_ARM',
    FRONT_LEFT_CORE = 'FRONT_LEFT_CORE',
    FRONT_LEFT_UPPER_LEG = 'FRONT_LEFT_UPPER_LEG',
    FRONT_LEFT_LOWER_LEG = 'FRONT_LEFT_LOWER_LEG',
    BACK_RIGHT_HEAD = 'BACK_RIGHT_HEAD',
    BACK_RIGHT_UPPER_ARM = 'BACK_RIGHT_UPPER_ARM',
    BACK_RIGHT_LOWER_ARM = 'BACK_RIGHT_LOWER_ARM',
    BACK_RIGHT_CORE = 'BACK_RIGHT_CORE',
    BACK_RIGHT_UPPER_LEG = 'BACK_RIGHT_UPPER_LEG',
    BACK_RIGHT_LOWER_LEG = 'BACK_RIGHT_LOWER_LEG',
    BACK_LEFT_HEAD = 'BACK_LEFT_HEAD',
    BACK_LEFT_UPPER_ARM = 'BACK_LEFT_UPPER_ARM',
    BACK_LEFT_LOWER_ARM = 'BACK_LEFT_LOWER_ARM',
    BACK_LEFT_CORE = 'BACK_LEFT_CORE',
    BACK_LEFT_UPPER_LEG = 'BACK_LEFT_UPPER_LEG',
    BACK_LEFT_LOWER_LEG = 'BACK_LEFT_LOWER_LEG',
}

export enum HeartZone {
    HEART_1 = 'HEART_1',
    HEART_2 = 'HEART_2',
    HEART_3 = 'HEART_3',
    HEART_4 = 'HEART_4',
    HEART_5 = 'HEART_5',
}

export enum FrontLungZone {
    FRONT_LUNG_1 = 'FRONT_LUNG_1',
    FRONT_LUNG_2 = 'FRONT_LUNG_2',
    FRONT_LUNG_3 = 'FRONT_LUNG_3',
    FRONT_LUNG_4 = 'FRONT_LUNG_4',
    FRONT_LUNG_5 = 'FRONT_LUNG_5',
    FRONT_LUNG_6 = 'FRONT_LUNG_6',
}

export enum BackLungZone {
    BACK_LUNG_1 = 'BACK_LUNG_1',
    BACK_LUNG_2 = 'BACK_LUNG_2',
    BACK_LUNG_3 = 'BACK_LUNG_3',
    BACK_LUNG_4 = 'BACK_LUNG_4',
    BACK_LUNG_5 = 'BACK_LUNG_5',
    BACK_LUNG_6 = 'BACK_LUNG_6',
    BACK_LUNG_7 = 'BACK_LUNG_7',
    BACK_LUNG_8 = 'BACK_LUNG_8',
}

export enum TracheaZone {
    TRACHEA_1 = 'TRACHEA_1',
    TRACHEA_2 = 'TRACHEA_2',
}

export enum EarPart {
    LEFT_EAR = 'LEFT_EAR',
    RIGHT_EAR = 'RIGHT_EAR',
}

export class DocImage {
    contentUuid?: string;
    // @Length(8, 250)
    uploadFilename: string;
    note?: string;
    properties?: PropertiesPic;
}

export class WoundDocImage extends DocImage {
    location: BodyPart[] = [];
    skinTissue: SkinTissue[] = [];
    granulationTissue: GranulationTissue[] = [];
    exudate: Exudate[] = [];
}

export class OtoscopeImage extends DocImage {
    location: EarPart;
    /**
     * Tells whether this otoscope image is already permanent:
     *  false: json is stored in the local database and image in permanent storage folder on filesystem
     *  true: json is NOT yet stored in local database (only in runtime object) and image is stored in temporary folder on filesystem
     */
    isTemporary: boolean;
}

export class StethoscopeRecording extends DocImage {
    location: HeartZone | FrontLungZone | BackLungZone | TracheaZone;
    // Duration in seconds
    duration: number;
    urlFile: any;
    base64EncodedAudio: string;
}

export class PropertiesPic {
    left: string;
    top: string;
    opacity: string;
}

export const measurementContentClassList = [
    MeasurementContentHeight,
    MeasurementContentBodyWeight,
    MeasurementContentTemperature,
    MeasurementContentOtoscope,
    MeasurementContentStethoscope,
    MeasurementContentBloodPressure,
    MeasurementContentBloodSugarLevel,
    MeasurementContentSPO2,
    MeasurementContentECG,
    MeasurementContentSpirometry,
    MeasurementContentWoundDoc,
    MeasurementContentNote,
    MeasurementContentWalkingTest,
];

export function getMeasurementContentClassByName(name: MeasurementType) {
    return measurementContentClassList.find((x) => {
        const obj = new x();
        return obj.type === name;
    });
}
