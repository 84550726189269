import { FormGroup, Validators } from '@angular/forms';
import { Type } from 'class-transformer';
import { SelectInputItem } from '../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';
import { defaultInputValidator, noWhitespaceValidator } from '../../../common/validators/curafida-validators';
import { User } from './user';
import { Content } from '../../../therapy/entities/content';

export class UserCustomProperty {
    uuid: string;
    username: string;
    user: User;
    schemaId: string;
    schema: UserCustomPropertySchema;
    value?: string;

    /** displayType: FILES */
    contents?: Content[];

    /** Indicates whether this value is validated */
    validated?: boolean;
}

export class UserCustomPropertyDto {
    schemaId: string;
    value?: string;
    uuid?: string;

    /** Indicates whether this value is validated */
    validated?: boolean;

    constructor(schemaId: string, value: string, uuid?: string) {
        this.schemaId = schemaId;
        this.value = value;
        if (uuid) this.uuid = uuid;
    }

    /**
     * Return true if this property was never set or changed.
     */
    isPristine(): boolean {
        return (this.value === '' || !this.value) && !this.uuid;
    }
}

export enum DisplayOptionTypes {
    STRING = 'STRING',
    SELECT = 'SELECT',
    DATE = 'DATE',
    FILES = 'FILES',
}

class DisplayCondition {
    otherKey?: string;
    hasValue?: string;
}

export abstract class DisplayOptions {
    displayType: DisplayOptionTypes;
    defaultValue?: string;
    displayCondition?: DisplayCondition;
}

export class StringDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.STRING;
}

export class SelectDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.SELECT;
    answerOptions: string[];
}

export class DateDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.DATE;
    presentation: 'date' | 'date-time' = 'date';
}

export class FilesDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.FILES;
}

export class DisplayOptionsSchema {
    version = 1;
    @Type(() => DisplayOptions, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'displayType',
            subTypes: [
                { value: StringDisplayOptions, name: StringDisplayOptions.name },
                { value: SelectDisplayOptions, name: SelectDisplayOptions.name },
                { value: DateDisplayOptions, name: DateDisplayOptions.name },
                { value: FilesDisplayOptions, name: FilesDisplayOptions.name },
            ],
        },
    })
    options: DisplayOptions | StringDisplayOptions | SelectDisplayOptions | FilesDisplayOptions;
}

export class UserCustomPropertySchema {
    uuid: string;
    groupUuid: string;
    propertyGroupUuid?: string;

    @Type(() => UserCustomPropertySchema)
    propertyGroup?: UserCustomPropertySchema;

    @Type(() => UserCustomPropertySchema)
    propertySchemas?: UserCustomPropertySchema[];
    name: string;
    displayName?: string;
    sortOrder = 0;
    required = false;
    userRole?: string = 'PATIENT';

    userReadable?: boolean;
    userWritable?: boolean;

    @Type(() => DisplayOptionsSchema)
    displayOptions?: DisplayOptionsSchema;

    /** Specifies that the value is to be validated. */
    validationEnabled?: boolean;

    getFormControlName(): string {
        return this.name + '_' + this.uuid;
    }

    getRepeatValidationCheckBoxFormControlName(): string {
        return this.getFormControlName() + '_repeat-validation-checkbox';
    }

    getRepeatValidationInputFormControlName(): string {
        return this.getFormControlName() + '_repeat-validation-input';
    }

    getAnswerOptionsAsItemList(): SelectInputItem[] {
        if (this.displayOptions?.options?.displayType === DisplayOptionTypes.SELECT) {
            const options = this.displayOptions?.options as SelectDisplayOptions;
            return options?.answerOptions?.map((v) => new SelectInputItem(v, v)) || [];
        }
        return [];
    }

    isVisible(form: FormGroup): boolean {
        const abstractControls = form.controls;
        const condition = this.displayOptions?.options?.displayCondition;
        if (condition) {
            if (abstractControls && abstractControls[condition.otherKey]) {
                const notVisible = !(abstractControls[condition.otherKey].value === condition.hasValue);
                this.isRequired(form, notVisible);
                if (notVisible) {
                    this.clearValue(form);
                }
                return !notVisible;
            } else {
                console.error(`other values key not found: `, condition.otherKey);
            }
        }
        return true;
    }

    isRequired(form: FormGroup, notVisible: boolean) {
        const formControlName = this.getFormControlName();
        if (notVisible) {
            form.controls[formControlName]?.clearValidators();
            form.controls[formControlName]?.updateValueAndValidity();
        } else {
            if (this.required) {
                form.controls[formControlName]?.setValidators([
                    defaultInputValidator,
                    noWhitespaceValidator,
                    Validators.minLength(1),
                    Validators.required,
                ]);
            }
        }
    }

    hasVisiblePropertySchemas(form: FormGroup): boolean {
        return this.propertySchemas.some((propertySchema) => propertySchema.isVisible(form));
    }

    clearValue(form: FormGroup) {
        const formControlName = this.getFormControlName();
        form.controls[formControlName]?.setValue(null);
    }
}
