export interface AppInformation {
    androidPackageName: string;
    name: string;
    urlSchema: string;
}

export const AppQueryList: AppInformation[] = [
    {
        urlSchema: 'alfaview',
        androidPackageName: 'com.alfaview.app',
        name: 'alfaview®',
    },
];
