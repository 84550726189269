import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'curafida-text-input-with-button',
    templateUrl: './curafida-text-input-with-button.component.html',
    styleUrls: ['./curafida-text-input-with-button.component.scss'],
})
export class CurafidaTextInputWithButtonComponent extends CurafidaInputComponent {
    @Input()
    unit: string;
    @Input()
    explanation: string;
    @Input()
    labelButton: string;
    @Input()
    showButton: boolean;
    @Output()
    setActionButton: EventEmitter<boolean> = new EventEmitter<boolean>();
    isButtonDisabled = false;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
    ) {
        super(ngControl, translateService);
    }

    actionButton() {
        this.setActionButton.emit(true);
    }
}
