export class Device {
    id: number;
    name?: string;
    email: string;
    setupCode?: string;
    setupCodeExpiration?: string;
    deviceType?: string;
    manufacturer?: string;
    model?: string;
    product?: string;
    sdkVersion?: string;
    appId?: string;
    appVersion?: string;
    created_at: string;
    updated_at: string;
}

export class StepCount {
    startTime: number;
    endTime: number;
    data: Data[];
}

export class Data {
    deviceId: number;
    stepCount: string;
    day: string;
}

export class StepData {
    Date: string;
    NumberOfSteps: number;
    // internal proMX CRM Id of the user -> not relevant for us, only relevant for proMX
    ContactId: string;
    // internal proMX CRM Id of step entity -> not relevant for us, only relevant for proMX
    Id: string;
}

export class StepsResult {
    startDate: string;
    endDate: string;
    steps: StepsPerDayAndProvider[] = [];
}

export class StepsPerDayAndProvider {
    day: string;
    stepCount: number;
    devices_id: number;
    email: string;
    stepProvider: StepProvider;
}

export enum StepProvider {
    ztm = 'ZTM_DEVICE_API',
    fitbit = 'FITBIT_WEB_API',
    withings = 'WITHINGS_WEB_API',
    garmin = 'GARMIN_WEB_API',
}

export class CalendarWeek {
    Year: number;
    Week: number;
    StepsData: StepData[];
    BarChartStepsData: any;
    BarChartStepsLabels: string[];
}

export class CalendarMonth {
    Year: number;
    Month: number;
    MonthName: string;
    BarChartActivityMinutesData: any;
    BarChartActivityMinutesLabels: string[];
}

export enum DeviceProvider {
    FITBIT = 'FITBIT',
    WITHINGS = 'WITHINGS',
    GARMIN = 'GARMIN',
}
