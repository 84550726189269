import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay, startOfDay } from 'date-fns';
import { TimelineItemResource } from '../../../journal/entities/planer-item-resource';

@Pipe({
    standalone: true,
    name: 'curafidaTimelineDateLabel',
    pure: true,
})
export class CurafidaTimelineDateLabelPipe implements PipeTransform {
    private readonly today = startOfDay(new Date());

    transform(item: TimelineItemResource, timeline: TimelineItemResource[]): string {
        const currentDate = this.toDate(item);
        const currentIndex = timeline.indexOf(item);
        if (currentIndex === 0 || !isSameDay(currentDate, this.toDate(timeline.at(currentIndex - 1)))) {
            return isSameDay(currentDate, this.today) ? 'TODAY' : currentDate.toISOString();
        }
        return '';
    }

    private toDate(item: TimelineItemResource): Date {
        if (item['timelineDate']) {
            return new Date(item['timelineDate']);
        } else {
            return this.today;
        }
    }
}
