import { Pipe, PipeTransform } from '@angular/core';
import { TableItem } from '../entities/table';

@Pipe({
    name: 'filterMobile',
})
export class FilterMobilePipe implements PipeTransform {
    transform(
        itemSettings: TableItem[],
        offset: number = null,
        limit: number = null,
        includeTitle = true,
        onlyTitle = false,
    ): TableItem[] {
        itemSettings = itemSettings.filter((item) => item?.sortOrderMobile || item.sortOrderMobile === 0);

        if (onlyTitle) {
            itemSettings = itemSettings.filter((item) => item.isMobileBold);
        } else if (!includeTitle) itemSettings = itemSettings.filter((item) => !item.isMobileBold);
        if (offset !== null && limit !== null) {
            if (offset === 0) {
                itemSettings = itemSettings.slice(0, limit);
            } else {
                itemSettings = itemSettings.slice(offset, limit + offset);
            }
        }
        return itemSettings.sort((a, b) => {
            if (a.sortOrderMobile < b.sortOrderMobile) {
                return -1;
            } else if (a.sortOrderMobile > b.sortOrderMobile) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });
    }
}
