import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BrowserNavigationService } from '../common/services/browser-navigation/browser-navigation.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard {
    constructor(private browser: BrowserNavigationService) {}
    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.browser.openTargetBlank(route.data['externalUrl']);
        return true;
    }
}
