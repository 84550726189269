export enum TrainingPageState {
    TrainingList = 0,
    TrainingDetail = 1,
    TrainingQuestionnaireStart = 2,
    TrainingFreeModus = 3,
    TrainingAutoModus = 4,
    TrainingQuestionnaireEnd = 5,
    TrainingFinished = 6,
    TrainingClosed = 7,
    TrainingHistory = 8,
}
