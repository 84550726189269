import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { StyleService } from '../../services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurafidaSegmentItem } from '../../entities/curafida-segment.item';
import { ButtonDefinition } from '../../entities/buttons-list-toolbar';
import { Debounce } from '../../debounce';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-search-and-button',
    templateUrl: './curafida-search-and-button.component.html',
    styleUrls: ['./curafida-search-and-button.component.scss'],
})
export class CurafidaSearchAndButtonComponent<T> implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() extendedSearchBar: boolean;
    @Input() hideButton: boolean;
    @Input() hideSearch: boolean;
    @Input() hideFilter: boolean = true;
    @Input() hideSegment: boolean = true;
    @Input() buttonLabel: string;
    @Input() buttonIcon: string;
    @Input() buttonList: ButtonDefinition[];
    @Input() filterLength: number = 0;
    @Input() segmentValue: T;
    @Input() segmentList: CurafidaSegmentItem<T>[] = [];

    @Output() getItemList: EventEmitter<number> = new EventEmitter<number>();
    @Output() setButtonAction: EventEmitter<string> = new EventEmitter<string>();
    @Output() openFilterModal: EventEmitter<void> = new EventEmitter<void>();
    @Output() changeSegmentEmit: EventEmitter<T> = new EventEmitter();

    protected isMobile: boolean;
    protected filterLabel = 'Filtern';
    protected searchBarLabel = 'Suchen';
    private readonly subscription: Subscription[] = [];
    private readonly debouncedSearch = new Debounce<number>();
    private innerValue: T;
    private changed = new Array<(value: T) => void>();
    private touched = new Array<() => void>();

    constructor(
        private styleService: StyleService,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    get value(): T {
        return this.innerValue;
    }

    set value(value: T) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.changed.forEach((f) => f(value));
        }
    }

    ngOnInit(): void {
        this.isMobile = this.styleService.isMobile$;
        if (!this.segmentValue) {
            this.segmentValue = this.segmentList[0]?.value;
        }
        this.subscription.push(
            this.debouncedSearch.value$.subscribe((offset) => {
                if (offset) {
                    this.getItemList.emit(offset);
                } else {
                    this.getItemList.emit(0);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.forEach((it) => it.unsubscribe());
    }

    touch() {
        this.touched.forEach((f) => f());
    }

    writeValue(value: T) {
        this.innerValue = value;
    }

    @HostListener('onChange', ['$event.detail'])
    registerOnChange(fn: (value: T) => void) {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }

    openActionButton(type?: string) {
        this.setButtonAction.emit(type);
    }

    search(offset?: number): void {
        this.debouncedSearch.nextValue(offset);
    }

    setFilter() {
        this.openFilterModal.emit();
    }

    emitSegmentChange(event) {
        this.changeSegmentEmit.emit(event);
    }
}
