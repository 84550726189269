import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'lib-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
    @Input()
    titleSwitch: string;

    @Input()
    arrayTitle: string[] = [];

    @Input()
    textSize: 'small' | 'medium' | 'large' = 'medium';

    @Input()
    isLeftArrowDisabled = false;

    @Input()
    isRightArrowDisabled = false;

    @Output()
    nextAction = new EventEmitter();

    @Output()
    previousAction = new EventEmitter();

    next() {
        this.nextAction.emit();
    }

    previous() {
        this.previousAction.emit();
    }
}
