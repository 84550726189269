import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import {
    AnnouncementAssignableUserRoles,
    assignableCompositeRoles,
    AssignableRoles,
    BasicAssignableUserRole,
    ChatAssignableUserRoles,
    getAssignableRoles,
    isRoleAssignmentValid,
    KnowledgeAssignableUserRoles,
    MeetingAssignableUserRoles,
    NotesAssignableUserRoles,
} from '../../../auth/entities/user';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../common/services/modal';
import { ConfigService } from '../../../config/services';
import { NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CurafidaCommonModule } from '../../../common/curafida-common.module';
import { LoadingComponent } from '../../../common/components/loading/loading.component';

class SelectableRoleListItem {
    name: AssignableRoles;
    isChecked: boolean;
    isVisible: boolean;
    isDisabled: boolean;

    constructor(name: AssignableRoles, isChecked: boolean, isVisible: boolean, isDisabled: boolean) {
        this.name = name;
        this.isChecked = isChecked;
        this.isVisible = isVisible;
        this.isDisabled = isDisabled;
    }
}

@Component({
    selector: 'lib-role-list-modal',
    templateUrl: './role-list-modal.component.html',
    styleUrls: ['./role-list-modal.component.scss'],
    standalone: true,
    imports: [IonicModule, NgForOf, FormsModule, CurafidaCommonModule, LoadingComponent],
})
export class RoleListModalComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;
    title = 'Rollen wählen';
    saveButtonText = 'Auswahl bestätigen';
    anyItem = 'ANY_ROLE';

    alreadyAssignedUserRoles: SelectableRoleListItem[] = [];
    roleSelection: SelectableRoleListItem[];

    constructor(
        protected readonly modalAlertService: ModalAlertService,
        protected readonly modalCtrl: ModalController,
        private readonly toastService: ToastService,
        private configService: ConfigService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.initRoleList();
    }

    async initRoleList(): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        if (!this.roleSelection) {
            const assignableRoles: AssignableRoles[] = Object.values(BasicAssignableUserRole);
            if (this.configService.config.features.chat?.enabled) {
                assignableRoles.push(...Object.values(ChatAssignableUserRoles));
            }
            if (this.configService.config.features.note?.enabled) {
                assignableRoles.push(...Object.values(NotesAssignableUserRoles));
            }
            if (this.configService.config.features.announcement?.enabled) {
                assignableRoles.push(...Object.values(AnnouncementAssignableUserRoles));
            }
            if (this.configService.config.features.knowledge?.enabled) {
                assignableRoles.push(...Object.values(KnowledgeAssignableUserRoles));
            }
            if (this.configService.config.features.course?.enabled) {
                assignableRoles.push(...Object.values(MeetingAssignableUserRoles));
            }
            this.roleSelection = assignableRoles.map((x) => new SelectableRoleListItem(x, false, true, true));
        }
        try {
            await this.updateListTable(this.alreadyAssignedUserRoles.map((x) => x.name));
            this.isLoadingSuccess = true;
        } catch (err) {
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.isLoadingSuccess = false;
        } finally {
            this.isLoading = false;
        }
    }

    async updateListTable(selected: AssignableRoles[]): Promise<void> {
        const assignableUserRoles = getAssignableRoles(selected, this.configService.config.features);
        const selectedCompositeRoles = assignableCompositeRoles.filter((x) => selected.includes(x.roleName));
        for (const roleListItem of this.roleSelection) {
            roleListItem.isChecked =
                (!!selected.find((x) => x === roleListItem.name) &&
                    !!assignableUserRoles.find((x) => x === roleListItem.name)) ||
                selectedCompositeRoles
                    .map((x) => x.compositeRoleNames)
                    .flat()
                    .includes(roleListItem.name);
            roleListItem.isDisabled = !assignableUserRoles.find((x) => x === roleListItem.name);
        }
    }

    async onItemSelection(): Promise<void> {
        await this.updateListTable(this.roleSelection.filter((x) => x.isChecked === true).map((x) => x.name));
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    async returnSelectedGroups(): Promise<void> {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = 'Rolle ändern';
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Möchten Sie die Rollenzuordnung tatsächlich ändern? Hierdurch können für diesen Benutzer Einschränkungen bei der Bedienung auftreten.`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Änderung \n bestätigen';
        modalConfig.buttonRight.buttonColor = 'primary';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            const result: { role: string }[] = [];
            for (const selectedRole of this.roleSelection.filter((i) => i.isChecked)) {
                result.push({ role: selectedRole.name });
            }
            await this.modalCtrl.dismiss(result);
        }
    }

    isButtonDisabled(): boolean {
        // At least one role must be selected for the button to be clickable
        return !isRoleAssignmentValid(
            this.roleSelection.filter((x) => x.isChecked).map((x) => x.name),
            this.configService.config.features,
        );
    }
}
