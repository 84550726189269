import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

interface IPluralizationKey {
    SINGULAR: string;
    PLURAL: string;
}

type allowedKeyTypes = string | number | boolean;
@Pipe({
    name: 'curafidaTranslate',
    standalone: true,
})
/**
 * allowedKeyTypes = string | number | boolean
 */
export class CurafidaTranslatePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(key: allowedKeyTypes, option?: { args?: any; count?: number }): string {
        const sanitizedKey = key?.toString();
        if (sanitizedKey) {
            const translationKey = this.translate.get(sanitizedKey);
            let translatedValue: string;

            translationKey.subscribe((res: IPluralizationKey): void => {
                if (option) {
                    if (option.count >= 0) {
                        if (option.count === 1) {
                            translatedValue = res.SINGULAR;
                        } else {
                            translatedValue = res.PLURAL;
                        }
                    } else {
                        translatedValue = this.translate.instant(sanitizedKey, option.args);
                    }
                } else if (res.SINGULAR) {
                    translatedValue = res.SINGULAR;
                } else if (res.PLURAL) {
                    translatedValue = res.PLURAL;
                } else {
                    translatedValue = this.translate.instant(sanitizedKey);
                }
            });
            return translatedValue;
        }
    }
}
