import { Component, Input } from '@angular/core';
import { PanelStyle } from '../../entities/panel.style';

@Component({
    selector: 'curafida-toggle-panel',
    templateUrl: './curafida-toggle-panel.component.html',
    styleUrls: ['./curafida-toggle-panel.component.scss'],
})
export class CurafidaTogglePanelComponent {
    @Input()
    buttonText: string;

    @Input()
    panelStyle: PanelStyle;

    @Input()
    isMobile: boolean;

    PanelStyle = PanelStyle;

    isPanelOpen = false;

    togglePanelOpen(): void {
        this.isPanelOpen = !this.isPanelOpen;
    }
}
