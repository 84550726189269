import { FeatureConfigs } from '../../../common/entities/curafida-frontend-configuration';

export enum UserRoles {
    PATIENT = 'PATIENT',
    CAREGIVER = 'CAREGIVER',
    PHYSICIAN = 'PHYSICIAN',
    ADMIN = 'ADMIN',
    SUPERVISOR = 'SUPERVISOR',
    USER_MANAGER = 'USER_MANAGER',
    CATALOG_MANAGER = 'CATALOG_MANAGER',
    ANALYST = 'ANALYST',
    MFA = 'MFA',

    manage_therapy_template = `manage_therapytemplate`,
    manage_therapy = 'manage_therapy',
    manage_exercise = 'manage_exercise',
    manage_device = 'manage_device',

    manage_medication = 'manage_medication',
    read_medication = 'read_medication',

    manage_note = 'manage_note',
    write_note = 'write_note',
    read_note = 'read_note',
    manage_note_characteristics = 'manage_note_characteristics',

    manage_supervisor = 'manage_supervisor',
    manage_caregiver = 'manage_caregiver',
    read_therapy = 'read_therapy',

    manage_medical_history = 'manage_medical_history',
    read_medical_history = 'read_medical_history',

    manage_measurement = 'manage_measurement',
    create_measurement = 'create_measurement',
    read_measurement = 'read_measurement',
    update_measurement = 'update_measurement',
    delete_measurement = 'delete_measurement',

    manage_user = 'manage_user',
    create_user = 'create_user',
    read_user = 'read_user',
    update_user = 'update_user',
    delete_user = 'delete_user',

    create_examination = 'create_examination',

    manage_patient = 'manage_patient',
    read_patient = 'read_patient',
    delete_patient = 'delete_patient',
    create_patient = 'create_patient',
    create_supervised_patient = 'create_supervised_patient',

    manage_group = 'manage_group',
    read_group = 'read_group',

    manage_consultation = 'manage_consultation',
    manage_consultation_services = 'manage_consultation_services',
    manage_consultation_settings = 'manage_consultation_settings',
    read_consultation = 'read_consultation',

    manage_order = 'manage_order',
    manage_order_services = 'manage_order_services',
    manage_order_settings = 'manage_order_settings',
    read_order = 'read_order',

    manage_chat = 'manage_chat',
    write_chat = 'write_chat',
    read_chat = 'read_chat',

    write_questionnaire = 'write_questionnaire',
    query_medityme = 'query-medityme',

    read_medical_patientdata = 'read_medical_patientdata',
    write_medical_patientdata = 'write_medical_patientdata',
    patient_coordination = 'patient_coordination',
    patient_recruitment = 'patient_recruitment',
    analyse_task = 'analyse_task',
    write_announcement = 'write_announcement',

    KNOWLEDGE_MANAGER = 'KNOWLEDGE_MANAGER',

    read_task_plan = 'read_task_plan',
    write_task_plan = 'write_task_plan',
    manage_my_courses_and_meetings = 'manage_my_courses_and_meetings',

    manage_patient_dossier = 'manage_patient_dossier',
}

/**
 * A list of roles and rights which are assignable to a user in curafida.
 * This does not include fine granular rights, which are only implicitly
 * assigned through other roles/rights.
 *
 * Use this enum preferably instead of the UserRoles enum.
 *
 * https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/wikis/Rollen-und-Rechte
 */
export enum BasicAssignableUserRole {
    /**
     * Person in Behandlung.
     */
    PATIENT = 'PATIENT',

    /**
     * Verantwortlich für die Behandlung von ihm zugewiesenen Patienten,
     * kann aber auch eine Person außerhalb der Einrichtung sein.
     */
    SUPERVISOR = 'SUPERVISOR',

    /**
     * Verantwortlich für die Behandlung von allen Patienten
     * in der eigenen Gruppe (inklusive Untergruppen).
     */
    CAREGIVER = 'CAREGIVER',

    /**
     * Zuständig für die Benutzer- und Gruppenverwaltung
     */
    USER_MANAGER = 'USER_MANAGER',

    /**
     * Zuständig für die Verwaltung von Katalogen (Vorlagen)
     */
    CATALOG_MANAGER = 'CATALOG_MANAGER',

    /**
     * Verantwortlich für Auswertung der Daten für Studienmanagement
     * und Evaluation für alle Patienten in der eigenen Gruppe.
     */
    ANALYST = 'ANALYST',

    /**
     * Medizinische Daten lesen (Erweiterung)
     */
    read_medical_patientdata = 'read_medical_patientdata',

    /**
     * Medizinische Daten bearbeiten (Erweiterung)
     */
    write_medical_patientdata = 'write_medical_patientdata',

    /**
     * Patienten koordinieren (Erweiterung)
     */
    patient_coordination = 'patient_coordination',

    /**
     * Patienten rekrutieren (Erweiterung)
     */
    patient_recruitment = 'patient_recruitment',

    read_task_plan = 'read_task_plan',
    write_task_plan = 'write_task_plan',

    /**
     * Zuordnungen zwischen Patienten und Randomisierungsgruppen lesen (Erweiterung)
     */
    read_randomization_assignment = 'read_randomization_assignment',

    manage_patient_dossier = 'manage_patient_dossier',

    /**
     * Medikation bearbeiten (Erweiterung)
     */
    manage_medication = 'manage_medication',

    /**
     * Medikation lesen (Erweiterung)
     */
    read_medication = 'read_medication',
}

export enum KnowledgeAssignableUserRoles {
    /**
     * Berechtigt den Bereich "Wissen" zu sehen
     */
    KNOWLEDGE_MANAGER = 'KNOWLEDGE_MANAGER',
}

export enum NotesAssignableUserRoles {
    /**
     * Notizen lesen (Erweiterung)
     */
    read_note = 'read_note',

    /**
     * Notizen bearbeiten (Erweiterung)
     */
    write_note = 'write_note',
}

export enum ChatAssignableUserRoles {
    /**
     * Chats lesen (Erweiterung)
     */
    write_chat = 'write_chat',

    /**
     * Chats bearbeiten (Erweiterung)
     */
    read_chat = 'read_chat',
}

export enum AnnouncementAssignableUserRoles {
    /**
     * Neuigkeiten bearbeiten (Erweiterung)
     */
    write_announcement = 'write_announcement',
}

export enum MeetingAssignableUserRoles {
    /**
     * Kurse/Sitzungen bearbeiten (Erweiterung) in denen Benutzer Moderator oder Ersteller ist.
     */
    manage_my_courses_and_meetings = 'manage_my_courses_and_meetings',
}

export type AssignableRoles =
    | BasicAssignableUserRole
    | AnnouncementAssignableUserRoles
    | ChatAssignableUserRoles
    | NotesAssignableUserRoles
    | KnowledgeAssignableUserRoles
    | MeetingAssignableUserRoles;

export class CompositeRole {
    constructor(
        public roleName: AssignableRoles,
        public compositeRoleNames: AssignableRoles[],
    ) {}

    public flatten(): AssignableRoles[] {
        return [this.roleName, ...this.compositeRoleNames];
    }
}

export const assignableCompositeRoles = [
    new CompositeRole(NotesAssignableUserRoles.write_note, [NotesAssignableUserRoles.read_note]),
    new CompositeRole(ChatAssignableUserRoles.write_chat, [ChatAssignableUserRoles.read_chat]),
    new CompositeRole(BasicAssignableUserRole.read_medical_patientdata, [BasicAssignableUserRole.read_medication]),
    new CompositeRole(BasicAssignableUserRole.write_medical_patientdata, [
        BasicAssignableUserRole.read_medical_patientdata,
        BasicAssignableUserRole.write_task_plan,
        BasicAssignableUserRole.manage_medication,
    ]),
    new CompositeRole(BasicAssignableUserRole.SUPERVISOR, [BasicAssignableUserRole.patient_recruitment]),
    new CompositeRole(BasicAssignableUserRole.write_task_plan, [BasicAssignableUserRole.read_task_plan]),
    new CompositeRole(BasicAssignableUserRole.manage_medication, [BasicAssignableUserRole.read_medication]),
];

/**
 * Validates a set of roles:
 *  1. List items must be unique
 *  2. Includes only assignable roles
 *  3. Includes no conflicting roles
 *  4. Includes at least one role
 */
export function isRoleAssignmentValid(currentRoles: AssignableRoles[], featureConfigs: FeatureConfigs): boolean {
    const assignableRoles: AssignableRoles[] = Object.values(BasicAssignableUserRole);
    if (featureConfigs.chat?.enabled) {
        assignableRoles.push(...Object.values(ChatAssignableUserRoles));
    }
    if (featureConfigs.note?.enabled) {
        assignableRoles.push(...Object.values(NotesAssignableUserRoles));
    }
    if (featureConfigs.announcement?.enabled) {
        assignableRoles.push(...Object.values(AnnouncementAssignableUserRoles));
    }
    if (featureConfigs.knowledge?.enabled) {
        assignableRoles.push(...Object.values(KnowledgeAssignableUserRoles));
    }
    if (featureConfigs.course?.enabled) {
        assignableRoles.push(...Object.values(MeetingAssignableUserRoles));
    }

    if (!currentRoles || currentRoles.length < 1) {
        // List must include at least one role
        return false;
    }
    if (
        currentRoles.filter((x) => {
            const roles: AssignableRoles[] = [
                BasicAssignableUserRole.PATIENT,
                BasicAssignableUserRole.CAREGIVER,
                BasicAssignableUserRole.USER_MANAGER,
                BasicAssignableUserRole.CATALOG_MANAGER,
                BasicAssignableUserRole.ANALYST,
                BasicAssignableUserRole.SUPERVISOR,
            ];
            if (featureConfigs?.knowledge?.enabled) {
                roles.push(KnowledgeAssignableUserRoles.KNOWLEDGE_MANAGER);
            }
            return roles.includes(x);
        }).length < 1
    ) {
        // List must include at least one base role
        return false;
    }
    if (currentRoles.length !== [...new Set(currentRoles)].length) {
        // List items are not unique
        return false;
    }
    const nonAssignableRoles = currentRoles.filter((x) => !assignableRoles.includes(x));
    if (nonAssignableRoles.length !== 0) {
        // currentRoles contains non assignable roles
        return false;
    }
    if (currentRoles.includes(BasicAssignableUserRole.PATIENT)) {
        // Users with the role PATIENT are allowed to become the roles PATIENT and KNOWLEDGE_MANAGER
        const allowedPatientRoles: AssignableRoles[] = [BasicAssignableUserRole.PATIENT];
        if (currentRoles.filter((x) => !allowedPatientRoles.includes(x)).length > 0) {
            return false;
        }
    }
    if (currentRoles.includes(BasicAssignableUserRole.ANALYST)) {
        // Users with the role ANALYST are not allowed to have any other role
        return currentRoles.length === 1 && currentRoles.includes(BasicAssignableUserRole.ANALYST);
    }
    if (currentRoles.includes(BasicAssignableUserRole.SUPERVISOR)) {
        // Users with the role SUPERVISOR are only allowed to have the following
        // additional roles
        const allowedSupervisorRoles: AssignableRoles[] = [
            BasicAssignableUserRole.SUPERVISOR,
            AnnouncementAssignableUserRoles.write_announcement,
            BasicAssignableUserRole.patient_recruitment,
            BasicAssignableUserRole.patient_coordination,
            KnowledgeAssignableUserRoles.KNOWLEDGE_MANAGER,
            BasicAssignableUserRole.read_task_plan,
            BasicAssignableUserRole.write_task_plan,
            BasicAssignableUserRole.manage_patient_dossier,
            BasicAssignableUserRole.manage_medication,
            BasicAssignableUserRole.read_medication,
            MeetingAssignableUserRoles.manage_my_courses_and_meetings,
        ];
        if (currentRoles.filter((x) => !allowedSupervisorRoles.includes(x)).length > 0) {
            return false;
        }
    }
    return true;
}

/**
 * Based on a current set of assigned roles other roles can be assigned.
 *
 * E.g. If a user has role PATIENT, then he is not allowed to have other roles.
 *
 */
export function getAssignableRoles(currentRoles: AssignableRoles[], featureConfigs: FeatureConfigs): AssignableRoles[] {
    let baseSelection: AssignableRoles[] = [
        // Base Composite Roles
        BasicAssignableUserRole.ANALYST,
        BasicAssignableUserRole.CAREGIVER,
        BasicAssignableUserRole.SUPERVISOR,
        BasicAssignableUserRole.USER_MANAGER,
        BasicAssignableUserRole.CATALOG_MANAGER,
        BasicAssignableUserRole.PATIENT,
    ];
    if (featureConfigs?.knowledge?.enabled) {
        baseSelection.push(KnowledgeAssignableUserRoles.KNOWLEDGE_MANAGER);
    }
    if (currentRoles.length === 0) {
        return baseSelection;
    }
    if (currentRoles.includes(BasicAssignableUserRole.PATIENT)) {
        return [BasicAssignableUserRole.PATIENT];
    }
    if (currentRoles.includes(BasicAssignableUserRole.ANALYST)) {
        return [BasicAssignableUserRole.ANALYST];
    }
    if (currentRoles.includes(BasicAssignableUserRole.SUPERVISOR)) {
        const assignableUserRoles: AssignableRoles[] = [
            BasicAssignableUserRole.SUPERVISOR,
            BasicAssignableUserRole.patient_coordination,
            BasicAssignableUserRole.read_task_plan,
            BasicAssignableUserRole.write_task_plan,
            BasicAssignableUserRole.manage_patient_dossier,
            BasicAssignableUserRole.manage_medication,
            BasicAssignableUserRole.read_medication,
        ];
        if (featureConfigs.announcement?.enabled) {
            assignableUserRoles.push(AnnouncementAssignableUserRoles.write_announcement);
        }
        if (featureConfigs.knowledge?.enabled) {
            assignableUserRoles.push(KnowledgeAssignableUserRoles.KNOWLEDGE_MANAGER);
        }
        if (featureConfigs.course?.enabled) {
            assignableUserRoles.push(MeetingAssignableUserRoles.manage_my_courses_and_meetings);
        }
        return assignableUserRoles;
    }
    baseSelection = baseSelection.filter((x) => {
        const roles: AssignableRoles[] = [
            BasicAssignableUserRole.PATIENT,
            BasicAssignableUserRole.SUPERVISOR,
            BasicAssignableUserRole.ANALYST,
        ];
        return !roles.includes(x);
    });
    baseSelection.push(
        ...[
            // Base Extension Rights
            BasicAssignableUserRole.read_medical_patientdata,
            BasicAssignableUserRole.write_medical_patientdata,
            BasicAssignableUserRole.patient_coordination,
            BasicAssignableUserRole.patient_recruitment,
            BasicAssignableUserRole.read_randomization_assignment,
        ],
    );

    if (featureConfigs?.chat?.enabled) {
        baseSelection.push(...Object.values(ChatAssignableUserRoles));
    }
    if (featureConfigs?.note?.enabled) {
        baseSelection.push(...Object.values(NotesAssignableUserRoles));
    }
    if (featureConfigs?.announcement?.enabled) {
        baseSelection.push(...Object.values(AnnouncementAssignableUserRoles));
    }

    if (currentRoles.includes(BasicAssignableUserRole.write_medical_patientdata)) {
        baseSelection = baseSelection.filter((x) => x !== BasicAssignableUserRole.read_medical_patientdata);
    }
    if (currentRoles.includes(NotesAssignableUserRoles.write_note)) {
        baseSelection = baseSelection.filter((x) => x !== NotesAssignableUserRoles.read_note);
    }
    if (currentRoles.includes(ChatAssignableUserRoles.write_chat)) {
        baseSelection = baseSelection.filter((x) => x !== ChatAssignableUserRoles.read_chat);
    }
    return baseSelection;
}
