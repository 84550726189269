import { IconAdapterElement } from '../../table/components/table-adapter/icon-adapter.component';

export class QuestionAndAnswer {
    question: string;
    answer: string;
    icons: IconAdapterElement[];
}
export class QuestionAndAnswers {
    question: string;
    answers: string[];
    icons: IconAdapterElement[];
}
