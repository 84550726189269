// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-contrast-color {
  fill: var(--ion-color-primary-inverse);
  stroke: var(--ion-color-primary);
}

.primary-color {
  fill: var(--ion-color-primary);
  stroke: var(--ion-color-primary-inverse);
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-app-icon/curafida-app-icon.component.scss"],"names":[],"mappings":"AAMA;EACI,sCAAA;EACA,gCAAA;AALJ;;AAQA;EACI,8BAAA;EACA,wCAAA;AALJ","sourcesContent":["//svg filters for programmatic colour change\n//hex can be converted to filter at this codepen:\n//https://codepen.io/sosuke/pen/Pjoqqp\n//note that the start colour is white so filters\n//should be prepended with brightness(0) saturate(100%)\n\n.primary-contrast-color {\n    fill: var(--ion-color-primary-inverse);\n    stroke: var(--ion-color-primary);\n}\n\n.primary-color {\n    fill: var(--ion-color-primary);\n    stroke: var(--ion-color-primary-inverse);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
