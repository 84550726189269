export type LoadingStates = { success: boolean; processing: boolean };

export class LoadingProcess {
    static initLoadingProcess(): LoadingStates {
        return { success: false, processing: true };
    }
    static finishedSuccessfullyLoadingProcess(): LoadingStates {
        return { success: true, processing: false };
    }
    static finishedWithErrorLoadingProcess(): LoadingStates {
        return { success: false, processing: false };
    }
}
