import { NgModule } from '@angular/core';
import { HandleEmptyValuePipe } from './handle-empty-value.pipe';
import { FilterMobilePipe } from './filter-mobile.pipe';
import { FilterButtonMobilePipe } from './filter-button-mobile.pipe';
import { ContainsPipe, SortPipe } from './array.pipe';

@NgModule({
    declarations: [HandleEmptyValuePipe, FilterMobilePipe, FilterButtonMobilePipe, SortPipe, ContainsPipe],
    imports: [],
    exports: [HandleEmptyValuePipe, FilterMobilePipe, FilterButtonMobilePipe, SortPipe, ContainsPipe],
})
export class PipeTableLib {}
