import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingController, LoadingOptions } from '@ionic/angular';
import Timeout = NodeJS.Timeout;
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    isLoadingEnable = new BehaviorSubject<{ state: boolean; message: string }>({ state: false, message: '' });
    counter = 0;
    sendLoadingMessage = 'Daten werden versendet.';
    saveLoadingMessage = 'Daten werden speichern.';
    private loading: HTMLIonLoadingElement;
    private timeout: Timeout;
    private _isLoadingConnected: boolean;

    get isLoadingConnected() {
        return this._isLoadingConnected;
    }

    private set isLoadingConnected(value: boolean) {
        this._isLoadingConnected = value;
    }

    constructor(
        private loadingController: LoadingController,
        private readonly translation: TranslateService,
    ) {}

    subscribeLoadingModal(onNext) {
        return this.isLoadingEnable.subscribe(onNext);
    }

    startLoadingModal(messageOrKey?: string, timeoutInMs = 120000) {
        const message = messageOrKey ? (this.translation.instant(messageOrKey) as string) : messageOrKey;
        this.counter++;
        this.isLoadingEnable.next({ state: true, message });
        if (this.timeout) clearTimeout(this.timeout);
        if (timeoutInMs) {
            this.timeout = setTimeout(() => {
                this.isLoadingEnable.next({ state: false, message });
            }, timeoutInMs);
        }
    }

    stopLoadingModal() {
        if (this.counter > 0) this.counter--;

        if (this.counter === 0) {
            this.isLoadingEnable.next({ state: false, message: '' });
            clearTimeout(this.timeout);
        }
    }

    resetCountLoadingModal() {
        this.counter = 0;
        this.stopLoadingModal();
    }

    getLoadingModalState() {
        return this.isLoadingEnable.getValue();
    }

    /**
     * This starts a single LoadingController (Loading in an overlay). In case it was already started, it will update message and duration
     */
    async startLoadingController(loadingOptions?: LoadingOptions): Promise<any> {
        loadingOptions = loadingOptions ?? {};
        loadingOptions.message = loadingOptions.message ?? loadingOptions.message ?? this.sendLoadingMessage;
        loadingOptions.duration = loadingOptions.duration ?? 50000;
        loadingOptions.backdropDismiss = loadingOptions.backdropDismiss ?? true;

        if (this.loading?.isConnected) {
            this.loading.message = loadingOptions.message;
            this.loading.duration = loadingOptions.duration;
            return this.loading;
        }
        this.loading = await this.loadingController.create(loadingOptions);
        this.isLoadingConnected = true;
        await this.loading.present();
        this.loading.onDidDismiss().then(() => {
            this.isLoadingConnected = false;
        });
        return this.loading;
    }

    async stopLoadingController() {
        this.isLoadingConnected = false;
        await this.loading?.dismiss();
    }

    changeLoadingMessage(newMessage: string) {
        if (this.loading) this.loading.message = newMessage;
    }
}
