import { HypermediaLinks, HypermediaResource } from '../../../hateoas/hateoas.model';
import { UserCustomPropertySchema } from '../../../auth/entities/user/user-custom-property';

export class Group implements HypermediaResource {
    path: string;
    _links: HypermediaLinks;
}

export class SelfRegistrationConfig implements HypermediaResource {
    groups: Group[] = [];
    _links: HypermediaLinks;
}

export class SelfRegistrationModel {
    config: SelfRegistrationConfig;
    selected: Group;
    customFormProps: UserCustomPropertySchema[];

    static initialState(): SelfRegistrationModel {
        return {
            config: new SelfRegistrationConfig(),
            selected: undefined,
            customFormProps: [],
        };
    }
}
