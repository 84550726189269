// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-tutorial {
  border: 2px solid var(--ion-color-primary);
  border-radius: 2%;
  margin: var(--ion-padding, 16px);
  height: 95%;
}`, "",{"version":3,"sources":["webpack://./../../libs/measurement/components/tutorial-slides-modal/tutorial-slides-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,iBAAA;EACA,gCAAA;EACA,WAAA;AACJ","sourcesContent":[".border-tutorial {\n    border: 2px solid var(--ion-color-primary);\n    border-radius: 2%;\n    margin: var(--ion-padding, 16px);\n    height: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
