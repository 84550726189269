import { ActionType } from './table';
import { IonicColor } from '../../common/entities/toast/ionic-color';

export enum ActionItemType {
    BUTTON = 'BUTTON',
    DIVIDER = 'DIVIDER',
    INFO_TEXT = 'INFO_TEXT',
}

export interface ActionMenuItem {
    label?: string;
    type: ActionItemType;
}

export class ActionButton implements ActionMenuItem {
    type: ActionItemType = ActionItemType.BUTTON;
    label: string;
    action: ActionType | string;
    disabled = false;
    icon: string;
    color: IonicColor;

    constructor(
        type: ActionItemType,
        label: string,
        action: ActionType | string,
        disabled?: boolean,
        icon?: string,
        color?: IonicColor,
    ) {
        this.type = type;
        this.label = label;
        this.action = action;
        this.disabled = disabled;
        this.icon = icon;
        this.color = color;
    }
}

export class ActionDivider implements ActionMenuItem {
    type: ActionItemType = ActionItemType.DIVIDER;
}

export class ActionInfoText implements ActionMenuItem {
    type: ActionItemType = ActionItemType.INFO_TEXT;
    label: string;

    constructor(label: string) {
        this.label = label;
    }
}
