import { NgModule } from '@angular/core';
import { ValueParameterPipe } from './value-parameter.pipe';
import { MeasurementNumberPipe } from './display-number/measurement-number.pipe';
import { Convert2StringMetricSystemPipePipe } from './display-number/string-decimal-colon.pipe';
import { ConvertNumber2ECGNomenclaturePipePipe } from './convert-number-2-roman-numeral.pipe';
import { StringForSecondsPipe } from './stringForSeconds.pipe';
import { FilterPatientsPipe } from './filter-patients.pipe';

@NgModule({
    declarations: [
        ValueParameterPipe,
        MeasurementNumberPipe,
        Convert2StringMetricSystemPipePipe,
        ConvertNumber2ECGNomenclaturePipePipe,
        StringForSecondsPipe,
        FilterPatientsPipe,
    ],
    imports: [],
    exports: [
        ValueParameterPipe,
        MeasurementNumberPipe,
        Convert2StringMetricSystemPipePipe,
        ConvertNumber2ECGNomenclaturePipePipe,
        StringForSecondsPipe,
        FilterPatientsPipe,
    ],
})
export class PipesMeasurementModule {}
