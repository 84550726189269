import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { CurafidaAuthService } from '../../../auth/services';
import { UpdateUserTagDto, UserTag, UserTagAssignmentDto, UserTagDto } from '../../entities/user-tag/user-tag.entity';
import { UserRoles } from '../../../auth/entities/user';
import { UsersService } from '../user';
import {
    UpdateUserTagCategoryDto,
    UserTagCategory,
    UserTagCategoryDto,
} from '../../entities/user-tag/user-tag-category.entity';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { CurafidaFrontendConfiguration } from '../../../common/entities/curafida-frontend-configuration';

@Injectable({
    providedIn: 'root',
})
export class UserTagsService {
    constructor(
        @Optional() @Inject('environmentConfig') private environmentConfig: CurafidaFrontendConfiguration,
        protected http: HttpClient,
        private authService: CurafidaAuthService,
        private usersService: UsersService,
    ) {}

    async getUserTagCategories(args: {
        tenantOfUser?: string;
        offset?: number;
        limit?: number;
        userRole?: UserRoles;
    }): Promise<PaginatedResponse<UserTagCategory[]>> {
        if (!args.tenantOfUser) {
            const groupsWithHighestLevel = await this.getUserGroup();
            args.tenantOfUser = encodeURIComponent(groupsWithHighestLevel[0]);
        }
        const url = new URL(`${ApiService.url}groups/${args.tenantOfUser}/-/userTagCategories`);
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.userRole) url.searchParams.set('userRole', args.userRole);
        return this.http.get<PaginatedResponse<UserTagCategory[]>>(url.toString()).toPromise();
    }

    async createUserTagCategories(userTagCategoryDto?: UserTagCategoryDto): Promise<UserTagCategory[]> {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .post<
                UserTagCategory[]
            >(`groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/userTagCategories`, userTagCategoryDto)
            .toPromise();
    }

    async updateUserTagCategories(updateUserTagCategoryDto: UpdateUserTagCategoryDto): Promise<UserTagCategory[]> {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .put<
                UserTagCategory[]
            >(`groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/userTagCategories`, updateUserTagCategoryDto)
            .toPromise();
    }

    async deleteTagCategories(userTagCategoryUuid?: string) {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .delete(
                `groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/userTagCategories/${userTagCategoryUuid}`,
            )
            .toPromise();
    }

    async createUserTag(userTagDto?: UserTagDto): Promise<UserTag> {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .post<UserTag>(`groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/userTags`, userTagDto)
            .toPromise();
    }

    async updateUserTag(updateUserTagDto?: UpdateUserTagDto): Promise<UserTag> {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .put<UserTag>(`groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/userTags`, updateUserTagDto)
            .toPromise();
    }

    async deleteUserTag(tagUuid?: string) {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .delete(`groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/userTags/${tagUuid}`)
            .toPromise();
    }

    async updateUserTagFromPatient(username: string, userTagAssignmentDto: UserTagAssignmentDto) {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .put<UserTag>(
                `groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/patients/${username}/userTags`,
                userTagAssignmentDto,
            )
            .toPromise();
    }

    async updateUserTagFromUser(username: string, userTagAssignmentDto: UserTagAssignmentDto) {
        const groupsWithHighestLevel = await this.getUserGroup();
        return this.http
            .put<UserTag>(
                `groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/members/${username}/userTags`,
                userTagAssignmentDto,
            )
            .toPromise();
    }

    async getUserGroup() {
        const groupsOfUser = this.authService.getSession().user.groups;
        const errorMessage =
            'Es ist ein Fehler im Zusammenhang mit Ihrem Benutzerkonto aufgetreten. Daher werden Sie in 10 Sekunden automatisch ausgeloggt.\nBitte wenden Sie sich an service@ztm.de um dieses Problem zu beheben.';
        if (!groupsOfUser || groupsOfUser.length === 0) {
            await this.authService.logoutUserWithError(errorMessage);
            throw Error(errorMessage);
        }
        const groupsWithHighestLevel = this.usersService.getHighestHierarchyGroupPaths(groupsOfUser);
        if (groupsWithHighestLevel.length !== 1) {
            await this.authService.logoutUserWithError(errorMessage);
            throw Error(errorMessage);
        }

        return groupsWithHighestLevel;
    }
}
