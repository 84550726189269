import { ItemType } from './item-type';
import { ActionType } from './action-type';
import { SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { EventEmitter, Type } from '@angular/core';
import { DurationUnit } from '../../../therapy/entities/duration';
import { IntensityUnit } from '../../../therapy/entities/exerciseGoal/exerciseGoalDto';

export interface ItemAdapterComponent {
    data: any;
    itemSetting: TableItem;
    updateList?: EventEmitter<boolean>;
    actionSelected?: EventEmitter<{ action: ActionType; mouseEvent: MouseEvent }>;
    sendUpdateOfExerciseGoal?: EventEmitter<{ action: ActionType; exerciseGoal: IPhysicalExerciseGoal }>;
    name?: string;
    rowIndex?: number;
    isReorderDisable?: boolean;
}

export interface MultiItemAdapterItem {
    itemSetting: TableItem;
    condition: (data) => boolean;
}

export class ActionSelection {
    value: ActionType;
    label: string;
    disabled?: boolean;
}

export enum DateFormat {
    DATE = 'DATE',
    DATE_AND_HOUR = 'DATE_AND_HOUR',
    DATE_AND_HOUR_2_LINES = 'DATE_AND_HOUR_2_LINES',
    HOUR = 'HOUR',
    VIDEO_CONFERENCE_ADMISSION = 'VIDEO_CONFERENCE_ADMISSION',
}

export class TableItem {
    actionType?: ActionType;
    actions?: { value: ActionType; label: string; disabled?: boolean }[];
    adapter?: Type<ItemAdapterComponent>;
    color?: string;
    disabled?: boolean;
    fill? = 'solid';
    format?: DateFormat;
    header?: string;
    headerIcon?: string;
    headerIconColor? = '';
    icon?: string;
    id?: string;
    isLabelDisabled?: boolean;
    /** Deprecated */
    isMobileBold? = false; // only for type appointment, duration, string, date
    isOnlyToShow?: boolean;
    label?: string;
    prop: string;
    sortOrder?: SortOrder;
    /** Deprecated */
    sortOrderMobile?: number;
    /** Deprecated */
    showColNameOnMobile? = false;
    sortBy?: SortBy | string;
    columnPosition?: number;
    starValue?: number;
    tooltip? = '';
    type?: ItemType;
    unit?: string;
    width?: number | string;
    maxTextLines?: number;
    /** Deprecated */
    isMobileButton?: boolean;
    disabledProp?: string;
    isHidden? = false;
    translationPrefix? = '';
    multiItemSettings?: MultiItemAdapterItem[];
}

export interface IPhysicalExerciseGoal {
    uuid: string;
    active: boolean;
    series?: number;
    duration?: number;
    durationUnit?: DurationUnit;
    intensity?: number;
    intensityUnit?: IntensityUnit;
    pulse?: number;
    pulseVariance?: number;
}
