import { NgModule } from '@angular/core';
import { AuthorizationPipe, NoAuthorizationPipe } from './authorization.pipe';

@NgModule({
    declarations: [AuthorizationPipe, NoAuthorizationPipe],
    imports: [],
    exports: [AuthorizationPipe, NoAuthorizationPipe],
    providers: [AuthorizationPipe, NoAuthorizationPipe],
})
export class HateoasModule {}
