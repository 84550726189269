import { CoverageType, User } from './user';
import { ContactCategory } from './contact-category';
import { UserCustomPropertyDto } from './user-custom-property';

export class UpdateUserDto {
    title?: string;
    firstname?: string;
    lastname?: string;
    disabled?: boolean;
    birthdate?: string;
    gender?: string;
    maritalStatus?: string;
    alternativeName?: string;
    pvsId?: string;
    streetAddress?: string;
    postalCode?: string;
    city?: string;
    addressAddition?: string;
    country?: string;
    phone: string;
    mobilePhone: string;
    nationality: string;
    healthInsurer: string;
    healthInsurerType: CoverageType; // siehe https://simplifier.net/basisprofil-de-r4/versicherungsart-de-basis
    healthInsuranceNumber: string;
    generalPractitionerName: string;
    generalPractitionerPhone: string;
    isEmployed: boolean; // type prüfen -> null oder undefined heißt keine Angabe
    job: string;
    company: string;
    contactCategory: ContactCategory;
    pseudonym?: string;
    usePseudonym: boolean;
    email?: string;

    customPropertyDtos?: UserCustomPropertyDto[];
}

export class UpdateUserDtoBuilder {
    private updateUserDto = new UpdateUserDto();

    setUserJob(job: string): UpdateUserDtoBuilder {
        this.updateUserDto.job = job;
        return this;
    }

    copyFromUser(user: User): UpdateUserDtoBuilder {
        this.updateUserDto.title = user.title;
        this.updateUserDto.firstname = user.firstname;
        this.updateUserDto.lastname = user.lastname;
        this.updateUserDto.disabled = user.disabled;
        this.updateUserDto.birthdate = user.birthdate;
        this.updateUserDto.gender = user.gender;
        this.updateUserDto.maritalStatus = user.maritalStatus;
        this.updateUserDto.alternativeName = user.alternativeName;
        this.updateUserDto.pvsId = user.pvsId;
        this.updateUserDto.streetAddress = user.streetAddress;
        this.updateUserDto.postalCode = user.postalCode;
        this.updateUserDto.city = user.city;
        this.updateUserDto.addressAddition = user.addressAddition;
        this.updateUserDto.country = user.country;
        this.updateUserDto.phone = user.phone;
        this.updateUserDto.mobilePhone = user.mobilePhone;
        this.updateUserDto.nationality = user.nationality;
        this.updateUserDto.healthInsurer = user.healthInsurer;
        this.updateUserDto.healthInsurerType = user.healthInsurerType;
        // siehe https://simplifier.net/basisprofil-de-r4/versicherungsart-de-basis
        this.updateUserDto.healthInsuranceNumber = user.healthInsuranceNumber;
        this.updateUserDto.generalPractitionerName = user.generalPractitionerName;
        this.updateUserDto.generalPractitionerPhone = user.generalPractitionerPhone;
        this.updateUserDto.isEmployed = user.isEmployed; // type prüfen -> null oder undefined heißt keine Angabe
        this.updateUserDto.job = user.job;
        this.updateUserDto.company = user.company;
        this.updateUserDto.contactCategory = user.contactCategory;
        this.updateUserDto.pseudonym = user.pseudonym;
        this.updateUserDto.usePseudonym = user.usePseudonym;
        // this.updateUserDto.email = user.email;
        return this;
    }

    build(): UpdateUserDto {
        return this.updateUserDto;
    }
}
