// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
  width: 244px;
  height: 45px;
  margin-bottom: 0;
}
.toggle-button span {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.button-text {
  font-size: 16px !important;
  margin-left: 5px;
}

.narrow-no-border {
  width: 244px;
}

.wide-dash-border {
  position: relative;
  top: -13px;
  z-index: -1;
  padding: 22px 13px 20px 13px;
  width: 100% !important;
  border: 1px dashed var(--ion-color-primary);
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-toggle-panel/curafida-toggle-panel.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AAER;;AAEA;EACI,0BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EACA,WAAA;EACA,4BAAA;EACA,sBAAA;EACA,2CAAA;EACA,kBAAA;AACJ","sourcesContent":[".toggle-button {\n    width: 244px;\n    height: 45px;\n    margin-bottom: 0;\n    span {\n        width: 100%;\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: flex-start;\n    }\n}\n\n.button-text {\n    font-size: 16px !important;\n    margin-left: 5px;\n}\n\n.narrow-no-border {\n    width: 244px;\n}\n\n.wide-dash-border {\n    position: relative;\n    top: -13px;\n    z-index: -1;\n    padding: 22px 13px 20px 13px;\n    width: 100% !important;\n    border: 1px dashed var(--ion-color-primary);\n    border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
