import { UserRoles } from '../../auth/entities/user';
import { PageName } from './page.name';
import { RoutingSegment } from '../../common/entities/routing-segment';
import { ToolbarType } from '../../common/entities/view/toolbar-type';

export interface BasePageConfig {
    title: string;
    pageId?: string | PageName;
    showTitle?: boolean;
    url?: string;
    role: UserRoles[];
    categories: RoutingSegment[];
    logo?: string;
    showBurgerMenu?: boolean;
    module?: CurafidaFeatureEnum;
    configuration?: PageParameters;
    text?: string;
    badge?: number;
}

export abstract class PageParameters {
    // main configuration
    toolbarVisibility? = true;
    menuVisibility? = false;
    headerVisibility? = false;
    showPageTitle? = false;
    showSubPagesDetailList? = false;
    showCurrentUser? = false;
    hasHelpIcon? = false;
    helpUrl?: string;
    toolbarType?: ToolbarType;
    onlyIcon?: boolean; // The title-text is not always desired on the toolbar.
    // The Icon is used as a reference for the user. It will be displayed on the toolbar
    //focusModeEnabled? = false;
}

export enum CurafidaFeatureEnum {
    anamnese = 'anamnese',
    announcement = 'announcement',
    appointment = 'appointment',
    chat = 'chat',
    common = 'common',
    consultation = 'consultation',
    course = 'course',
    knowledge = 'knowledge',
    kiosk = 'kiosk',
    learning = 'learning',
    log = 'log',
    manual = 'manual',
    measurement = 'measurement',
    medication = 'medication',
    mobility = 'mobility',
    note = 'note',
    order = 'order',
    organisation = 'organisation',
    program = 'program',
    screening = 'screening',
    shop = 'shop',
    task = 'task',
    training = 'training',
    user = 'user',
    video = 'video',
    //focus = 'focus',
}
