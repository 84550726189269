// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select::part(icon) {
  color: #fff !important;
}

.select-content {
  max-width: 100% !important;
  padding-left: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  white-space: pre;
  text-wrap: balance;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-select-input/curafida-select-input.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,0BAAA;EACA,eAAA;EACA,wBAAA;UAAA,uBAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":["ion-select::part(icon) {\n  color: #fff !important;\n}\n\n.select-content {\n  max-width: 100% !important;\n  padding-left: 0;\n  padding-inline-start: 0;\n  white-space: pre;\n  text-wrap: balance;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
