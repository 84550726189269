import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, skip, take } from 'rxjs/operators';
import { AuthorizationPipe } from '../../hateoas/authorization.pipe';
import { NoteTagsService } from '../services/note-tags.service';

@Injectable({
    providedIn: 'root',
})
export class NoteCharacteristicAuthGuard {
    constructor(
        readonly http: HttpClient,
        private readonly authorizationPipe: AuthorizationPipe,
        private readonly noteTagsService: NoteTagsService,
    ) {}

    canActivate(): Observable<boolean> {
        this.noteTagsService.init();
        return this.noteTagsService.characteristic$.pipe(
            skip(1),
            take(1),
            map((it) => this.authorizationPipe.transform(it, 'self', 'read')),
        );
    }
}
