// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  white-space: pre-wrap;
}

ion-card > ion-card-header > .title {
  font-size: 14px !important;
  font-weight: bold !important;
}

curafida-card {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-card/curafida-card.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,4BAAA;AACJ;;AACA;EACI,sBAAA;AAEJ","sourcesContent":["ion-button {\n    white-space: pre-wrap;\n}\n\nion-card > ion-card-header > .title {\n    font-size: 14px !important;\n    font-weight: bold !important;\n}\ncurafida-card {\n    width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
