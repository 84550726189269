import { MigrationInterface, QueryRunner } from 'typeorm';

export class AddTenantId1598278812130 implements MigrationInterface {
    name = 'AddTenantId1598278812130';

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`ALTER TABLE Patient ADD tenantId VARCHAR;`);
        await queryRunner.query(`ALTER TABLE Examination ADD tenantId VARCHAR;`);
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query('ALTER TABLE Patient DROP COLUMN tenantId;');
        await queryRunner.query('ALTER TABLE Examination DROP COLUMN tenantId;');
    }
}
