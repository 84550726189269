import { TherapyState } from '../entities/therapy';
import { TherapyModuleSortBy } from '../../table/entities/table';
import { SortBy, SortOrder } from '../../common/entities/paginated-response';
import { ExerciseType } from '../entities/exerciseSession';

export class SortTherapyModuleListService {
    static setSortParam(
        url,
        offset: number,
        limit: number,
        filter: string,
        sortOrder: SortOrder,
        therapyState: TherapyState,
        sortBy: SortBy | TherapyModuleSortBy,
        categoryId?: string,
        exerciseType?: ExerciseType,
    ) {
        let isFirstParam = true;
        if (offset >= 0) {
            if (isFirstParam) {
                url = url.concat(`?offset=${offset}`);
                isFirstParam = false;
            } else {
                url = url.concat(`&offset=${offset}`);
            }
        }
        if (limit >= 0) {
            if (isFirstParam) {
                url = url.concat(`?limit=${limit}`);
                isFirstParam = false;
            } else {
                url = url.concat(`&limit=${limit}`);
            }
        }
        if (filter && filter !== '') {
            filter = encodeURIComponent(filter);
            if (isFirstParam) {
                url = url.concat(`?filter=${filter}`);
                isFirstParam = false;
            } else {
                url = url.concat(`&filter=${filter}`);
            }
        }
        if (sortOrder) {
            if (isFirstParam) {
                url = url.concat(`?sortOrder=${sortOrder}`);
                isFirstParam = false;
            } else {
                url = url.concat(`&sortOrder=${sortOrder}`);
            }
        }
        if (therapyState) {
            if (isFirstParam) {
                url = url.concat(`?therapyState=${therapyState}`);
                isFirstParam = false;
            } else {
                url = url.concat(`&therapyState=${therapyState}`);
            }
        }
        if (sortBy) {
            if (isFirstParam) {
                url = url.concat(`?sortBy=${sortBy}`);
                isFirstParam = false;
            } else {
                url = url.concat(`&sortBy=${sortBy}`);
            }
        }
        if (categoryId) {
            if (isFirstParam) {
                url = url.concat(`?categoryId=${categoryId}`);
            } else {
                url = url.concat(`&categoryId=${categoryId}`);
            }
        }
        if (exerciseType) {
            if (isFirstParam) {
                url = url.concat(`?exerciseType=${exerciseType}`);
            } else {
                url = url.concat(`&exerciseType=${exerciseType}`);
            }
        }
        return url;
    }
}
