import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'measurementNumber',
})
export class MeasurementNumberPipe implements PipeTransform {
    transform(value: string, isInteger: boolean): string {
        if (!isInteger) {
            value = value.replace('.', ',');
        }
        return value;
    }
}
