import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convert2StringMetricSystemPipe',
})
export class Convert2StringMetricSystemPipePipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        if (!value) {
            return '';
        }
        return value.replace('.', ',');
    }
}
