import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICardActionButton } from '../../entities/card-action-button.interface';

@Component({
    selector: 'curafida-card',
    templateUrl: './curafida-card.component.html',
    styleUrls: ['./curafida-card.component.scss'],
})
export class CurafidaCardComponent {
    @Input()
    disabled = false;
    @Input()
    title: string;

    @Input()
    isCardCollapsed = false;

    @Input()
    areButtonsVisible = true;

    @Input()
    actionButtons: ICardActionButton[];
    @Output()
    setActionButton: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    isHeaderClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    handleButtonClick($event: MouseEvent, id: string) {
        $event.stopPropagation();
        this.setActionButton.emit(id);
    }

    collapseCard() {
        this.isCardCollapsed = !this.isCardCollapsed;
        this.isHeaderClick.emit(true);
    }
}
