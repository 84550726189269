import { EventEmitter } from '@angular/core';

export class ButtonMeasurementInfo<T = any> {
    buttonTitle: string;
    buttonSubtitle: string;
    svgName: string;
    svgRadio: string;
    uriRedirection: string;
    disabled: boolean;
    clicked = new EventEmitter<void>();
    actions = new EventEmitter<T>();

    constructor(buttonTitle?: string, disabled?: boolean) {
        this.buttonTitle = buttonTitle;
        this.disabled = disabled;
    }
}
