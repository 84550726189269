// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-item-box {
  width: 50%;
}

.sc-ion-input-md-h {
  --padding-start: 0 !important;
}

.separator {
  font-size: 100%;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-double-text-input/curafida-double-text-input.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".ion-item-box {\n    width: 50%;\n}\n\n.sc-ion-input-md-h {\n    --padding-start: 0 !important;\n}\n\n.separator {\n    font-size: 100%;\n    margin-left: 5px;\n    margin-right: 5px;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
