import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaTextInputComponent } from '../curafida-text-input/curafida-text-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'curafida-double-text-input',
    templateUrl: './curafida-double-text-input.component.html',
    styleUrls: ['./curafida-double-text-input.component.scss'],
})
export class CurafidaDoubleTextInputComponent extends CurafidaTextInputComponent {
    @Input() formControlName2;
    @Input() separator = '/';
    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
    ) {
        super(ngControl, translateService);
    }
}
