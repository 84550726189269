import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { defaultInputValidator } from '../../validators/curafida-validators';
import { PatientReportCreateDto, PatientReportTemplate } from './patient-report.model';

export class PatientReportForm {
    readonly group: FormGroup;

    constructor(formBuilder: FormBuilder) {
        this.group = formBuilder.group({
            template: new FormControl({ value: '', disabled: false }),
            reportPseudonymized: new FormControl({ value: false, disabled: true }),
            headerText: new FormControl({ value: '', disabled: false }),
            patientText: new FormControl({ value: '', disabled: true }, defaultInputValidator),
            showCreatorUser: new FormControl({ value: true, disabled: false }),
            therapist: new FormControl({ value: '', disabled: true }, defaultInputValidator),
            letterSender: new FormControl({ value: '', disabled: false }, defaultInputValidator),
            selectLetterRecipient: new FormControl({ value: 'text', disabled: false }),
            letterRecipient: new FormControl({ value: '', disabled: false }, defaultInputValidator),
            letterSubject: new FormControl({ value: '', disabled: false }, defaultInputValidator),
            letterBody: new FormControl({ value: '', disabled: false }, defaultInputValidator),
            letterPostscript: new FormControl({ value: '', disabled: false }, defaultInputValidator),
            headerImages: new FormControl({ value: [], disabled: false }),
            footerImages: new FormControl({ value: [], disabled: false }),
            signatureImages: new FormControl({ value: [], disabled: false }),
            myMedaxQuestionnaireSubmissionUuids: new FormControl({ value: [], disabled: false }),
            dataCategories: new FormControl({ value: [], disabled: false }),
        });
    }

    toDto(): PatientReportCreateDto {
        return {
            letterBody: this.group.controls.letterBody.value,
            letterPostscript: this.group.controls.letterPostscript.value,
            letterRecipient: this.group.controls.letterRecipient.value,
            letterSender: this.group.controls.letterSender.value,
            letterSubject: this.group.controls.letterSubject.value,
            reportPseudonymized: this.group.controls.reportPseudonymized.value,
            myMedaxQuestionnaireSubmissionUuids: this.group.controls.myMedaxQuestionnaireSubmissionUuids.value,
            dataCategories: this.group.controls.dataCategories.value,
        };
    }

    loadTemplate(patientTemplate: PatientReportTemplate): void {
        this.group.controls.template.setValue(patientTemplate.letterSubject);
        this.group.controls.template.disable();
        this.group.controls.letterSubject.setValue(patientTemplate.letterSubject);
        this.group.controls.letterBody.setValue(patientTemplate.letterBody);
        this.group.controls.letterPostscript.setValue(patientTemplate.letterPostscript);
        this.group.controls.letterSender.setValue(patientTemplate.letterSender);
        this.group.controls.letterRecipient.setValue(patientTemplate.letterRecipient);
        this.group.controls.reportPseudonymized.setValue(patientTemplate.reportPseudonymized);
    }
}
