import { Capacitor } from '@capacitor/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { NetworkService } from '../../../measurement/services/network/network.service';
import { ModalConfig } from '../../entities/modal/modal-config';
import { ModalTyp } from '../../entities/modal/modal-typ';
import { IonicColor } from '../../entities/toast/ionic-color';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ModalAlertService } from '../../services/modal';
import { takeUntil, auditTime } from 'rxjs/operators';
import { Content } from '../../../therapy/entities/content';
import { ExerciseContentsService } from '../../../therapy/services/exercise-contents';
import { ButtonConfig } from '../../entities/modal/modal-button';
import { FileContentService, DownloadContentProgress } from '../../services/content/file-content.service';
import { Subject } from 'rxjs';
import { User, UserRoles } from '../../../auth/entities/user';
import { PaginatedResponse } from '../../entities/paginated-response';

@Component({
    selector: 'offline-media-sync',
    templateUrl: './offline-media-sync.component.html',
    styleUrls: ['./offline-media-sync.component.scss'],
})
export class OfflineMediaSyncComponent implements OnInit {
    protected readonly log: Logger;
    loading: boolean;
    @ViewChild('content') private content: any;

    lastSyncTimestamp: Date;
    numCachedFiles: number;
    numAvailableServerFiles: number;

    counter: number = 0;
    downloadProgress: number = 0;
    downloadProgressContent: DownloadContentProgress;
    private notDownloadedList: Content[] = [];
    ngUnsubscribe = new Subject<void>();
    UserRoles = UserRoles;
    user: User;

    constructor(
        private readonly loggingService: LoggingService,
        private readonly networkService: NetworkService,
        private readonly toastService: ToastService,
        private readonly loadingService: LoadingService,
        private readonly modalAlertService: ModalAlertService,
        private readonly fileContentService: FileContentService,
        private readonly exerciseContentsService: ExerciseContentsService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.log.setLevel('DEBUG');
    }

    async ngOnInit() {
        await Promise.all([
            this.fetchExerciseContentMetadataToCache(),
            this.setLastSyncTimestamp(),
            this.setNumCachedFiles(),
        ]);
    }

    ionViewDidLeave() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    scrollToBottom() {
        // this.content.scrollToBottom(300);
    }

    /**
     * Returns the cache date of the latest cached item in the cache
     */
    async setLastSyncTimestamp(): Promise<Date> {
        const cache = await this.fileContentService.getCachePublicStorage();
        const responses = await cache.matchAll();
        const maxUnixTimestamp = Math.max(
            ...responses
                .filter((x) => x.headers.has('x-cached-timestamp'))
                .map((x) => {
                    return parseInt(x.headers.get('x-cached-timestamp'));
                }),
        );
        let resultDate: Date = null;
        if (isFinite(maxUnixTimestamp)) {
            resultDate = new Date(maxUnixTimestamp);
        }
        this.lastSyncTimestamp = resultDate;
        return resultDate;
    }

    /**
     * Sets and returns the number of cached files.
     */
    async setNumCachedFiles(): Promise<number> {
        const cache = await this.fileContentService.getCachePublicStorage();
        this.numCachedFiles = (await cache.keys()).length;
        return this.numCachedFiles;
    }

    async isFastNetworkAvailable(): Promise<boolean> {
        // Check Connection Status 3G 4G WiFi
        if (this.networkService.hasLowNetworkQuality() || !this.networkService.isCurrentNetworkOnline()) {
            this.log.error('Slow Internet Connection');
            this.toastService.showToast(
                'Es ist keine ausreichende Internetverbindung vorhanden, um die Synchronisation zu starten.',
                IonicColor.danger,
            );
            return false;
        }
        return true;
    }

    async showConfirmationPrompt() {
        if (!(await this.isFastNetworkAvailable())) {
            return;
        }
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = 'OFFLINE_SYNC.START';
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description =
            'Sind Sie sicher, dass Sie mit der Offline-Synchronisation starten' +
            ' möchten? Es werden hierzu möglicherweise große Datenmengen auf Ihrem' +
            ' Endgerät gespeichert.';
        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'START';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                this.loading = true;
                await this.loadingService.startLoadingController({
                    message: 'Daten werden geladen...',
                    duration: 86400000,
                });
                await this.syncContentResponseWithCacheStorage();
            } catch (e) {
                this.loading = false;
                this.log.error('Error Sync', e);
                await this.loadingService.stopLoadingController();
            }
        }
    }

    /**
     * Returns a list of Content metadata objects that should be cached.
     * - Only video mime types
     * - iOS related filter quirks
     */
    async fetchExerciseContentMetadataToCache(): Promise<PaginatedResponse<Content[]>> {
        const contents = await this.exerciseContentsService.getExerciseContents();

        // Filter: Only pictures and videos
        contents.items = contents.items.filter((content) => Content.isVideoMimeType(content.mimeType));

        // Filter (only for iOS): keep only files with maximum size of 150MB
        if (Capacitor.getPlatform() === 'ios') {
            const megabyteLimit = 150;
            const byteLimit = megabyteLimit * 1024 ** 2;
            contents.items = contents.items.filter((content) => content.byteSize < byteLimit);
        }

        // Set number of files to be cached
        this.numAvailableServerFiles = contents.items.length;
        return contents;
    }

    async syncContentResponseWithCacheStorage() {
        const contents = await this.fetchExerciseContentMetadataToCache();

        if (!contents || contents.items.length < 1) {
            this.log.warn('No content to be downloaded');
            this.loading = false;
            await this.loadingService.stopLoadingController();
            return;
        }

        // Check which contents are already in CacheStorage
        const notDownloadedContents = await this.fileContentService.filterDownloadedFilesFromCache(contents.items);
        if (!notDownloadedContents || notDownloadedContents.length < 1) {
            this.log.info('All Contents are already in Cache');
            this.toastService.showToast('Offline-Synchronisation erfolgreich abgeschlossen', IonicColor.success);
            this.loading = false;
            await this.loadingService.stopLoadingController();
            return;
        }
        const numberOfDownloadedContents = contents.items.length - notDownloadedContents.length;
        // Check Free Space Memory
        const enoughMemory = await this.fileContentService.hasEnoughMemory(notDownloadedContents);
        if (!enoughMemory) {
            this.loading = false;
            this.toastService.showToast(
                'Auf Ihrem Endgerät ist nicht genügend Speicherplatz vorhanden, um die Synchronisation durchzuführen.',
                IonicColor.danger,
            );
            await this.loadingService.stopLoadingController();
            return;
        }
        // Set Media-Downloader-Observable and subscribe
        this.counter = numberOfDownloadedContents;
        this.subscribeToDownloadEmitter();
        const cache$ = await this.fileContentService.cacheFilesObservableWithProgress(
            notDownloadedContents,
            numberOfDownloadedContents,
        );
        cache$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (i) => {
                this.counter = i;
                this.downloadProgress = Math.floor((this.counter / this.numAvailableServerFiles) * 100);
                this.loadingService.changeLoadingMessage(
                    `${this.counter} Dateien von ${this.numAvailableServerFiles} wurden geladen`,
                );
                this.setNumCachedFiles();
            },
            async (e) => {
                this.loading = false;
                await this.loadingService.stopLoadingController();
                this.log.error('Error Caching Not-Downloaded-Contents', e, e.message);
                this.log.error(
                    'Error Not-Downloaded-Contents',
                    this.downloadProgressContent.content.origFileName,
                    this.downloadProgressContent.content.uuid,
                );
            },
            async () => {
                await this.handleCompleted();
            },
        );
    }

    private async handleCompleted() {
        this.log.info('cache$ Subscription Completed');
        this.loading = false;
        await this.loadingService.stopLoadingController();
        if (this.counter === this.numAvailableServerFiles) {
            this.toastService.showToast('Offline-Synchronisation erfolgreich abgeschlossen', IonicColor.success);
            this.counter = null;
            this.downloadProgressContent = null;
            this.downloadProgress = null;
            return;
        }
        this.toastService.showToast('Offline-Synchronisation wurde unterbrochen', IonicColor.danger);
        await this.ngOnInit();
    }

    private subscribeToDownloadEmitter() {
        this.fileContentService
            .getDownloaderProgressEmitter()
            .pipe(auditTime(200))
            .subscribe(
                (downloadProgress: DownloadContentProgress) => (this.downloadProgressContent = downloadProgress),
            );
        this.fileContentService
            .getNotDownloadedFilesEmitter()
            .subscribe((fileNotDownloaded: Content) => this.notDownloadedList.push(fileNotDownloaded));
    }
}
