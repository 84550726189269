import { NgModule } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { Brightness } from '@ionic-native/brightness/ngx';
import { PipesMeasurementModule } from './pipes/pipes-measurement.module';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { BluetoothClassicSerial } from '../custom-plugin/native-wrapper-plugin/bluetooth-classic-serial/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Stethoscope } from '../custom-plugin/native-wrapper-plugin/ekuore-pro-stethoscope/ngx';
import { NormTagFilterPipe } from './pipes/norm-tag-filter.pipe';

@NgModule({
    declarations: [],
    providers: [
        Camera,
        File,
        WebView,
        Network,
        InAppBrowser,
        BluetoothLE,
        BluetoothSerial,
        Brightness,
        InAppBrowser,
        BluetoothClassicSerial,
        OpenNativeSettings,
        Stethoscope,
        NormTagFilterPipe,
    ],
    imports: [PipesMeasurementModule],
    exports: [],
})
export class MeasurementModule {}
