import { LogLevel } from '../../../logging/logging.service';
import { User } from './user';
import { Session } from '../../../kc-auth/entities/session';

export class CurafidaSession extends Session {
    /**
     * Different tenants can have different backend api servers.
     * The backendUrl tells which api should be reached for backend requests.
     */
    backendUrl: string;

    /**
     * Every user is the member of one tenant.
     * The tenantId is extracted from the access token groups claim.
     */
    tenantId: string;

    /**
     * If set that is the log level of the session in the app LogViewer and the browser console.
     */
    globalLogLevel?: LogLevel;

    /**
     * User object extracted from access token payload.
     */
    user?: User = null;
}
