export enum IonicColor {
    danger = 'danger',
    success = 'success',
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    warning = 'warning',
    light = 'light',
    medium = 'medium',
    dark = 'dark',
    card = 'card',
}
