import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isAfter, isBefore } from 'date-fns';
import { ApiService } from '../../../api';
import { SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { MyMedaxFillLink } from '../../../my-medax/entities/my-medax-fill-link';
import { MyMedaxQuestionnaireSubmission } from '../../../my-medax/entities/my-medax-questionnaire-submission';
import { QuestionnaireType } from '../../../my-medax/entities/questionnaire-type';
import { MyMedaxRedirection } from '../../../my-medax/my-medax.redirection';
import { MyMedaxService } from '../../../my-medax/services/my-medax.service';
import { TaskResource } from '../../components/task/task.resource';

@Injectable({
    providedIn: 'root',
})
export class TherapyMyMedaxService {
    protected readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private loggingService: LoggingService,
        private readonly myMedaxService: MyMedaxService,
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async getMyMedaxFillLink(
        username: string,
        exerciseSessionId: string,
        myMedaxRedirection: MyMedaxRedirection,
        type?: QuestionnaireType,
        prefill?: boolean,
        prefillSubmissions?: string[],
        submissionVersioningUuid?: string,
    ): Promise<MyMedaxFillLink> {
        const url = new URL(`${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/myMedaxFillLink`);
        if (myMedaxRedirection.isActive) {
            url.searchParams.set('returnUri', encodeURIComponent(myMedaxRedirection.url().toString()));
        }
        if (type) url.searchParams.set('type', type.toString());
        if (prefill) url.searchParams.set('prefill', prefill.toString());
        if (prefillSubmissions) url.searchParams.set('prefillSubmissions', prefillSubmissions.toString());
        if (submissionVersioningUuid) {
            url.searchParams.set('submissionVersioningUuid', submissionVersioningUuid.toString());
        }
        return await this.http.get<MyMedaxFillLink>(url.toString()).toPromise();
    }

    async fetchMyMedaxQuestionnaireSubmissions(taskResource: TaskResource): Promise<MyMedaxQuestionnaireSubmission[]> {
        try {
            const submissions = await this.myMedaxService.getQuestionnaireSubmissionsByUser(
                taskResource.concernedUsername,
                0,
                null,
                SortBy.UPDATED_AT,
                SortOrder.DESC,
            );
            if (submissions?.items?.length > 0) {
                const contentUuid = taskResource?.exerciseSessionUserResults.find((x) =>
                    x.content.mimeType?.includes('pdf'),
                )?.contentUuid;
                const versioningUuid = submissions?.items?.find(
                    (x) => x.pdfContent === contentUuid,
                )?.submissionVersioningUuid;
                return submissions?.items
                    ?.filter((x) => x.submissionVersioningUuid === versioningUuid)
                    .sort((a, b) => {
                        if (isBefore(new Date(a.created_at), new Date(b.created_at))) {
                            return 1;
                        } else if (isAfter(new Date(a.created_at), new Date(b.created_at))) {
                            return -1;
                        }
                        return 0;
                    });
            }
            return [];
        } catch (e) {
            this.log.error('Error in initMyMedaxQuestionnaireSubmissions', e);
            this.toastService.showToast(this.translateService.instant('ERROR_LOADING_FORM'), IonicColor.danger);
        }
    }
}
