import { Content } from '../../therapy/entities/content';
import { MyMedaxQuestionnaireSubmission } from '../../my-medax/entities/my-medax-questionnaire-submission';
import { Consultation } from './consultation';

export class ConsultationContent {
    uuid: string;
    consultationUuid: string;
    consultation?: Consultation;
    consultationQuestionnaireUuid?: string;
    isFormContent: boolean;
    parentId?: string;
    uploaderFullName?: string;
    contentUuid: string;
    content?: Content;
    created_at: string;
    updated_at: string;
    myMedaxQuestionnaireSubmission?: MyMedaxQuestionnaireSubmission;
    myMedaxQuestionnaireSubmissionUuid?: string;
    name: string;
    mimeType: string;
    writeLock?: string;
    isFindingContent: boolean;
    isPdfReport: boolean;
}
