// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-list-item {
  margin-bottom: 10px;
  align-content: center;
  width: 50%;
}

.square-aspect {
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipsis-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/checkbox-list-modal/checkbox-list-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,qBAAA;EACA,UAAA;AACJ;;AAEA;EACI,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".checkbox-list-item {\n    margin-bottom: 10px;\n    align-content: center;\n    width: 50%;\n}\n\n.square-aspect {\n    aspect-ratio: 1 / 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.ellipsis-overflow {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
