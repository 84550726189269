import { Injectable } from '@angular/core';
import { CurafidaSession } from '../entities/user/curafida-session';
import { UserRoles } from '../entities/user';
import { AbstractAuthService } from '../../kc-auth/services';

@Injectable({ providedIn: 'root' })
export class CurafidaAuthService extends AbstractAuthService<CurafidaSession> {
    /**
     * Get the Tenant ID of the currently logged-in user or null if there is no user logged-in at the moment.
     */
    public async getTenantId(): Promise<string> {
        const session = this.getSession();
        if (!session) {
            this.log.error('[getTenantId] There is no active user session');
            return null;
        }
        if (!session.tenantId || !session.tenantId.startsWith('/tenants/')) {
            this.log.error('[getTenantId] No or invalid tenantId present in session');
            return null;
        }
        return session.tenantId;
    }

    /**
     * Lists the roles of the currently logged-in user.
     * @returns UserRoles[] - List of realm-roles strings.
     */
    public async getRoles(): Promise<UserRoles[] | string[]> {
        return this.getSession()?.user?.roles ?? [];
    }

    public async getDebuggingAttribute(): Promise<boolean> {
        return this.getSession()?.user?.debuggingEnabled ?? false;
    }
}
