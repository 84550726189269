import { ExerciseSessionUserState } from './exercise-session-state';
import { User } from '../../../auth/entities/user';

export class ExerciseSessionParticipant {
    user: User;
    state: ExerciseSessionUserState;
    /*
     * `state` and `exerciseSessionUserState` SHOULD be the same attribute
     * but due to incompatibilities with some backend queries it is not possible
     * to ensure the consistency of the attribute name
     */
    exerciseSessionUserState?: ExerciseSessionUserState;
}
