import { HypermediaLinks, HypermediaResource } from '../../../hateoas/hateoas.model';
import { ContentDto } from './content-dto';

export class Content extends ContentDto implements HypermediaResource {
    uuid: string;
    selected = false;
    origFileName: string;
    uploadUsername?: string;
    created_at?: string;
    updated_at?: string;
    contentUsage?: string;
    _links: HypermediaLinks;

    static isMyMedaxType(mimeType: string) {
        switch (mimeType) {
            case 'application/vnd.my-medax-questionnaire-template+json':
                return true;
            default:
                return false;
        }
    }
    static isImageMimeType(mimeType: string) {
        switch (mimeType) {
            case 'image/gif':
                return true;
            case 'image/png':
                return true;
            case 'image/jpeg':
                return true;
            case 'image/bmp':
                return true;
            case 'image/webp':
                return true;
            default:
                return false;
        }
    }
    static isPNGMimeType(mimeType: string) {
        switch (mimeType) {
            case 'image/png':
                return true;
            default:
                return false;
        }
    }
    static isJPGMimeType(mimeType: string) {
        switch (mimeType) {
            case 'image/jpeg':
                return true;
            default:
                return false;
        }
    }
    static isPDFMimeType(mimeType: string) {
        switch (mimeType) {
            case 'application/pdf':
                return true;
            default:
                return false;
        }
    }

    static isTextMimeType(mimeType: string) {
        switch (mimeType) {
            case 'text/plain':
                return true;
            default:
                return false;
        }
    }
    static isVideoMimeType(mimeType: string) {
        switch (mimeType) {
            case 'video/webm':
                return true;
            case 'video/ogg':
                return true;
            case 'video/mp4':
                return true;
            case 'video/mov':
                return true;
            case 'video/avi':
                return true;
            case 'video/quicktime':
                return true;
            default:
                return false;
        }
    }

    static isAudioMimeType(mimeType: string) {
        switch (mimeType) {
            case 'audio/mpeg': // mp3
                return true;
            case 'audio/wav':
                return true;
            default:
                return false;
        }
    }

    static isArticulateMimeType(mimeType: string) {
        switch (mimeType) {
            case 'application/vnd.articulate-course':
                return true;
            case 'application/vnd.articulate-lesson':
                return true;
            default:
                return false;
        }
    }

    static isArticulateZipMimeType(mimeType: string) {
        switch (mimeType) {
            case 'application/zip':
                return true;
            case 'application/x-zip-compressed':
                return true;
            case 'application/x-zip':
                return true;
            default:
                return false;
        }
    }
}
