import { Params } from '@angular/router';
import { MeasurementType } from '../../../../../../../libs/measurement/entities/models/measurement-type';

export class ButtonMeasurementInfo {
    buttonTitle: string;
    buttonSubtitle = 'Nicht gemessen';
    svgName: string;
    svgRadio = 'assets/Mona-Icons/b-w_Nicht_gemessen.svg';
    uriRedirection: string;
    queryParams: Params;
    disabled = false;
    simpleMeasurement = false; // Simple measurement are the ones who only needs a keyboard as input to complete the measurement
    measurementType: MeasurementType;
}
