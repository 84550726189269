import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, of } from 'rxjs';

import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { flatMap } from 'rxjs/internal/operators';
import {
    NoteCharacteristicModuleResource,
    NoteTag,
    NoteTagCategoriesResource,
    NoteTagCategory,
    NoteTagLabelDto,
    noteTagsInitialState,
    NoteTagsState,
} from '../entities/note-tags.model';
import { Logger, LoggingService } from '../../logging/logging.service';
import { ToastService } from '../../common/services/toast-service/toast-service.service';
import { AuthorizationPipe } from '../../hateoas/authorization.pipe';
import { IonicColor } from '../../common/entities/toast/ionic-color';

@Injectable({
    providedIn: 'root',
})
export class NoteTagsService {
    tags: NoteTag[] = [];

    private readonly log: Logger;
    private readonly model$ = new BehaviorSubject<NoteTagsState>({ ...noteTagsInitialState });
    readonly moduleConfig$ = this.model$.pipe(map((it) => it.moduleConfig));
    readonly characteristic$ = this.model$.pipe(map((it) => it.characteristic));
    readonly categories$ = this.model$.pipe(map((it) => it.characteristic.categories));
    readonly selected$ = this.model$.pipe(map((it) => it.selected));
    readonly allTags$ = this.categories$.pipe(
        map((it) => it.filter((category) => category.tags?.length > 0).flatMap((category) => category.tags)),
    );
    constructor(
        private readonly http: HttpClient,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly authorizationPipe: AuthorizationPipe,
        private readonly loggingService: LoggingService,
    ) {
        this.log = loggingService.getLogger(this.constructor.name);
    }

    private _isInitializing = false;

    get isInitializing(): boolean {
        return this._isInitializing;
    }

    private set isInitializing(value: boolean) {
        this._isInitializing = value;
    }

    get current(): NoteTagsState {
        return this.model$.value;
    }

    get selected(): NoteTagCategory {
        return this.model$.value.selected;
    }

    /**
     * Fetches all tag categories available for the current user, preselecting first.
     * @param fetchTagsEager - Preload the tags for all categories, default false, so the tags are only fetched once on category selection.
     */
    init(fetchTagsEager = false): void {
        this.isInitializing = true;
        (this.authorizationPipe.transform(this.current.moduleConfig, 'self', 'read')
            ? of(this.current.moduleConfig)
            : this.http.get<NoteCharacteristicModuleResource>(`notes/characteristic`)
        )
            .pipe(
                flatMap((it) => {
                    this.model$.value.moduleConfig = it;
                    if (!this.authorizationPipe.transform(it, 'categories', 'read')) {
                        return of(<NoteTagCategoriesResource>{ ...noteTagsInitialState.characteristic });
                    }
                    return this.http.get<NoteTagCategoriesResource>(`${it._links.categories.href}`).pipe(
                        flatMap((result) => {
                            if (fetchTagsEager && result.categories?.length > 0) {
                                const authorizedCategories = result.categories.filter((it) =>
                                    this.authorizationPipe.transform(it, 'tags', 'read'),
                                );
                                if (authorizedCategories.length > 0) {
                                    return forkJoin([
                                        ...authorizedCategories.map((it) =>
                                            this.http
                                                .get<NoteTag[]>(`${it._links.tags.href}`)
                                                .pipe(tap((tags) => (it.tags = tags))),
                                        ),
                                    ]).pipe(map(() => result));
                                }
                            }
                            return of(result);
                        }),
                    );
                }),
            )
            .subscribe(
                (result) => {
                    this.model$.next({
                        ...this.current,
                        characteristic: result,
                    });
                    if (result.categories[0]) {
                        this.select(result.categories[0]);
                    }
                },
                (response) => this.onError(response),
                () => (this.isInitializing = false),
            );
    }

    select(category: NoteTagCategory): void {
        this.fetchTags(category);
        this.model$.next({ ...this.current, selected: category });
    }

    createCategory(payload: NoteTagLabelDto): void {
        this.http.post<NoteTagCategory>(`${this.current.characteristic._links.self.href}`, payload).subscribe(
            (result) => {
                this.current.characteristic.categories.push(result);
                this.select(result);
                this.toastService.showToast('TAG.CATEGORY.SUCCESS.CREATE', IonicColor.success);
            },
            (response) => this.onError(response, { label: payload.label }),
        );
    }

    patchCategory(category: NoteTagCategory, payload: NoteTagLabelDto): void {
        this.http.patch<NoteTagCategory>(`${category._links.self.href}`, payload).subscribe(
            (result) => (category.label = result.label),
            (response) => this.onError(response, { label: payload.label }),
        );
    }

    deleteCategory(category: NoteTagCategory): void {
        this.http.delete(`${category._links.self.href}`).subscribe(
            () => {
                this.current.characteristic.categories.splice(
                    this.current.characteristic.categories.indexOf(category),
                    1,
                );
                if (this.selected === category) {
                    if (this.current.characteristic.categories[0]) {
                        this.select(this.current.characteristic.categories[0]);
                    } else {
                        this.model$.next({ ...this.current, selected: undefined });
                    }
                }
                this.toastService.showToast(this.translate.instant('TAG.CATEGORY.SUCCESS.DELETE'), IonicColor.success);
            },
            (response) => this.onError(response),
        );
    }

    createTag(payload: NoteTagLabelDto): void {
        const selected = this.current.selected;
        this.http.post<NoteTag>(`${selected._links.tags.href}`, payload).subscribe(
            (result) => {
                selected.tags.push(result);
                this.toastService.showToast(
                    this.translate.instant('TAG.SUCCESS.CREATE', { label: result.label }),
                    IonicColor.success,
                );
            },
            (response) => this.onError(response, { label: payload.label }),
        );
    }

    patchTag(noteTag: NoteTag, payload: NoteTagLabelDto): void {
        this.http.patch<NoteTag>(`${noteTag._links.self.href}`, payload).subscribe(
            (result) => (noteTag.label = result.label),
            (response) => this.onError(response, { label: payload.label }),
        );
    }

    deleteTag(tag: NoteTag): void {
        const selected = this.current.selected;
        this.http.delete(`${tag._links.self.href}`).subscribe(
            () => {
                selected.tags.splice(selected.tags.indexOf(tag), 1);
                this.toastService.showToast(this.translate.instant('TAG_WAS_DELETED'), IonicColor.success);
            },
            (response) => this.onError(response),
        );
    }

    clear(): void {
        this.model$.next({ ...noteTagsInitialState });
    }

    private async fetchTags(category: NoteTagCategory): Promise<void> {
        if (!category.tags && !category.isTagsLoading) {
            category.isTagsLoading = true;
            this.http.get<NoteTag[]>(`${category._links.tags.href}`).subscribe(
                (result) => (category.tags = result),
                (response) => this.onError(response),
                () => (category.isTagsLoading = false),
            );
        }
    }

    private onError(response: any, interpolateParams?: Record<string, string | number>) {
        this.log.error(response);
        this.toastService.showToast(
            this.translate.instant(response.error.message, interpolateParams),
            IonicColor.danger,
        );
    }
}
