import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { FormControl, FormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CalendarMode } from 'ng2-date-picker/lib/common/types/calendar-mode';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { endOfDay, startOfDay } from 'date-fns';

const DEF_CONF: IDatePickerConfig = {
    firstDayOfWeek: 'mo',
    disableKeypress: false,
    closeOnSelect: false,
    closeOnSelectDelay: 100,
    openOnFocus: false,
    openOnClick: false,
    onOpenDelay: 100,
    closeOnEnter: false,
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 5,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ':',
    hideInputContainer: false,
    returnedValueType: ECalendarValue.String,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    numOfMonthRows: 4,
};

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
    @Input()
    title?: string;
    @Input()
    inputDate: string;
    @Input()
    disablePastDays = false;
    @Input()
    minDate?: string;
    @Input()
    disableFutureDays = false;
    @Input()
    maxDate?: string;
    @Input()
    minuteStep = 5;
    @Input()
    roundedMinutes? = true;
    @Input()
    datePickerMode: CalendarMode = 'day';
    config: IDatePickerConfig;
    displayDate: dayjs.Dayjs;
    datePickerFormGroup: FormGroup;
    private dateFormat = 'DD.MM.YYYY';
    private readonly log: Logger;

    constructor(
        private popoverCtrl: PopoverController,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        dayjs.locale('de');
        dayjs.extend(customParseFormat);
    }

    ngOnInit(): void {
        this.initDatePickerConfig();
        /* roundToNearestMinutes is currently bugged and does not work properly,
         * see https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/issues/6680.
         * This custom method seems to work.
         */
        const initialDate = this.inputDate ?? new Date().toISOString();
        const millisecondsToRound = 1000 * 60 * this.minuteStep;
        const roundedDate = new Date(
            Math.ceil(new Date(initialDate).getTime() / millisecondsToRound) * millisecondsToRound,
        ).toISOString();
        this.displayDate = dayjs(roundedDate);
        const inputDate = dayjs(roundedDate).format(this.dateFormat);
        this.datePickerFormGroup = new FormGroup({
            picker: new FormControl({ value: inputDate, disabled: false }),
        });
    }

    async sendDate() {
        const value = this.datePickerFormGroup.controls['picker'].value;
        const isoStringValue = dayjs(value, this.dateFormat).toISOString();
        await this.popoverCtrl.dismiss(isoStringValue);
    }

    async dismissPopover() {
        await this.popoverCtrl.dismiss();
    }

    private initDatePickerConfig(): void {
        if (this.disableFutureDays) {
            this.maxDate =
                this.datePickerMode === 'day' ? endOfDay(new Date()).toISOString() : new Date().toISOString();
        }
        if (this.disablePastDays) {
            this.minDate =
                this.datePickerMode === 'day' ? startOfDay(new Date()).toISOString() : new Date().toISOString();
        }
        if (this.datePickerMode === 'day') {
            this.dateFormat = 'DD.MM.YYYY';
        } else if (this.datePickerMode === 'daytime') {
            this.dateFormat = 'DD.MM.YYYY H:mm';
        }
        this.config = {
            ...DEF_CONF,
            minutesInterval: this.roundedMinutes ? this.minuteStep : 1,
            format: this.dateFormat,
            max: this.maxDate ? dayjs(this.maxDate) : null,
            min: this.minDate ? dayjs(this.minDate) : null,
        };
    }
}
