import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { SelectCustomEvent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'curafida-select-input',
    templateUrl: './curafida-select-input.component.html',
    styleUrls: ['./curafida-select-input.component.scss'],
})
export class CurafidaSelectInputComponent extends CurafidaInputComponent implements ControlValueAccessor {
    @Input()
    itemList: SelectInputItem[];

    @Output()
    selectionChange: EventEmitter<SelectCustomEvent> = new EventEmitter<SelectCustomEvent>();

    @Input()
    allowInputSelection: boolean;
    @Input()
    translationPrefix = '';
    @Input()
    disabled: boolean;

    constructor(
        @Optional()
        @Self()
        public ngControl: NgControl,
        public translateService: TranslateService,
    ) {
        super(ngControl, translateService);
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    onIonChange(event: SelectCustomEvent): void {
        this.selectionChange.emit(event);
    }
}

export class SelectInputItem {
    value: any;
    label: string;

    constructor(value?: any, label?: string) {
        this.value = value;
        this.label = label;
    }

    static fromKeyValuePair([key, value]: [any, string]): SelectInputItem {
        return new SelectInputItem(key, value);
    }
    static fromArray(value: number): SelectInputItem {
        return new SelectInputItem(value, value.toString());
    }
}
