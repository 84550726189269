import { Component, Input } from '@angular/core';
import { ErrorMessage } from '../../../entities/error-const';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'curafida-input-errors',
    templateUrl: './curafida-input-errors.component.html',
})
export class CurafidaInputErrorsComponent {
    @Input()
    errorMessages: ErrorMessage[];

    @Input()
    inputControl: AbstractControl;
}
