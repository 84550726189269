import { Pipe, PipeTransform } from '@angular/core';
import { FrontendNormRange } from '../entities/frontend-norm-range';
import { FilterTagCategory } from '../../common/entities/filter-tag';

@Pipe({
    standalone: true,
    name: 'normTagFilter',
    pure: true,
})
export class NormTagFilterPipe implements PipeTransform {
    transform(roles: FrontendNormRange[], label: string): FilterTagCategory {
        return {
            label,
            uuid: 'normwerte',
            tags: roles,
        };
    }
}
