// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  display: flex;
  flex-flow: column;
  min-height: 100%;
}
form > div {
  flex: 1 0 auto;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/patient-report-modal/patient-report-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACI;EACI,cAAA;AACR","sourcesContent":["form {\n    display: flex;\n    flex-flow: column;\n    min-height: 100%;\n\n    > div {\n        flex: 1 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
