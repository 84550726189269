export enum ModuleName {
    USER_MASTER_DATA = 'USER_MASTER_DATA',
    MEDICAL_HISTORY = 'MEDICAL_HISTORY',
    CHAT = 'CHAT',
    TASK = 'TASK',
    CONSULTATION = 'CONSULTATION',
    ORDER = 'ORDER',
    MEASUREMENT = 'MEASUREMENT',
    TRAINING = 'TRAINING',
    LEARNING = 'LEARNING',
    MEDICATION = 'MEDICATION',
    NOTE = 'NOTE',
    LOGBOOK = 'LOGBOOK',
    SCREENING = 'SCREENING',
}

export enum ModuleLabel {
    USER_MASTER_DATA = 'USER_MASTER_DATA',
    MEDICAL_HISTORY = 'MEDICAL_HISTORY.SINGULAR',
    CHAT = 'CHAT.SINGULAR',
    TASK = 'TASK.SINGULAR',
    CONSULTATION = 'CONSULTATION.PLURAL',
    ORDER = 'ORDER.PLURAL',
    MEASUREMENT = 'MEASUREMENT',
    TRAINING = 'TRAINING.SINGULAR',
    LEARNING = 'LEARNING.SINGULAR',
    MEDICATION = 'MEDICATION',
    NOTE = 'NOTE.SINGULAR',
    LOGBOOK = 'LOGBOOK',
    SCREENING = 'SCREENING.NAME',
}
