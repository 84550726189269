import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { SelectInputItem } from '../curafida-select-input/curafida-select-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'curafida-text-input-autocomplete',
    templateUrl: './curafida-text-input-autocomplete.component.html',
    styleUrls: ['./curafida-text-input-autocomplete.component.scss'],
})
export class CurafidaTextInputAutocompleteComponent extends CurafidaInputComponent {
    @Input()
    unit: string;

    @Input()
    explanation: string;

    @Input()
    inputType = 'text';

    @Input()
    list: SelectInputItem[];

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
    ) {
        super(ngControl, translateService);
    }
}
