// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sc-ion-input-md-h {
  --padding-start: 0 !important;
}

ion-content {
  position: relative;
}

ion-content > label {
  position: absolute;
}

.label-input-autocomplete {
  height: 40%;
  font-size: 12px !important;
  top: 0;
  width: 100%;
  position: relative;
}

.input-autocomplete {
  border: none;
  height: 50%;
  background: white;
  font-size: 15.5px;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-text-input-autocomplete/curafida-text-input-autocomplete.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,0BAAA;EACA,MAAA;EACA,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;AACJ","sourcesContent":[".sc-ion-input-md-h {\n    --padding-start: 0 !important;\n}\n\nion-content {\n    position: relative;\n}\n\nion-content > label {\n    position: absolute;\n}\n\n.label-input-autocomplete {\n    height: 40%;\n    font-size: 12px !important;\n    top: 0;\n    width: 100%;\n    position: relative;\n}\n\n.input-autocomplete {\n    border: none;\n    height: 50%;\n    background: white;\n    font-size: 15.5px;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
