import { ExerciseSession } from './exercise-session';
import { ExerciseSessionState } from './exercise-session-state';

export class ExerciseSessionOfTherapist {
    exerciseSession: ExerciseSession;
    exerciseSessionTherapistState: ExerciseSessionState;
    username: string;
    therapyName: string;
    exerciseSession_id: number;
    isFinishedButtonDisabled = true;
}
