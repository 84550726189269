import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TutorialDefinition } from '../../entities/tutorial-slide';
import { TutorialSliderComponent } from '../tutorial-slider/tutorial-slider.component';

@Component({
    selector: 'lib-tutorial-slides-modal',
    templateUrl: './tutorial-slides-modal.component.html',
    styleUrls: ['./tutorial-slides-modal.component.scss'],
})
export class TutorialSlidesModalComponent implements OnInit {
    @ViewChild('tutorialSliderComponent') tutorialSliderComponent: TutorialSliderComponent;

    tutorialDefinition: TutorialDefinition;
    title = '';

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
    ) {}

    async ngOnInit() {
        this.tutorialDefinition = this.params.get('modalDefinition');
        if (this.tutorialDefinition) this.title = this.tutorialDefinition.title;
    }

    async backStep() {
        await this.tutorialSliderComponent.backStep();
    }

    async fowardStep() {
        if (this.tutorialSliderComponent.currentIndex === this.tutorialSliderComponent.slideshow.list.length - 1) {
            this.dismissModal();
        }
        await this.tutorialSliderComponent.fowardStep();
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            isTutorialChecked: this.tutorialSliderComponent.isTutorialChecked,
        });
    }
}
