// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.halved-box {
  width: 50%;
  max-width: 50%;
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.filename {
  flex: 1 1 auto;
  align-self: center;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-content-input/curafida-content-input.component.scss"],"names":[],"mappings":"AAAA;EACI,qDAAA;AACJ;;AAEA;EACI,UAAA;EACA,cAAA;EACA,qDAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".item {\n    border-bottom: 1px solid var(--ion-color-light-shade);\n}\n\n.halved-box {\n    width: 50%;\n    max-width: 50%;\n    border-bottom: 1px solid var(--ion-color-light-shade);\n}\n\n.filename {\n    flex: 1 1 auto;\n    align-self: center;\n    overflow: hidden;\n    display: inline-block;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
