import { LoginUserWithoutPasswordDto, User } from '../../../auth/entities/user';
import { Inject, Injectable, Optional } from '@angular/core';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { HttpClient } from '@angular/common/http';
import { UserCustomPropertySchema } from '../../../auth/entities/user/user-custom-property';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    Group,
    SelfRegistrationConfig,
    SelfRegistrationModel,
} from '../../components/register-form/self-registration.model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CurafidaFrontendConfiguration } from '../../../common/entities/curafida-frontend-configuration';

@Injectable({
    providedIn: 'root',
})
export class SelfRegistrationService {
    readonly model$ = new BehaviorSubject<SelfRegistrationModel>(SelfRegistrationModel.initialState());
    readonly selected$ = this.model$.pipe(
        map((it) => it.selected),
        distinctUntilChanged(),
    );
    readonly customFormProperties$: Observable<UserCustomPropertySchema[]> = this.model$.pipe(
        map((it) => it.customFormProps),
    );
    private readonly baseUrl: URL;

    constructor(
        @Optional() @Inject('environmentConfig') private readonly environmentConfig: CurafidaFrontendConfiguration,
        @Optional()
        private readonly loadingService: LoadingService,
        private readonly http: HttpClient,
    ) {
        if (environmentConfig?.features?.user?.registerUrl) {
            const baseUrl = environmentConfig?.features?.user?.registerUrl;
            if (baseUrl) {
                this.baseUrl = new URL('/api', baseUrl);
            } else {
                console.warn('Registration url is missing in env config!');
            }
        }
    }

    get customFormProperties(): UserCustomPropertySchema[] {
        return this.model$.value.customFormProps;
    }

    init(): void {
        this.http.get<SelfRegistrationConfig>(`${this.baseUrl}/registration`).subscribe((result) =>
            this.model$.next({
                ...this.model$.value,
                config: result,
                selected: result.groups?.length === 1 ? result.groups[0] : undefined,
            }),
        );
    }

    fetchCustomProps(group: Group): void {
        this.http
            .get<UserCustomPropertySchema[]>(this.baseUrl.toString() + '/' + group._links.customFormConfig.href)
            .pipe(map((it) => plainToInstance(UserCustomPropertySchema, it)))
            .subscribe((it) => this.model$.next({ ...this.model$.value, customFormProps: it }));
    }

    /**
     * postPatientSelfRegistration
     * @param user - LoginUserWithoutPasswordDto
     * @param executeActionsEmail - boolean
     * @param groupPath - string
     * (Self) Register a new patient with a username and an email address
     */
    async postPatientSelfRegistration(
        user: LoginUserWithoutPasswordDto,
        groupPath?: string,
        executeActionsEmail = true,
    ): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${this.baseUrl.href}/${this.model$.value.config._links?.self?.href}`);
        if (executeActionsEmail) url.searchParams.set('executeActionsEmail', executeActionsEmail.toString());
        if (groupPath) url.searchParams.append('groupPath', groupPath);
        url.searchParams.append('keycloakClientId', this.environmentConfig.keycloak.clientId);
        return await this.http
            .post<User>(url.toString(), user)
            .toPromise()
            .finally(() => {
                this.loadingService.stopLoadingModal();
            });
    }
}
