import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringForSecondsPipe',
})
export class StringForSecondsPipe implements PipeTransform {
    transform(value: number, ...args: any[]): any {
        if (value > 1) {
            return `${value} Sekunden`;
        }
        return `${value} Sekunde`;
    }
}
