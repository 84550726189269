import { ButtonConfig } from './modal-button';
import { ModalTyp } from './modal-typ';

export class ModalConfig {
    title: string;
    titleIcon?: string;
    description: string;
    modalTyp: ModalTyp;
    buttonLeft: ButtonConfig;
    buttonRight: ButtonConfig;
    isdatePickerEnable = false;
    formGroup: ModalFormGroup[] = [];
    selectItem: { value: string; label: string }[];
    hasSelectList = false;
    descriptionIsHTML?: boolean;
}

export class ModalFormGroup {
    label: string;
    value: string;
    type: FieldType;

    constructor(label: string, value: string, type: FieldType) {
        this.label = label;
        this.value = value;
        this.type = type;
    }
}

export enum FieldType {
    NUMBER = 'NUMBER',
    INPUT = 'INPUT',
    DATE_PICKER = 'DATE_PICKER',
    TEXT_AREA = 'TEXT_AREA',
}
