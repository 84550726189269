import { MigrationInterface, QueryRunner } from 'typeorm';

export class AddIsExportPending1591699841611 implements MigrationInterface {
    name = 'AddIsExportPending1591699841611';

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query('ALTER TABLE Patient ADD isExportPending boolean default false;');
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query('ALTER TABLE Patient DROP COLUMN isExportPending;');
    }
}
