import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StyleService } from '../../../common/services/style/style.service';
import { BasePageConfig } from '../../../config/entities';
import { ConfigService } from '../../../config/services';
import { Group } from '../../entities/group';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-group-short-info',
    templateUrl: './group-short-info.component.html',
    styleUrls: ['./group-short-info.component.scss'],
})
export class GroupShortInfoComponent implements OnInit {
    @Input() nameOnly = false;
    isMobile = false;
    pageConfig: BasePageConfig;
    groupForm: FormGroup;

    constructor(
        private styleService: StyleService,
        private configService: ConfigService,
        private formBuilder: FormBuilder,
    ) {}

    private _group: Group;

    get group(): Group {
        return this._group;
    }

    @Input() set group(group: Group) {
        this._group = group;
        this.initGroupForm();
    }

    ngOnInit() {
        this.isMobile = this.styleService.isMobile$;
        if (this.configService.config.pageConfigs) {
            this.pageConfig = this.configService.config.pageConfigs.find((page) => page.pageId === 'PATIENT_DETAIL');
        }
        this.initGroupForm();
    }

    initGroupForm() {
        const groupName = this.group == null ? '' : this.group.name;
        const groupPath = this.group == null ? '' : this.group.path.split('/');
        const parentName = this.group == null ? '' : groupPath[groupPath.length - 2];
        const category = this.group == null ? '' : this.group.category;

        this.groupForm = this.formBuilder.group({
            groupName: new FormControl({ value: groupName, disabled: true }),
            parentName: new FormControl({ value: parentName, disabled: true }),
            category: new FormControl({ value: category, disabled: true }),
        });
    }
}
