import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'curafida-radio-button-input',
    templateUrl: './curafida-radio-button-input.component.html',
    styleUrls: ['./curafida-radio-button-input.component.scss'],
})
export class CurafidaRadioButtonInputComponent extends CurafidaInputComponent {
    @Input()
    suffix: string;

    @Input()
    defaultValue: any = false;

    @Input()
    itemList: RadioButtonInputItem[];

    @Output()
    ionChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
    ) {
        super(ngControl, translateService);
    }
}

export class RadioButtonInputItem {
    value: any;
    label: string;
    subtitle?: string;

    constructor(value?: any, label?: string, subtitle?: string) {
        this.value = value;
        this.label = label;
        this.subtitle = subtitle;
    }
}
