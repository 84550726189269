import { User } from '../../auth/entities/user';
import { OrderEvent } from './order-event';
import { Group } from '../../user/entities/group';
import { Chat } from '../../chat/entities/chat';
import { OrderContent } from './order-content';

export enum OrderStateEnum {
    ORDER_CREATED = 'ORDER_CREATED', // Auftrag erstellt
    REQUEST_IN_CREATION = 'REQUEST_IN_CREATION', // Anfrage in Bearbeitung
    REQUEST_UNDER_REVIEW = 'REQUEST_UNDER_REVIEW', // Anfrage in Prüfung
    EXAMINATION_REQUESTED = 'EXAMINATION_REQUESTED', // Befund angeordnet
    FINDING_IN_CREATION = 'FINDING_IN_CREATION', // Befund in Bearbeitung
    FINDING_IN_APPROVAL = 'FINDING_IN_APPROVAL', // Befund in Freigabe
    FINDING_APPROVED = 'FINDING_APPROVED', // Befund freigegeben
    ORDER_DELETED = 'ORDER_DELETED', // Auftrag gelöscht
    ORDER_CANCELED = 'ORDER_CANCELED', // Auftrag abgebrochen
    ORDER_DECLINED = 'ORDER_DECLINED', // Auftrag abgelehnt
    ORDER_REVOKED = 'ORDER_REVOKED', // Auftrag widerrufen
    ORDER_FINISHED = 'ORDER_FINISHED', // Auftrag abgeschlossen
}

export class SelectedService {
    name: string;
    description?: string;
    selectedAt?: string;
    selectedBy?: string;
}

export class Order {
    uuid: string;
    clientGroupUuid: string;
    client: Group;
    providerGroupUuid: string;
    provider: Group;
    patientUsername: string;
    patient: User;
    responsibleUsername: string;
    responsibleUser: User;
    subject: string;
    subjectNotes?: string;
    contactInfo?: string;
    caseNumber?: string;
    clinicalNotes?: string;
    selectedClientServices?: SelectedService[];
    selectedProviderServices?: SelectedService[];
    justifyingIndicationGiven?: boolean;
    justifyingIndicationNotes?: string;
    state: OrderStateEnum;
    chatUuid?: string;
    chat: Chat;
    events: OrderEvent[];
    contents: OrderContent[];
    created_at: string;
    updated_at: string;

    constructor(client: Group, provider: Group, patient: User) {
        this.client = client;
        this.provider = provider;
        this.patient = patient;
        this.state = OrderStateEnum.ORDER_CREATED;
        this.justifyingIndicationGiven = false;
    }
}

export class CreateOrderDto {
    clientGroupUuid: string;
    providerGroupUuid: string;
    patientUsername: string;

    constructor(clientGroupUuid: string, providerGroupUuid: string, patientUsername: string) {
        this.clientGroupUuid = clientGroupUuid;
        this.providerGroupUuid = providerGroupUuid;
        this.patientUsername = patientUsername;
    }
}
