/**
 * This is a template for new plugin wrappers
 *
 * TODO:
 * - Add/Change information below
 * - Document usage (importing, executing main functionality)
 * - Remove any imports that you are not using
 * - Remove all the comments included in this template, EXCEPT the @Plugin wrapper docs and any other docs you added
 * - Remove this note
 *
 */
import { Injectable } from '@angular/core';
import { Plugin, Cordova, IonicNativePlugin } from '@ionic-native/core';

/**
 * @name Stethoscope
 * @description
 * This plugin does something
 *
 * @usage
 * ```typescript
 * import { Stethoscope } from '@ionic-native/cordova-plugin-stethoscope';
 *
 *
 * constructor(private stethoscope: Stethoscope) { }
 *
 * ...
 *
 *
 * this.stethoscope.functionName('Hello', 123)
 *   .then((res: any) => console.log(res))
 *   .catch((error: any) => console.error(error));
 *
 * ```
 */
@Plugin({
  pluginName: 'Stethoscope',
  plugin: 'cordova-plugin-stethoscope',
  pluginRef: 'Stethoscope',
  platforms: ['Android']
})
@Injectable()
export class Stethoscope extends IonicNativePlugin {

  /**
   * Connect to a stethoscope and start the audio stream automatically
   * All other functionalities are only available after establishing a successful connection
   * @param {string} accessPoint - The SSID of the stethoscope
   * @returns {Promise} - Represents either a successful execution or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  connect(accessPoint: string): Promise<any> {
    return;
  }

  /**
   * Check if there is a stethoscope currently connected
   * @returns {Promise} - Represents the boolean resulting from the connection status
   */
  @Cordova({
    otherPromise: true,
  })
  isConnected(): Promise<any> {
    return;
  }

  /**
   * Trigger to start saving the audio stream to the chunks
   * @returns {Promise} - Represents either a successful execution or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  startRecording(): Promise<any> {
    return;
  }

  /**
   * Trigger to stop saving the audio stream and start processing the saved raw data into a blob
   * @returns {Promise} - Represents either the blob resulting from the recording or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  stopRecording(): Promise<any> {
    return;
  }

  /**
   * Stop the audio stream and disconnect from the stethoscope
   * The plugin will be unable to perform any actions until a connection is established again
   * @returns {Promise} - Represents either a successful execution or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  disconnect(): Promise<any> {
    return;
  }

  /**
   * Change the current filter status to the next one
   * @returns {Promise} - Represents either a successful execution or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  changeFilterStatus(): Promise<any> {
    return;
  }

  /**
   * Obtain the current battery level of the connected stethoscope
   * @returns {Promise} - Represents either the battery percentage, a "charging" status or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getBatteryLevel(): Promise<any> {
    return;
  }

  /**
   * Obtain the firmware version of the currently connected stethoscope
   * @returns {Promise} - Represents either the firmware version or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getFirmwareVersion(): Promise<any> {
    return;
  }

  /**
   * Obtain the serial number of the currently connected stethoscope
   * @returns {Promise} - Represents either the serial number or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getSerialNumber(): Promise<any> {
    return;
  }

  /**
   * Obtain the DSP firmware version of the currently connected stethoscope
   * @returns {Promise} - Represents either the DSP firmware version or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getDspFirmwareVersion(): Promise<any> {
    return;
  }

  /**
   * Obtain the current filter status of the connected stethoscope
   * @returns {Promise} - Represents either the filter status or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getFilterStatus(): Promise<any> {
    return;
  }

  /**
   * Obtain the current volume level of the connected stethoscope
   * @returns {Promise} - Represents either the volume percentage or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getVolumeLevel(): Promise<any> {
    return;
  }

  /**
   * Obtain the hardware version of the currently connected stethoscope
   * @returns {Promise} - Represents either the hardware version or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getHardwareVersion(): Promise<any> {
    return;
  }

  /**
   * Obtain the SSID of the currently connected stethoscope
   * @returns {Promise} - Represents either the SSID or throws an error on its failure
   */
  @Cordova({
    otherPromise: true,
  })
  getStethoscopeSSID(): Promise<any> {
    return;
  }

}
