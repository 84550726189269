import { Injectable } from '@angular/core';
import { Logger, LoggingService } from '../../logging/logging.service';
import { BasePageConfig, PageConfig } from '../../config/entities';
import { UserRoles } from '../../auth/entities/user';
import { ConfigService } from '../../config/services';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    roles: UserRoles[] | string[] = [];
    protected readonly log: Logger;

    constructor(
        protected readonly configService: ConfigService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        // this.log.setLevel(LogLevel.DEBUG);
    }

    static fetchSubPageConfig(
        subPagesConfig: BasePageConfig[],
        roles: UserRoles[] | string[],
        configService: ConfigService,
    ) {
        return subPagesConfig?.filter((page) => this.hasRoleAndModule(page, roles, configService));
    }

    private static hasRoleAndModule(page: PageConfig, roles: UserRoles[] | string[], configService: ConfigService) {
        if (!configService.config.features[page.module]?.enabled) return false;
        for (const pageRole of page.role) {
            return roles.includes(pageRole as UserRoles);
        }
    }
}
