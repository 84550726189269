import { FeedBackDto } from './feedback-dto';
import { DurationUnit } from '../duration';
import { IntensityUnit } from '../exerciseGoal/exerciseGoalDto';
import { FeedbackFlag } from './feedback-comment';

export class FeedbackExerciseParams {
    order: number;
    duration: number;
    durationUnit: DurationUnit;
    intensity: number;
    intensityUnit: IntensityUnit;
    pulse: number;
    additionalParams?: ExerciseGoalAdditionalParam[];

    constructor(
        order: number,
        duration?: number,
        durationUnit?: DurationUnit,
        intensity?: number,
        intensityUnit?: IntensityUnit,
        pulse?: number,
    ) {
        this.order = order;
        this.intensity = intensity;
        this.intensityUnit = intensityUnit;
        this.duration = duration;
        this.durationUnit = durationUnit;
        this.pulse = pulse;
    }
}

export class FeedBack extends FeedBackDto {
    id: number;
    created_at: string;
    updated_at: string;
    exerciseSession_id: number;
    user_username: string;
    feedbackCommentUuid: string;
    pain: number;
    exerciseParams: FeedbackExerciseParams[];
    borgFlag: FeedbackFlag;
    commentFlag: FeedbackFlag;
    overallFlag: FeedbackFlag;
    painFlag: FeedbackFlag;
    pulseFlag: FeedbackFlag;
}

export class ExerciseGoalAdditionalParam {
    name?: string;
    description?: string;
    value: string;
    unitString?: string;
}
