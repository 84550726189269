import {
    ExerciseSessionType,
    ExerciseSubType,
    ExerciseToolSettings,
    ExerciseType,
    LocationInfo,
    PresenceType,
} from '../exerciseSession';
import { UserRoles } from '../../../auth/entities/user';
import { Tag } from '../tag/tag.entity';
import { Exercise } from './exercise';

export class ExerciseDto {
    mainGoal: string;
    title: string;
    description: string;
    duration?: string;
    exerciseSessionType: ExerciseSessionType;
    needsSchedule = false;
    autoActivation = true;
    minGroupSize?: number;
    maxGroupSize?: number;
    responsibleUserRole?: UserRoles;
    exerciseType: ExerciseType;
    tags?: Tag[];
    isGroup: boolean;
    location?: LocationInfo;
    presenceType?: PresenceType;
    exerciseToolSettings?: ExerciseToolSettings;
    metaInfo?: any;
    exerciseSubType?: ExerciseSubType;
    notifyOnStartTime?: boolean;
    notifyOnEndTime?: boolean;
    notifyOnDelayedTime?: boolean;
}

export class ExerciseDtoBuilder {
    private exerciseDto = new ExerciseDto();

    copyExerciseDtoFromExercise(exercise: Exercise) {
        this.exerciseDto.title = exercise.title;
        this.exerciseDto.description = exercise.description;
        this.exerciseDto.duration = exercise.duration;
        this.exerciseDto.exerciseSessionType = exercise.exerciseSessionType;
        this.exerciseDto.needsSchedule = exercise.needsSchedule;
        this.exerciseDto.autoActivation = true;
        this.exerciseDto.minGroupSize = exercise.minGroupSize;
        this.exerciseDto.maxGroupSize = exercise.maxGroupSize;
        this.exerciseDto.responsibleUserRole = exercise.responsibleUserRole;
        this.exerciseDto.exerciseType = exercise.exerciseType;
        this.exerciseDto.tags = exercise.tags;
        this.exerciseDto.isGroup = exercise.isGroup;
        this.exerciseDto.location = exercise.location;
        this.exerciseDto.presenceType = exercise.presenceType;
        this.exerciseDto.exerciseToolSettings = exercise.exerciseToolSettings;
        this.exerciseDto.exerciseSubType = exercise.exerciseSubType;
        this.exerciseDto.notifyOnStartTime = exercise.notifyOnStartTime;
        this.exerciseDto.notifyOnDelayedTime = exercise.notifyOnDelayedTime;
        this.exerciseDto.notifyOnEndTime = exercise.notifyOnEndTime;
        return this;
    }

    build(): ExerciseDto {
        return this.exerciseDto;
    }
}
