import { ExerciseSessionType } from './exercise-session-type';
import { ExerciseType } from './exercise-type';
import { ExerciseSubType, PresenceType } from './exercise-session-dto';

export class ExerciseTool {
    name: string;
    exerciseSessionTypes: ExerciseSessionType[];
    exerciseTypes: ExerciseType[];
    presenceTypes: PresenceType[];
    exerciseSubType: ExerciseSubType[];
    config?: Record<string, any>;
}

export class ExerciseToolSetting {
    name: string;
    config?: Record<string, any>;

    constructor(name: string) {
        this.name = name;
    }
}

export class ExerciseToolSettings {
    enabledTools: ExerciseToolSetting[];
}
