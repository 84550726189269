import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@capgo/inappbrowser';
import { ReplaySubject } from 'rxjs';
import { ApiService } from '../../../api';
import { CurafidaAuthService } from '../../../auth/services';
import { BrowserNavigationService } from '../../../common/services/browser-navigation/browser-navigation.service';
import { Device } from '../../entities/device/device';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    devices: ReplaySubject<Device[]> = new ReplaySubject(1);

    constructor(
        public http: HttpClient,
        protected authService: CurafidaAuthService,
        private browserNavigation: BrowserNavigationService,
    ) {}

    async garminHealthAuth() {
        const url = `${ApiService.url}garminHealthApi/requestAuth/${this.authService.getSession().user.username}`;
        const oauth_link = await this.http
            .get(url)
            .toPromise()
            .then((link) => {
                return link;
            })
            .catch((e) => {
                return e;
            });
        this.validateRequestTokenbyUserAuthorization(oauth_link);
    }

    async validateRequestTokenbyUserAuthorization(link: string) {
        this.browserNavigation.openTargetBlank(link);
        if (!Capacitor.isNativePlatform()) return;
        InAppBrowser.addListener('urlChangeEvent', (event) => {
            if (event.url === 'https://app.edumovo.de/') {
                InAppBrowser.close();
            }
        });
    }

    async requestGarminAuthAccessToken(oauth_token: string, oauth_verifier: string) {
        return this.http
            .get(
                ApiService.url +
                    'garminHealthApi/callback?oauth_token=' +
                    oauth_token +
                    '&oauth_verifier=' +
                    oauth_verifier,
            )
            .toPromise()
            .then((success) => {
                return success;
            })
            .catch((e) => {
                // e = this.handleError(e);
                console.error(`error`, e);
                return e;
            });
    }

    async checkAuthGarmin(): Promise<boolean> {
        const url = `${ApiService.url}garminHealthApi/isAccountActive/${this.authService.getSession().user.username}`;
        return this.http
            .get(url)
            .toPromise()
            .then((state) => {
                return state;
            })
            .catch((e) => {
                console.error('error to checkAuthFitbit', e);
                return false;
            }) as Promise<boolean>;
        // TODO: Correct/check this response.
    }

    async revokeGarminAuth() {
        const url = `${ApiService.url}garminHealthApi/revokeAuth/${this.authService.getSession().user.username}`;
        return this.http
            .delete(url, ApiService.options)
            .toPromise()
            .then((state) => {
                return state;
            })
            .catch((e) => {
                console.error('error to revokeAuthFitbit', e);
                return false;
            }) as Promise<boolean>;
    }
}
