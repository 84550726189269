import { Observable, of, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { flatMap } from 'rxjs/internal/operators';

/**
 * Debounce UI events.
 * Docs: https://rxjs.dev/api/operators/debounce
 */
export class Debounce<EventValue> {
    private readonly debounce$ = new Subject<() => Observable<EventValue>>();

    readonly value$ = this.debounce$.pipe(
        debounceTime(this.timeInMs),
        flatMap((src) => src()),
    );

    constructor(readonly timeInMs = 600) {}

    next(event: () => Observable<EventValue>): void {
        this.debounce$.next(event);
    }

    nextValue(eventValue: EventValue): void {
        this.next(() => of(eventValue));
    }
}
