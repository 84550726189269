// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-item {
  color: var(--ion-color-primary);
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-popover-tab/curafida-popover-tab.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,iBAAA;AACJ","sourcesContent":[".selected-item {\n    color: var(--ion-color-primary);\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
