export const UPLOAD_MIME_TYPE_WHITELIST: string[] = [
    'application/pdf',

    // Text based files
    'text/plain',
    'text/csv',
    'text/markdown',
    'application/json',
    'text/calendar',
    'text/xml',

    // Custom MIME-Types we use in our modules
    'application/vnd.twintrac-ecg-3chan-raw+octet-stream',
    'application/vnd.multi-ecg-12chan-raw+octet-stream',
    'application/vnd.my-medax-questionnaire-template+json',
    'application/dicom',

    /*
     * Image formats
     */
    // Image formats also supported by the major web browsers.
    // See https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
    // 'image/apng',
    // 'image/avif', atm (2021-11-23) avif produces errors with sharp@0.29.3
    'image/gif',
    'image/jpeg',
    'image/png',
    // 'image/svg+xml', xml-file-upload is not allowed at this moment to prevent XXE when syncing files to foreign systems.
    'image/webp',
    // Legacy and not reccomended image formats:
    // 'image/bmp',
    'image/tiff',
    // 'image/x-icon',

    /*
     * Audio formats
     */

    // Audio formats also supported by the major web browsers:
    'audio/wav',
    'audio/mpeg',
    'audio/mp4',
    'audio/aac',
    'audio/aacp',
    'audio/ogg',
    'audio/webm',
    'audio/flac',

    /*
     * Video formats
     */
    'video/webm',
    'video/mpeg',
    'video/mp4',
    'video/ogg',
    'video/3gpp',
    'video/3gpp2',
    'video/av1',
    'video/h264',
    'video/h265',
    // Legacy and not reccomended video formats:
    'video/x-msvideo',
    'video/quicktime',
];
