// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-button {
  width: 105px;
  height: 105px;
  border-radius: 10px;
  border: 3px solid white;
  box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, 0.2509803922);
}

.app-button-box {
  width: 33%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-screen-app-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-width: 345px;
}

.app-button-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.app-icon-container-center {
  margin-bottom: 5px;
  padding-top: 5px;
}

.app-button-text {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-app-list/curafida-app-list.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qDAAA;AACJ;;AAEA;EACI,UAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,2BAAA;EACA,wBAAA;AACJ","sourcesContent":[".app-button {\n    width: 105px;\n    height: 105px;\n    border-radius: 10px;\n    border: 3px solid #ffff;\n    box-shadow: 3px 4px 5px 0 #00000040;\n}\n\n.app-button-box {\n    width: 33%;\n    aspect-ratio: 1/1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.home-screen-app-container {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    min-width: 345px;\n}\n\n.app-button-content {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n}\n\n.app-icon-container-center {\n    margin-bottom: 5px;\n    padding-top: 5px;\n}\n\n.app-button-text {\n    margin-bottom: 0 !important;\n    margin-top: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
