import { LogbookEntry } from '../../logbook/entities/logbook-entry';
import { FormGroup } from '@angular/forms';

export enum GroupCategory {
    TENANT = 'TENANT',
    ORGANIZATION = 'ORGANIZATION',
    CENTRAL_FACILITY = 'CENTRAL_FACILITY',
    COOPERATION_FACILITY = 'COOPERATION_FACILITY',
    NO_CATEGORY = 'NO_CATEGORY',
}

export enum GroupLevel {
    TENANT = 'TENANT',
    ORGANIZATION = 'ORGANIZATION',
    INSTITUTION = 'INSTITUTION',
}

export class CreateGroupDto {
    name: string;
    // Parent is uuid from parent group
    parent: string;
    shortName?: string;
    category: GroupCategory;

    static fromFormGroup(formGroup: FormGroup) {
        const createGroupDto = new CreateGroupDto();
        createGroupDto.name = formGroup.value.name;
        createGroupDto.parent = formGroup.controls.parent.value;
        createGroupDto.category =
            formGroup.value.category !== GroupCategory.NO_CATEGORY ? formGroup.value.category : null;
        return createGroupDto;
    }
}

export class Group extends CreateGroupDto {
    uuid: string;
    path: string;
    subGroups?: Group[];
    events?: LogbookEntry[];
}

export class UpdateGroupDto {
    uuid: string;
    name?: string;
    // Parent is uuid from parent group
    parent?: string;
    shortName?: string;
    category?: GroupCategory;
}

export class User_Group {
    uuid: string;
    username: string;
    group_uuid: string;
}
