export enum MeasurementMarker {
    OPEN = 'OPEN',
    TRACKING = 'TRACKING',
    AUDITED = 'AUDITED',
}

export enum NormRange {
    NORM = 'NORM',
    WARN = 'WARN',
    ALARM = 'ALARM',
}
