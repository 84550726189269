import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CurafidaChoiceInputChoice } from './curafida-choice-input.component';
import { ErrorMessageType } from '../../../entities/error-const';

export const curafidaChoiceRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value: CurafidaChoiceInputChoice[] = control.value;
    const checkedInput = value?.find((choiceInput) => choiceInput.checked);
    return !checkedInput
        ? {
              required: true,
              errorType: ErrorMessageType.required,
              formType: 'choiceInput',
          }
        : null;
};
