import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { IconType } from '../../../common/entities/icon_type';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

export interface IconAdapterElement {
    type: IconType;
    name: string;
    colorType?: string;
    color: string;
    source?: string;
}

@Component({
    template: `
        <div *ngIf="isNotEmpty">
            <ng-container *ngIf="isSingleMode; else multipleMode">
                <ion-icon
                    *ngIf="!data?.iconType || data?.iconType === IconType.ION_ICON"
                    name="{{ data[itemSetting.prop] }}"
                    color="{{ itemSetting.color }}"
                    class="icon-col"></ion-icon>
                <ion-icon
                    *ngIf="data?.iconType === IconType.SRC_ICON"
                    src="{{ data[itemSetting.prop] }}"
                    color="{{ itemSetting.color }}"
                    class="icon-col"></ion-icon>
            </ng-container>
            <ng-template #multipleMode>
                <div style="display: flex; flex-flow: wrap; margin-top: 2px">
                    <ng-container *ngFor="let icon of data[itemSetting.prop]">
                        <ng-container *ngIf="icon.colorType === 'css'; else ionColor">
                            <ion-icon
                                *ngIf="!icon.type || icon.type === IconType.ION_ICON"
                                name="{{ icon.name }}"
                                [style.color]="icon.color"
                                class="icon-multiple-col"></ion-icon>
                            <ion-icon
                                *ngIf="icon.type === IconType.SRC_ICON"
                                src="{{ icon.name }}"
                                [style.color]="icon.color"
                                class="icon-multiple-col"></ion-icon>
                        </ng-container>
                        <ng-template #ionColor>
                            <ion-icon
                                *ngIf="!icon.type || icon.type === IconType.ION_ICON"
                                name="{{ icon.name }}"
                                color="{{ icon.color }}"
                                class="icon-multiple-col"></ion-icon>
                            <ion-icon
                                *ngIf="icon.type === IconType.SRC_ICON"
                                src="{{ icon.name }}"
                                color="{{ icon.color }}"
                                class="icon-multiple-col"></ion-icon>
                        </ng-template>
                    </ng-container>
                </div>
            </ng-template>
        </div>
    `,
    styles: ['.icon-col { text-align: center; width: 100% }', '.icon-multiple-col { flex: 0 0 auto}'],
    standalone: true,
    imports: [NgIf, NgFor, IonicModule],
})
export class IconAdapterComponent implements ItemAdapterComponent {
    @Input() data: any;
    @Input() itemSetting: TableItem;
    isMobile: boolean;
    name = 'IconAdapterComponent';

    IconType = IconType;

    constructor(private platform: Platform) {
        if (platform.is('mobile')) this.isMobile = true;
    }

    get isSingleMode(): boolean {
        return typeof this.data[this.itemSetting.prop] === 'string';
    }

    get isNotEmpty(): boolean {
        return this.data[this.itemSetting.prop] !== undefined;
    }
}
