import { ErrorHandler, Injectable } from '@angular/core';
import { Logger, LoggingService } from '../../logging/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    protected readonly log: Logger;
    constructor(private loggingService: LoggingService) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }
    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        this.log.error(error);
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }
}
