import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CurafidaAuthService } from '../auth/services';
import { UserRoles } from '../auth/entities/user';
import { Logger, LoggingService } from '../logging/logging.service';
import { ConfigService } from '../config/services';

@Injectable({
    providedIn: 'root',
})
export class RootGuardService {
    protected readonly log: Logger;

    constructor(
        private authService: CurafidaAuthService,
        private router: Router,
        protected configService: ConfigService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async canActivate(): Promise<boolean | UrlTree> {
        const publicRootUrl = this.configService.config.rootUrls.find((x) => x.role === null);
        if (publicRootUrl) {
            // If there is a root url for users without a role (not authenticated users)
            // and the root url is a public url just redirect and do ot authenticate.
            return this.router.parseUrl(publicRootUrl.url.replace('public/', ''));
        }
        const isAuthenticated = await this.authService.isAuthenticated(false, false);
        this.log.debug('isAuthenticated', isAuthenticated);
        const roles = (await this.authService.getRoles()) as string[];
        if (roles && roles.length > 0) {
            let root: {
                url: string;
                role: UserRoles | string;
            };
            const rootLocal = this.configService.config.rootUrls;
            for (const roleMapped of roles) {
                root = rootLocal.find((page) => page.role === roleMapped);
                if (root && root.url) {
                    this.log.debug(`[canActivate] Navigate to user root URL based role (${roleMapped}): ${root.url}`);
                    return this.router.parseUrl(root.url);
                }
            }
            if (!root) {
                this.log.warn(
                    `[canActivate] No root URL specified for user role. Navigate to the App default URL ${this.configService.config.defaultUrl}`,
                );
                return this.router.parseUrl(this.configService.config.defaultUrl);
            }
        }
        this.log.info(`[canActivate] Default action: navigate to /login`);
        return this.router.parseUrl('/login');
    }
}
