import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

export interface SelectableItem {
    label: string;
    name: string;
    selected: boolean;
    description?: string;
    disabled?: boolean;
}

export class CheckboxListConfig {
    availableItemList: SelectableItem[];
    splitListInColumns?: boolean;
    listHeader?: string;
    emptyListText?: string;
    listUiColumns?: any[];
    selectedItemList?: any[];
}

@Component({
    selector: 'lib-checkbox-list-modal',
    templateUrl: './checkbox-list-modal.component.html',
    styleUrls: ['./checkbox-list-modal.component.scss'],
})
export class CheckboxListModalComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    titleIcon: string;
    @Input()
    modalBodyText: string;
    @Input()
    listConfigs: CheckboxListConfig[];
    @Input()
    confirmButtonText: string;

    constructor(
        private params: NavParams,
        private modalCtrl: ModalController,
    ) {}

    async ngOnInit() {
        for (const list of this.listConfigs) {
            list.selectedItemList = [];
            for (const item of list.availableItemList) {
                if (item.selected) list.selectedItemList.push(item.name);
            }
            if (list.splitListInColumns) list.listUiColumns = this.splitListInTwoColumns(list.availableItemList);
        }
    }

    splitListInTwoColumns(list: any) {
        const firstColumn = [];
        const secondColumn = [];
        const columnLength = Math.round(list.length / 2);
        for (let i = 0; i < columnLength; i++) {
            firstColumn.push(list[i]);
        }
        for (let i = columnLength; i < list.length; i++) {
            secondColumn.push(list[i]);
        }
        return [firstColumn, secondColumn];
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    onActionOnItem(clickedItem: SelectableItem, listConfigIndex: number) {
        clickedItem.selected = !clickedItem.selected;
        if (clickedItem.selected) {
            this.listConfigs[listConfigIndex].selectedItemList.push(clickedItem.name);
        } else {
            this.listConfigs[listConfigIndex].selectedItemList = this.listConfigs[
                listConfigIndex
            ].selectedItemList.filter((item) => item !== clickedItem.name);
        }
    }

    async returnSelectedData() {
        const response = [];
        for (const list of this.listConfigs) {
            response.push(list.selectedItemList);
        }
        await this.modalCtrl.dismiss({ value: response, action: 'right' });
    }

    fetchLineHeight(availableItemList: SelectableItem[]) {
        return Math.round(availableItemList.length / 2);
    }
}
