import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import 'hammerjs';
import { initEnvironment } from '../../../libs/config/init';
import { environment } from './environments/environment';

(async () => {
    await initEnvironment(environment);
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
})();
