// noinspection ES6UnusedImports

import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class SplashScreenStateService {
    $splashScreenActivationState: Subject<boolean> = new Subject();
    stop() {
        this.$splashScreenActivationState.next(false);
    }
    start() {
        this.$splashScreenActivationState.next(true);
    }
}
