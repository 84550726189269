export enum TherapyState {
    RUNNING = 'RUNNING',
    DONE = 'DONE',
    DELETE = 'DELETE',
    ACTIVE = 'ACTIVE',
    PLANNED = 'PLANNED',
    NO_SHOW = 'NO_SHOW',
    LOCKED = 'LOCKED',
    FINISHED = 'FINISHED',
}
