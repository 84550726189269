import { NgModule } from '@angular/core';
import { TOAST_SERVICE_INTERFACE } from '../kc-auth/services/toast-service.interface';
import { ToastService } from '../common/services/toast-service/toast-service.service';
import { ApiService } from '../api';
import { CurafidaSessionFactory } from './services';
import { LoggingService } from '../logging/logging.service';

@NgModule({
    providers: [
        { provide: TOAST_SERVICE_INTERFACE, useExisting: ToastService },
        { provide: 'BeforeSessionChangeListener', useExisting: ApiService },
        { provide: 'SESSION_FACTORY_INTERFACE', useClass: CurafidaSessionFactory },
        { provide: 'LOGGER_FACTORY', useClass: LoggingService },
    ],
})
export class AuthModule {}
