// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-tutorial {
  border: 2px solid var(--ion-color-primary);
  border-radius: 2%;
  margin: var(--ion-padding, 1%);
  height: 95%;
}

.title-top-rectangle-measurement {
  font-weight: bold;
  --margin-start: 0;
  --margin-end: 0;
  --margin-bottom: 0;
  --margin-top: calc(var(--ion-margin, 16px) / 2);
  margin-top: calc(var(--ion-margin, 16px) / 2);
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./../../libs/measurement/components/tutorial-slider/tutorial-slider.component.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,iBAAA;EACA,8BAAA;EACA,WAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,+CAAA;EACA,6CAAA;EACA,gBAAA;AACJ","sourcesContent":[".border-tutorial {\n    border: 2px solid var(--ion-color-primary);\n    border-radius: 2%;\n    margin: var(--ion-padding, 1%);\n    height: 95%;\n}\n\n.title-top-rectangle-measurement {\n    font-weight: bold;\n    --margin-start: 0;\n    --margin-end: 0;\n    --margin-bottom: 0;\n    --margin-top: calc(var(--ion-margin, 16px) / 2);\n    margin-top: calc(var(--ion-margin, 16px) / 2);\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
