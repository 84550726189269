export class TutorialSlide {
    title: string;
    picture?: string;
    video?: string;
    component?: ComponentType;
    description: string | string[];
}

export class TutorialDefinition {
    title: string;
    list: TutorialSlide[];
    hasBorder = true;
}

export enum ComponentType {
    COSINUSS,
    TWINTRAC,
}
