import { TherapyGoal } from '../therapy-goal/therapy-goal';
import { ExerciseType } from '../exerciseSession';

export class TherapyTemplateDto {
    title: string;
    description: string;
    duration?: string;
    therapyGoal?: TherapyGoal;
    exerciseType?: ExerciseType;
    therapySessionsPerWeek?: number = 0;
    notes?: string;
    metaInfo?: object;
    disabled?: boolean = false;
    autoLock?: boolean = true;
    trainingRestDays?: number = 0;
    startOffset?: string = 'P1D';
}
