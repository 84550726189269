import { Component, OnInit } from '@angular/core';
import { IonicModule, PopoverController } from '@ionic/angular';
import { ActionItemType, ActionMenuItem } from '../../entities/action-menu.item';
import { ActionType } from '../../entities/table';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'lib-curafida-popover-selection',
    templateUrl: './curafida-popover-selection.component.html',
    styleUrls: ['./curafida-popover-selection.component.scss'],
    standalone: true,
    imports: [IonicModule, NgForOf, NgIf, TranslateModule],
})
export class CurafidaPopoverSelectionComponent implements OnInit {
    ActionMenuItemType = ActionItemType;
    actionItems: ActionMenuItem[] = [];

    constructor(private popoverController: PopoverController) {}

    ngOnInit(): void {
        this.actionItems = this.actionItems.map((x) => {
            // @ts-ignore
            if (x?.action === ActionType.DELETE || x?.action === ActionType.REMOVE) {
                // @ts-ignore
                x.color = IonicColor.danger;
            }
            return x;
        });
    }

    async sendAction(value: any) {
        await this.popoverController.dismiss(value);
    }

    isType(item: ActionMenuItem, type: ActionItemType) {
        return type === item.type;
    }
}
