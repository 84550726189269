export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export interface HypermediaLink {
    href: string;
    methods: HttpMethod[];
}

export type HypermediaLinks = Record<string, HypermediaLink>;

export interface HypermediaResource {
    _links: HypermediaLinks;
}

export type authorizationAction = 'read' | 'write';
