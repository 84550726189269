// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-button {
  padding: 0;
  cursor: pointer;
}

.size-switch-button {
  text-align: center;
  font-size: x-large;
  height: 2rem;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.switch-container {
  background: var(--ion-color-light);
  margin-bottom: 5px;
  padding-bottom: 7px;
  padding-top: 7px;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/switch/switch.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kCAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACJ","sourcesContent":[".switch-button {\n    padding: 0;\n    cursor: pointer;\n}\n\n.size-switch-button {\n    text-align: center;\n    font-size: x-large;\n    height: 2rem;\n}\n\nh4 {\n    margin-top: 0;\n    margin-bottom: 0;\n}\n\nh5 {\n    margin-top: 0;\n    margin-bottom: 0;\n}\n\n.switch-container {\n    background: var(--ion-color-light);\n    margin-bottom: 5px;\n    padding-bottom: 7px;\n    padding-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
