// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  font-size: 14px !important;
}

.segment-xtra-wide {
  width: 85%;
}

ion-button {
  height: 32px;
  font-size: 14px;
}

.ion-margin-end-segment {
  --margin-end: var(--ion-margin, 1px);
  margin-right: var(--ion-margin, 1px);
}

.curafida-search-input {
  display: flex;
  align-items: center;
  border: 2px var(--ion-color-light) solid;
  border-radius: 7px;
  height: 40px;
  --padding-start: 10px;
  box-sizing: border-box;
  padding-left: 4px;
}

.curafida-search-input ion-input {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  line-height: 1;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --padding: 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.curafida-search-input ion-label {
  font-size: 14px !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.mobile {
  width: 50%;
}

.web {
  width: 75%;
}

.extended-search-bar {
  margin-left: 0;
  width: 100%;
}
.extended-search-bar .web {
  width: 100%;
}

.standard-search-bar {
  min-width: 30%;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-search-and-button/curafida-search-and-button.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,oCAAA;EACA,oCAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;EACA,SAAA;EACA,UAAA;EACA,0BAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;AACJ;AAAI;EACI,WAAA;AAER;;AAEA;EACI,cAAA;AACJ","sourcesContent":["ion-label {\n    font-size: 14px !important;\n}\n\n.segment-xtra-wide {\n    width: 85%;\n}\n\nion-button {\n    height: 32px;\n    font-size: 14px;\n}\n\n.ion-margin-end-segment {\n    --margin-end: var(--ion-margin, 1px);\n    margin-right: var(--ion-margin, 1px);\n}\n\n.curafida-search-input {\n    display: flex;\n    align-items: center;\n    border: 2px var(--ion-color-light) solid;\n    border-radius: 7px;\n    height: 40px;\n    --padding-start: 10px;\n    box-sizing: border-box;\n    padding-left: 4px;\n}\n\n.curafida-search-input ion-input {\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    font-size: 14px !important;\n    line-height: 1;\n    --padding-top: 0;\n    --padding-bottom: 0;\n    --padding-start: 0;\n    --padding-end: 0;\n    --padding: 0;\n    height: 100%;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n}\n\n.curafida-search-input ion-label {\n    font-size: 14px !important;\n    margin-right: 10px;\n    display: flex;\n    align-items: center;\n}\n\n.mobile {\n    width: 50%;\n}\n\n.web {\n    width: 75%;\n}\n\n.extended-search-bar {\n    margin-left: 0;\n    width: 100%;\n    .web {\n        width: 100%;\n    }\n}\n\n.standard-search-bar {\n    min-width: 30%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
