import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { CosinussMeasurementType, PulseOximeterMeasurementType } from './MeasurementContent';
import { MeasurementType } from './measurement-type';
import { ButtonMeasurementInfo } from '../html-models/button-measurement-info';

export enum ConnectionType {
    BLUETOOTH_CLASSIC = 'BLUETOOTH_CLASSIC',
    BLE = 'BLE',
    NONE = 'NONE',
    USB = 'USB',
    WLAN = 'WLAN',
}

export enum DeviceKey {
    TwinTrac = 'TwinTrac',
    Cosinuss = 'Cosinuss',
}

export enum DeviceNameInfix {
    TwinTrac = 'TwinT',
    EKG_12 = 'SRM M-ECG',
    Cosinuss = 'Cosinuss',
    PO60 = 'PO60',
    BM85 = 'BM85',
    otoscopeFirefly = 'FF_Otoscope',
    stethoscopeEkuore = 'ekuorePro',
}
export enum DeviceModel {
    TwinTrac = 'TwinTrac',
    Cosinuss = 'Cosinuss',
    PO60 = 'PO60',
    BM85 = 'BM85',
    Stethoscope = 'Ekuore Pro',
    Otoscope = 'Firefly DE570',
    EKG_12 = 'SRM Multi-ECG',
}

export enum Vendor {
    SRM = 'SRM',
    COSINUSS = 'Cosinuss',
    BEURER = 'Beurer',
    EKUORE = 'Ekuore',
    FIREFLY = 'Firefly',
}

@Entity('DeviceClass')
export class DeviceClass {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column()
    name: string;

    @Column()
    vendor: string;

    @Column()
    model: string | DeviceModel;

    @Column()
    connectionType: ConnectionType;

    @Column()
    measurementType: MeasurementType | CosinussMeasurementType | PulseOximeterMeasurementType;

    @Column()
    macAddress: string; // Same as Bluetooth-Address

    isPaired() {
        return !!this.uuid;
    }
}

@Entity('MeasurementDevice')
export class MeasurementDevice {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column({ type: 'simple-json', nullable: true })
    deviceClass: DeviceClass;

    @Column()
    macAddress: string;
}

export abstract class DeviceConnectionInfo {
    deviceNameInfix: DeviceNameInfix;
    deviceClass: DeviceClass;
    protected button: ButtonMeasurementInfo<DeviceAction>;

    abstract createButton(): ButtonMeasurementInfo;

    getStateText() {
        return this.deviceClass?.isPaired() ? `${this.deviceClass.name} gekoppelt` : `nicht eingerichtet`;
    }

    updateButton() {
        if (this.button) {
            this.button.buttonSubtitle = this.getStateText();
        }
    }

    // abstract addStoredDeviceClass()

    protected buttonClickedHandler() {
        // TODO: Action disconnect not yet implemented
        if (!this.deviceClass) {
            throw Error('Device is not defined');
        }
        if (!this.deviceClass.isPaired()) {
            const discoverAction = new DiscoverAction();
            discoverAction.deviceConnectionInfo = this;
            this.button.actions.emit(discoverAction);
        } else {
            const discoverAction = new ShowInfoAction();
            discoverAction.deviceConnectionInfo = this;
            this.button.actions.emit(discoverAction);
        }
    }
}

export class Ecg12ConnectionInfo extends DeviceConnectionInfo {
    constructor() {
        super();
        this.deviceClass = new DeviceClass();
        this.deviceClass.model = DeviceModel.EKG_12;
        this.deviceClass.connectionType = ConnectionType.BLUETOOTH_CLASSIC;
        this.deviceClass.vendor = Vendor.SRM;
        this.deviceClass.measurementType = MeasurementType.ECG;
        this.deviceNameInfix = DeviceNameInfix.EKG_12;
    }

    createButton(): ButtonMeasurementInfo {
        const buttonMeasurementInfo = new ButtonMeasurementInfo();
        buttonMeasurementInfo.buttonTitle = 'EKG (12-Kanal)';
        buttonMeasurementInfo.buttonSubtitle = this.getStateText();
        buttonMeasurementInfo.svgName = 'assets/icon/devices/b_EKG.svg';
        this.button = buttonMeasurementInfo;
        this.button.uriRedirection = 'member/measurements/ecg/';
        this.button.clicked.subscribe(() => this.buttonClickedHandler());
        return buttonMeasurementInfo;
    }
}

export enum DeviceActionType {
    DISCOVER = 'DISCOVER',
    DISCONNECT = 'DISCONNECT',
    SHOW_INFO = 'SHOW_INFO',
}

export class DeviceAction {
    type: DeviceActionType;
    deviceConnectionInfo: DeviceConnectionInfo;
}

export class DiscoverAction extends DeviceAction {
    readonly type = DeviceActionType.DISCOVER;
}

export class DisconnectAction extends DeviceAction {
    readonly type = DeviceActionType.DISCONNECT;
}
export class ShowInfoAction extends DeviceAction {
    readonly type = DeviceActionType.SHOW_INFO;
}

export class StethoscopeConnectionInfo extends DeviceConnectionInfo {
    constructor() {
        super();
        this.deviceClass = new DeviceClass();
        this.deviceClass.model = DeviceModel.Stethoscope;
        this.deviceClass.connectionType = ConnectionType.WLAN;
        this.deviceClass.vendor = Vendor.EKUORE;
        this.deviceClass.measurementType = MeasurementType.STETHOSCOPE;
        this.deviceNameInfix = DeviceNameInfix.stethoscopeEkuore;
    }

    createButton(): ButtonMeasurementInfo {
        const buttonMeasurementInfo = new ButtonMeasurementInfo<DeviceAction>();
        buttonMeasurementInfo.buttonTitle = 'Stethoskop';
        buttonMeasurementInfo.buttonSubtitle = this.getStateText();
        buttonMeasurementInfo.svgName = 'assets/icon/devices/b_Stethoskop.svg';
        this.button = buttonMeasurementInfo;
        this.button.uriRedirection = 'member/measurements/stethoscope/';
        this.button.clicked.subscribe(() => this.buttonClickedHandler());
        return buttonMeasurementInfo;
    }
}

export class OtoscopeConnectionInfo extends DeviceConnectionInfo {
    constructor() {
        super();
        this.deviceClass = new DeviceClass();
        this.deviceClass.model = DeviceModel.Otoscope;
        this.deviceClass.connectionType = ConnectionType.WLAN;
        this.deviceClass.vendor = Vendor.FIREFLY;
        this.deviceClass.measurementType = MeasurementType.OTOSCOPE;
        this.deviceNameInfix = DeviceNameInfix.otoscopeFirefly;
    }

    createButton(): ButtonMeasurementInfo {
        const buttonMeasurementInfo = new ButtonMeasurementInfo<DeviceAction>();
        buttonMeasurementInfo.buttonTitle = 'Otoskop';
        buttonMeasurementInfo.buttonSubtitle = this.getStateText();
        buttonMeasurementInfo.svgName = 'assets/icon/devices/b-w_Otoskop.svg';
        this.button = buttonMeasurementInfo;
        this.button.uriRedirection = 'member/measurements/otoscope/';
        this.button.clicked.subscribe(() => this.buttonClickedHandler());
        return buttonMeasurementInfo;
    }
}

export const DeviceModelMIA = [
    new Ecg12ConnectionInfo(),
    new StethoscopeConnectionInfo(),
    new OtoscopeConnectionInfo(),
    // Stethoscope = 'Ekuore Pro',
    // Otoscope = 'Firefly DE570',
    // EKG_12 = 'SRM Multi-ECG',
];
