import { ElementRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import * as Color from 'color';
import { CurafidaFrontendConfiguration } from '../../entities/curafida-frontend-configuration';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private platform: Platform,
        private storage: Storage,
    ) {
        storage.create().then((s) => {
            s.get('theme').then((cssText) => {
                // <--- GET SAVED THEME
                this.setGlobalCSS(cssText);
            });
        });
    }

    static async getTopHeight(header: ElementRef, elementRef: ElementRef, platform?: Platform): Promise<void> {
        const contentHeight = Number(header.nativeElement.clientHeight + header.nativeElement.offsetTop) + 'px';
        if (header.nativeElement.clientHeight !== 0) {
            elementRef.nativeElement.style.setProperty('--roulet-height', contentHeight);
        }
    }

    setColorFromEnvironment(environment: CurafidaFrontendConfiguration): void {
        if (environment?.theme?.colors) {
            if (environment.theme?.colors?.primary !== '' && environment.theme?.colors?.primaryContrast !== '') {
                this.setColorTemplate(
                    'primary',
                    environment.theme.colors['primary'],
                    environment.theme.colors['primaryContrast'],
                );
                this.setColorInverseTemplate(
                    'primary',
                    environment.theme.colors['primary'],
                    environment.theme.colors['primaryContrast'],
                );
            } else {
                this.setColorTemplate('primary', '#0096C8', '#ffffff');
                this.setColorInverseTemplate('primary', '#ffffff', '#0096C8');
            }
            if (environment.theme.colors.secondary !== '' && environment.theme.colors.secondaryContrast !== '') {
                this.setColorTemplate(
                    'secondary',
                    environment.theme.colors['secondary'],
                    environment.theme.colors['secondaryContrast'],
                );
                this.setColorInverseTemplate(
                    'secondary',
                    environment.theme.colors['secondary'],
                    environment.theme.colors['secondaryContrast'],
                );
            } else {
                this.setColorTemplate('secondary', '#07357E', '#ffffff');
                this.setColorInverseTemplate('secondaryContrast', '#ffffff', '#07357E');
            }
            if (environment.theme.colors.tertiary !== '' && environment.theme.colors.tertiaryContrast !== '') {
                this.setColorTemplate(
                    'tertiary',
                    environment.theme.colors['tertiary'],
                    environment.theme.colors['tertiaryContrast'],
                );
                this.setColorInverseTemplate(
                    'tertiary',
                    environment.theme.colors['tertiary'],
                    environment.theme.colors['tertiaryContrast'],
                );
            } else {
                this.setColorTemplate('tertiary', '#EFCE4A', '#ffffff');
                this.setColorInverseTemplate('tertiaryContrast', '#ffffff', '#EFCE4A');
            }
        }
    }

    hexToRgbSVG(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
    }

    async setColorTemplate(type: string, color: string, colorContrast: string): Promise<void> {
        const shadeRatio = 0.2;
        const tintRatio = 0.1;
        document.body.style.setProperty(`--ion-color-${type}`, color);
        document.body.style.setProperty(`--ion-color-${type}-rgb`, this.hexToRgb(color));
        document.body.style.setProperty(`--ion-color-${type}-contrast`, colorContrast);
        document.body.style.setProperty(`--ion-color-${type}-contrast-rgb`, this.hexToRgb(colorContrast));
        document.body.style.setProperty(`--ion-color-${type}-shade`, Color(color).darken(shadeRatio));
        document.body.style.setProperty(`--ion-color-${type}-tint`, Color(color).darken(tintRatio));
    }

    setColorInverseTemplate(type: string, color: string, colorContrast: string): void {
        const shadeRatio = 0.2;
        const tintRatio = 0.1;
        document.body.style.setProperty(`--ion-color-${type + '-inverse'}`, colorContrast);
        document.body.style.setProperty(`--ion-color-${type + '-inverse'}-rgb`, this.hexToRgb(colorContrast));
        document.body.style.setProperty(`--ion-color-${type + '-inverse'}-contrast`, color);
        document.body.style.setProperty(`--ion-color-${type + '-inverse'}-contrast-rgb`, this.hexToRgb(color));
        document.body.style.setProperty(
            `--ion-color-${type + '-inverse'}-shade`,
            Color(colorContrast).darken(shadeRatio),
        );
        document.body.style.setProperty(
            `--ion-color-${type + '-inverse'}-tint`,
            Color(colorContrast).darken(tintRatio),
        );
    }

    hexToRgb(hex: string): string {
        const result = /^#?([a-fd]{2})([a-fd]{2})([a-fd]{2})$/i.exec(hex);
        if (result) {
            const r = parseInt(result[1], 16);
            const g = parseInt(result[2], 16);
            const b = parseInt(result[3], 16);
            return `${r},${g},${b}`;
        }
        return null;
    }

    CSSTextGenerator(size: number): string {
        return `
    --curafida-text-size: ${size}%;
    --ion-font-size: ${size}%;
    `;
    }

    async setTextSize(value: number): Promise<void> {
        this.setGlobalCSS(this.CSSTextGenerator(value));
        await this.storage.set('theme', this.CSSTextGenerator(value)); // <--- SAVE THEME HERE
        // await this.storage.set('text-size', value); // <--- SAVE TEXT SIZE HERE
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

    changeCurafidaWrapperWidth(width: string) {
        document.body.style.setProperty(`--curafida-wrapper-width:`, width);
        document.body.style.setProperty(`--curafida-login-wrapper-width:`, width);
    }
}
