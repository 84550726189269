// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-splash-screen {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
}

.loading-text {
  position: relative;
  color: rgb(0, 0, 0);
  font-size: 5em;
}

.loading-text:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 7em;
  white-space: nowrap;
  color: #fff;
  animation: loading 8s linear;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/splash/splash.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,UAAA;AACJ;;AACA;EACI,kBAAA;EACA,mBAAA;EACA,cAAA;AAEJ;;AAAA;EACI,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,WAAA;EACA,4BAAA;AAGJ;;AADA;EACI;IACI,YAAA;EAIN;AACF","sourcesContent":[".app-splash-screen {\n    background: #fff;\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n    opacity: 1;\n}\n.loading-text {\n    position: relative;\n    color: rgb(0, 0, 0);\n    font-size: 5em;\n}\n.loading-text:before {\n    content: attr(data-text);\n    position: absolute;\n    overflow: hidden;\n    max-width: 7em;\n    white-space: nowrap;\n    color: #fff;\n    animation: loading 8s linear;\n}\n@keyframes loading {\n    0% {\n        max-width: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
