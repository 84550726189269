import { TokenSet } from './token-set';

export class Session {
    /**
     * A successful authentication results in a TokenSet containing JSON Web Tokens.
     * There is at least an access_token. But mostly also a refresh_token.
     * Sometimes also an id_token.
     */
    tokenSet: TokenSet = {
        access_token: null,
    };
}
