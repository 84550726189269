import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false,
})
export class SortPipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
        if (!Array.isArray(array)) {
            return array;
        }
        array.sort((a, b) => {
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        });
        return array;
    }
}

@Pipe({
    name: 'contains',
    pure: true,
})
export class ContainsPipe implements PipeTransform {
    transform<T>(array: T[], item: T): boolean {
        return Boolean(array?.includes(item));
    }
}

@Pipe({
    name: 'containsNot',
    pure: true,
    standalone: true,
})
export class ContainsNotPipe implements PipeTransform {
    transform<T>(array: T[], item: T): boolean {
        return !array?.includes(item);
    }
}
