// noinspection ES6UnusedImports

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActionEmitter, ActionType, TableConfig } from '../../../../table/entities/table';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { UserListModalComponent } from '../../user-list-modal/user-list-modal.component';
import { ConfigService } from '../../../../config/services';
import { UsersService } from '../../../services/user';
import { ModalAlertService } from '../../../../common/services/modal';
import { ModalController } from '@ionic/angular';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { careManagementItemSettingsConfig, tableItemsConfig } from '../caregiver-supervisor-table.config';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HypermediaResource } from '../../../../hateoas/hateoas.model';
import { ICardActionButton } from '../../../../common/entities/card-action-button.interface';
import { CareRelationshipUser, PatientCareRelationshipResource } from '../patient-care-relationship.resource';
import { CurafidaCardComponent } from '../../../../common/components/curafida-card/curafida-card.component';
import { CurafidaAuthService } from '../../../../auth/services';
import { Router } from '@angular/router';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { PatientDossierService } from '../../../services/patient-dossier.service';
import { LoadingProcess } from '../../../../table/components/curafida-table/loading.process';

@Component({
    selector: 'curafida-patient-care-management',
    templateUrl: './patient-care-management.component.html',
    styleUrls: ['./patient-care-management.component.scss'],
})
export class PatientCareManagementComponent implements OnInit {
    @ViewChild('curafidaCardComponent') curafidaCardComponent: CurafidaCardComponent;
    readonly log: Logger;
    readonly listConfig = new TableConfig<CareRelationshipUser[]>();
    @Input()
    isMobile: boolean;
    @Input()
    patient: User;
    @Input()
    managedUserRole: UserRoles.CAREGIVER | UserRoles.SUPERVISOR;
    actionButtons: ICardActionButton[];
    patientCareRelationships: PatientCareRelationshipResource[];

    isLoading = LoadingProcess.initLoadingProcess();

    constructor(
        readonly configService: ConfigService,
        readonly usersService: UsersService,
        readonly modalAlertService: ModalAlertService,
        readonly modalCtrl: ModalController,
        readonly toastService: ToastService,
        private readonly patientDossierService: PatientDossierService,
        private readonly authService: CurafidaAuthService,
        private readonly router: Router,
        loggingService: LoggingService,
    ) {
        this.log = loggingService.getLogger(this.constructor.name);
    }

    private _patientDossier: HypermediaResource;

    get patientDossier(): HypermediaResource {
        return this._patientDossier;
    }

    @Input()
    set patientDossier(value: HypermediaResource) {
        this._patientDossier = value;
        this.listConfig.list.items = [];
        this.refreshListConfigMetadata();
        this.isLoading = LoadingProcess.initLoadingProcess();
        this.patientDossierService.getPatientDossier$(this.patientDossier, this.endpoint).subscribe(
            (patientCareRelationships) => {
                this.patientCareRelationships = patientCareRelationships;
                this.listConfig.list.items.push(...this.patientCareRelationships.map((x) => x.careUser));
                this.refreshListConfigMetadata();
                this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
            },
            (message) => {
                this.log.error(message);
                this.toastService.showToast('Fehler beim Abrufen der Patientenbetreuung', IonicColor.danger);
                this.isLoading = LoadingProcess.finishedWithErrorLoadingProcess();
            },
        );
    }

    get authenticatedUser() {
        return this.authService.getSession().user;
    }

    private _isButtonHidden = false;

    get isButtonHidden(): boolean {
        return this._isButtonHidden;
    }

    @Input()
    set isButtonHidden(value: boolean) {
        this._isButtonHidden = value;
        if (this.actionButtons) {
            this.curafidaCardComponent.actionButtons = this.actionButtons;
        }
        if (value) {
            this.isEditEnabled = !value;
        }
        this.initActionButton();
    }

    private _isEditEnabled: boolean;

    get isEditEnabled(): boolean {
        return this._isEditEnabled;
    }

    set isEditEnabled(value: boolean) {
        this._isEditEnabled = value;
        if (this.listConfig.itemSettings) {
            this.listConfig.itemSettings.find((i) => i.id === `${this.managedUserRole}_action_delete`).isHidden =
                !value;
        }
    }

    get endpoint(): string {
        return this.managedUserRole === UserRoles.SUPERVISOR ? 'supervisors' : 'caregivers';
    }

    ngOnInit(): void {
        this.initTable();
        this.initActionButton();
    }

    initTable() {
        this.listConfig.emptyListLabel = 'USER.' + this.managedUserRole + '.ANY_ASSIGN';
        this.listConfig.itemSettings = careManagementItemSettingsConfig(this.managedUserRole);
    }

    initActionButton() {
        this.actionButtons = [
            {
                icon: 'add',
                id: 'add-care',
                isDisabled: false,
                isHidden: this.isButtonHidden,
                isIconButton: this.isMobile,
                title: this.managedUserRole === UserRoles.SUPERVISOR ? 'USER.SUPERVISOR.ADD' : 'USER.CAREGIVER.ADD',
            },
        ];
    }

    async setActionOfTable(actionEmitter: ActionEmitter<User>): Promise<void> {
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.deleteRelationship(actionEmitter);
        }
    }

    async deleteRelationship(actionEmitter: ActionEmitter<CareRelationshipUser>): Promise<void> {
        const userName = `${actionEmitter.item.firstname}  ${actionEmitter.item.lastname}`;
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `Zuordnung ${
            this.managedUserRole === UserRoles.SUPERVISOR ? 'Co-Betreuer' : 'Betreuer'
        } entfernen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Durch das Entfernen des ${
            this.managedUserRole === UserRoles.SUPERVISOR ? 'Co-Betreuers' : 'Betreuers'
        } wird die Zuordnung von „${userName}“ zum Patient aufgehoben. Sind Sie sicher, dass Sie fortfahren möchten?`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Entfernen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            const item = this.patientCareRelationships.find((it) => it.username === actionEmitter.item.username);
            this.patientDossierService
                .deletePatientCareRelationships$(item)
                .pipe(
                    tap(() => {
                        const removedItems = this.patientCareRelationships.filter(
                            (relationship) => relationship.uuid === item.uuid,
                        );
                        this.patientCareRelationships = this.patientCareRelationships.filter(
                            (value) => !removedItems.includes(value),
                        );
                        const removedUsernames = removedItems.map((value) => value.username);
                        this.listConfig.list.items = this.listConfig.list.items.filter(
                            (user) => !removedUsernames.includes(user.username),
                        );
                        this.refreshListConfigMetadata();
                    }),
                )
                .subscribe(
                    () => this.toastService.showToast('Die Zuordnung wurde gelöscht', IonicColor.success),
                    (message) => {
                        this.log.error(message);
                        this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                    },
                    () => this.iRemovedMyselfAsPatientSupervisorCheck(),
                );
        }
    }

    async addRelationship(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: UserListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: `${this.managedUserRole === UserRoles.SUPERVISOR ? 'Co-Betreuer' : 'Betreuer'} wählen`,
                selectedUsers: this.patientCareRelationships.map((it) => {
                    const newUser = new User();
                    newUser.username = it.username;
                    return newUser;
                }),
                concernedUser: this.authenticatedUser.roles.includes(UserRoles.SUPERVISOR)
                    ? this.patient.username
                    : null,
                isMultipleChoice: true,
                roles: [this.managedUserRole],
                tableItems: [...tableItemsConfig],
                anyItem: this.managedUserRole === UserRoles.SUPERVISOR ? 'ANY_SUPERVISOR' : 'ANY_CAREGIVER',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            const careUsernames: string[] = data
                .filter((it) => !this.patientCareRelationships.find((item) => item.username === it.username))
                .map((it) => it.username);
            this.patientCareRelationships
                .filter((it) => !data.find((item) => item.username === it.username))
                .map((it) => {
                    const actionEmitter = new ActionEmitter<CareRelationshipUser>();
                    actionEmitter.item = it.careUser;
                    this.deleteRelationship(actionEmitter);
                });

            if (careUsernames.length < 1) {
                return;
            }
            forkJoin([
                ...careUsernames.map((it) =>
                    this.patientDossierService.createPatientCareRelationships$(
                        this.patientDossier,
                        this.endpoint,
                        it,
                        this.patient.username,
                    ),
                ),
            ]).subscribe((result) => {
                this.patientCareRelationships.push(...result);
                this.listConfig.list.items.push(...result.map((item) => item.careUser));
                this.refreshListConfigMetadata();
                this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            });
        }
    }

    private refreshListConfigMetadata(): void {
        this.listConfig.list = PaginatedResponse.init(this.listConfig.list.items);
    }

    private iRemovedMyselfAsPatientSupervisorCheck() {
        if (
            this.managedUserRole === UserRoles.SUPERVISOR &&
            this.authenticatedUser.roles.includes(UserRoles.SUPERVISOR)
        ) {
            if (!this.listConfig.list.items.some((it) => it.username === this.authenticatedUser.username)) {
                this.router.navigate(['..']).catch(console.error);
            }
        }
    }
}
