import { Pipe, PipeTransform } from '@angular/core';
import { FilterTagCategory } from '../../common/entities/filter-tag';
import { UserTagCategory } from '../entities/user-tag/user-tag-category.entity';

@Pipe({
    standalone: true,
    name: 'userTagFilter',
    pure: true,
})
export class UserTagFilterPipe implements PipeTransform {
    transform(value: UserTagCategory): FilterTagCategory {
        return {
            label: value.label,
            uuid: value.uuid,
            tags: value.tags,
        };
    }
}
