import { TableItem } from './item-table';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';

export class TableConfig<T> {
    itemSettings: TableItem[];
    list: PaginatedResponse<T> = new PaginatedResponse<T>();
    isOpenDetailEnable? = false; // show hand on hover and enable open detail action
    isReorderDisable? = true; // disable reorder function
    hideHeader? = false;
    emptyListLabel: string;
}

export class TableUpdateValue {
    offset?: number;
    limit?: number;
    sortBy?: SortBy | string;
    sortOrder?: SortOrder;
    tableColumnId?: string;

    constructor(args: {
        offset?: number;
        limit?: number;
        sortBy?: SortBy | string;
        sortOrder?: SortOrder;
        tableColumnId?: string;
    }) {
        if (this.offset || this.offset === 0) this.offset = args.offset;
        if (this.limit || this.limit === 0) this.limit = args.limit;
        if (this.sortBy) this.sortBy = args.sortBy;
        if (this.sortOrder) this.sortOrder = args.sortOrder;
        if (this.tableColumnId) this.tableColumnId = args.tableColumnId;
    }
}
