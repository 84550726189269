import mergician from 'mergician';
import { Inject, Injectable, Optional } from '@angular/core';
import { AppConfiguration } from '../entities';
import { CurafidaFrontendConfiguration } from '../../common/entities/curafida-frontend-configuration';
import { AppTemplateConfig } from '../entities/app-template-config';
import packageJson from '../../../package.json';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    constructor(@Optional() @Inject('environmentConfig') private environmentConfig?: CurafidaFrontendConfiguration) {}

    private _config: AppConfiguration;

    get config(): AppConfiguration {
        return this._config;
    }

    set config(configBaseClass: AppConfiguration) {
        this._config = configBaseClass;
    }

    set configFromTemplate(configFromProject: AppConfiguration) {
        const mergeOptions = {};
        this._config = mergician(mergeOptions)({}, new AppTemplateConfig(), configFromProject, this.environmentConfig);
        this._config.appVersion = packageJson.version;
    }

    initFromJson() {
        const mergeOptions = {};
        this._config = mergician(mergeOptions)({}, new AppTemplateConfig(), this.environmentConfig);
        this._config.appVersion = packageJson.version;
    }
}
