export enum IconType {
    ION_ICON = 'ion',
    SRC_ICON = 'src',
}

export enum IconColour {
    PRIMARY_CONTRAST = 'primary-contrast',
    PRIMARY = 'primary',
}

export enum IconId {
    ANNOUNCEMENT = 'announcement',
    CALENDAR = 'calendar',
    CALL = 'call',
    CASE_STUDY = 'case-study',
    CHAT = 'chat',
    CLIPBOARD = 'clipboard',
    COURSES = 'courses',
    DOCUMENTS = 'documents',
    EDUCATION = 'education',
    FIGURE = 'figure',
    FILE = 'file',
    ORDER = 'file-tray-icon',
    FOOTPRINTS = 'footprints',
    GROUP = 'group',
    HEARTBEAT = 'heartbeat',
    LIGHTBULB = 'lightbulb',
    MEDICATION = 'medication',
    NOTES = 'notes',
    OVERVIEW = 'overview',
    PATIENTS = 'patients',
    PERSON = 'person',
    PRODUCT = 'product',
    PROFILE = 'profile',
    SETTING = 'setting',
    STAR = 'star',
    TASKS = 'tasks',
    TRAIL = 'trail',
    TRAINING = 'training',
}

export enum IconSizeTyp {
    segment,
    button,
    popover,
}
