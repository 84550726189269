import { Pipe, PipeTransform } from '@angular/core';
import { TableItem } from '../entities/table';

@Pipe({
    name: 'filterButtonMobile',
})
export class FilterButtonMobilePipe implements PipeTransform {
    transform(
        itemSettings: TableItem[],
        offset: number = null,
        limit: number = null,
        includeTitle = true,
        onlyTitle = false,
    ): TableItem[] {
        return itemSettings.filter((item) => item?.isMobileButton);
    }
}
