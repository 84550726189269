// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-square-picture-css .modal-wrapper {
  position: absolute;
  display: block;
  width: 660px !important;
  min-height: 490px !important;
}
.modal-square-picture-css .modal-wrapper .swiper-slide img {
  max-height: 101%;
  max-width: 101%;
}

.active-button {
  font-weight: bold;
  color: var(--ion-color-primary);
}

ion-item {
  --padding-start: 0;
  font-size: 95%;
}

div {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.icon-no-margin {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin: 0 !important;
}

.icon-col {
  height: 1.5rem;
  margin-bottom: 3px;
  margin-top: 3px;
  font-size: 1.5rem;
}

.column-margin {
  padding-right: 8%;
}`, "",{"version":3,"sources":["webpack://./../../libs/table/components/column-definition/curafida-column-definition.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,4BAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;AACR;;AAGA;EACI,iBAAA;EACA,+BAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,cAAA;AAAJ;;AAEA;EACI,qBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".modal-square-picture-css .modal-wrapper {\n    position: absolute;\n    display: block;\n    width: 660px !important;\n    min-height: 490px !important;\n\n    .swiper-slide img {\n        max-height: 101%;\n        max-width: 101%;\n    }\n}\n\n.active-button {\n    font-weight: bold;\n    color: var(--ion-color-primary);\n}\n\nion-item {\n    --padding-start: 0;\n    font-size: 95%;\n}\ndiv {\n    white-space: pre-wrap;\n    word-wrap: break-word;\n}\n\n.icon-no-margin {\n    --margin-start: 0;\n    --margin-end: 0;\n    --margin-top: 0;\n    --margin-bottom: 0;\n    margin: 0 !important;\n}\n\n.icon-col {\n    height: 1.5rem;\n    margin-bottom: 3px;\n    margin-top: 3px;\n    font-size: 1.5rem;\n}\n\n.column-margin {\n    padding-right: 8%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
