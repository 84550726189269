export enum ExerciseSessionState {
    NOT_PLANNED = 'NOT_PLANNED',
    SCHEDULED = 'SCHEDULED',
    PLANNED = 'PLANNED',
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    POSTPONED = 'POSTPONED',
    PATIENT_CANCELLED = 'PATIENT_CANCELLED',
    DELETED = 'DELETED',
    DELAYED = 'DELAYED',
    SHOW = 'SHOW',
    NO_SHOW = 'NO_SHOW',
    REPLACED = 'REPLACED',
    TECH_PROBLEM = 'TECH_PROBLEM',
}

// Correct list from Backend
// TODO: replace with the correct enum for all the frontend code
export enum ExerciseSessionUserState {
    NOT_PLANNED = 'NOT_PLANNED',
    SCHEDULED = 'SCHEDULED',
    PLANNED = 'PLANNED',
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    PATIENT_CANCELLED = 'PATIENT_CANCELLED',
    POSTPONED = 'POSTPONED',
    REPLACED = 'REPLACED',
    SHOW = 'SHOW',
    NO_SHOW = 'NO_SHOW',
    TECH_PROBLEM = 'TECH_PROBLEM',
}
