import { NgModule } from '@angular/core';
import { CommonComponentsModule } from './components/common-components.module';
import { CommonModuleRoutingModule } from './common-module-routing.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AutosizeDirective } from './components/curafida-input/curafida-text-area/autosize-directive';

@NgModule({
    declarations: [AutosizeDirective],
    imports: [CommonModuleRoutingModule, CommonComponentsModule],
    providers: [InAppBrowser],
    exports: [CommonComponentsModule],
})
export class CurafidaCommonModule {}
