import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurafidaAuthService } from '../../../auth/services';
import { UserRoles } from '../../../auth/entities/user';
import { ConfigService } from '../../../config/services';
import { intersection } from 'lodash-es';
import { Router } from '@angular/router';
import { CurafidaSegmentItem } from '../../entities/curafida-segment.item';
import { IconColour, IconSizeTyp } from '../../entities/icon_type';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-app-list',
    templateUrl: './curafida-app-list.component.html',
    styleUrls: ['./curafida-app-list.component.scss'],
})
export class CurafidaAppListComponent<T> {
    @Input()
    isAContrastView: boolean;

    constructor(
        public router: Router,
        protected authService: CurafidaAuthService,
        public configService: ConfigService,
    ) {}

    private _apps: CurafidaSegmentItem<T>[];

    get apps(): CurafidaSegmentItem<T>[] {
        return this._apps;
    }

    @Input() set apps(value: CurafidaSegmentItem<T>[]) {
        this.authService.getRoles().then((roles) => {
            this._apps = value.filter((app: CurafidaSegmentItem<T>) => this.shouldDisplayApp(app, roles));
        });
    }

    IconSizeTyp = IconSizeTyp;

    shouldDisplayApp = (app: CurafidaSegmentItem<T>, userRoles: UserRoles[] | string[]): boolean =>
        !!intersection(app.permittedRoles, userRoles);

    openPage(url: string): void {
        this.router.navigate([url]);
    }

    setColor() {
        return this.isAContrastView ? IconColour.PRIMARY : IconColour.PRIMARY_CONTRAST;
    }
}
