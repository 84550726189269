import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'curafida-save-cancel-button',
    templateUrl: './curafida-save-cancel-button.component.html',
    styleUrls: ['./curafida-save-cancel-button.component.scss'],
})
export class CurafidaSaveCancelButtonComponent<T> {
    @Input()
    element: T;

    @Input()
    isNewElement: boolean;
    @Input()
    isEditEnabled: boolean;
    @Input()
    formGroup: FormGroup;
    @Input()
    saveLabel = 'SAVE';

    @Input()
    isMobile: boolean;

    @Output()
    cancelEditEmit: EventEmitter<any> = new EventEmitter();
    @Output()
    toggleEditEmit: EventEmitter<any> = new EventEmitter();
    @Output()
    saveInformationEmit: EventEmitter<any> = new EventEmitter();
}
