import { Component, Input, OnInit } from '@angular/core';
import { JsonItem } from '../../entities/jsonItem';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'curafida-json-reader',
    templateUrl: './curafida-json-reader.component.html',
    styleUrls: ['./curafida-json-reader.component.scss'],
})
export class CurafidaJsonReaderComponent implements OnInit {
    @Input()
    jsonDefinition: JsonItem[];

    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    getColor(color: string): string {
        if (color) {
            return `var(--ion-color-${color})`;
        } else {
            return 'black';
        }
    }

    ngOnInit(): void {
        const position = this.activatedRoute.snapshot.queryParamMap.get('position');
        if (position) {
            setTimeout(() => {
                document.getElementById(position).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
            }, 700);
        }
    }
}
