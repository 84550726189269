import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import { DeviceProvider } from '../../entities/device/device';
import { Subscription } from 'rxjs';
import { HostListenerService } from '../../../common/services/host-listener/host-listener.service';

@Component({
    selector: 'app-data-auth',
    templateUrl: './data-auth.component.html',
    styleUrls: ['./data-auth.component.scss'],
})
export class DataAuthComponent implements OnInit {
    garminActiv = false;
    loadedFlag = false;

    device = DeviceProvider;
    deviceSelected: string;

    @Output()
    collapse = new EventEmitter<boolean>();
    isConnected: boolean;
    showRevokeInstructions: boolean;

    private visibilityChangeSubscription: Subscription;

    constructor(
        private devices: DeviceService,
        private hostListenerService: HostListenerService,
    ) {}

    async ngOnInit() {
        await this.checkAuths();
        this.visibilityChangeSubscription = this.hostListenerService.visibilityChangeEvent.subscribe(async (event) => {
            if (!(event.target as any).hidden) {
                await this.checkAuths();
            }
        });
    }

    async createAuthorization() {
        await this.devices.garminHealthAuth();
    }

    async revokeAuthorization() {
        this.loadedFlag = false;
        this.showRevokeInstructions = true;
        if (this.garminActiv) {
            this.deviceSelected = this.device.GARMIN;
            await this.devices.revokeGarminAuth();
            await this.checkAuths();
        }
    }

    async checkAuths() {
        this.garminActiv = await this.devices.checkAuthGarmin();
        this.isConnected = this.garminActiv;
        this.loadedFlag = true;
    }
}
