import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../entities/table';
import { StyleService } from '../../../common/services/style/style.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipeTableLib } from '../../pipe/pipes-table-lib.module';
import { CurafidaTranslatePipe } from '../../../translation/pluralization-translate.pipe';
import { DateTransformPipe } from '../../pipe/date.pipe';

// prettier-ignore
@Component({
    template: `
        <div>
            <div
                *ngIf="data[itemSetting.tooltip]"
                [title]="data[itemSetting.tooltip]"
                style="display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; word-wrap: anywhere"
                [ngStyle]="{ '-webkit-line-clamp': itemSetting.maxTextLines }"
                [ngClass]="{
                    'mobile-font': isMobile,
                    'web-width': !isMobile,
                    'mobile-title': itemSetting.isMobileBold && isMobile,
                }"
                >{{ data[itemSetting.prop] | curafidaTranslate | handleEmptyValue | dateTransform: itemSetting.format }}
            </div>
            <div
                *ngIf="!data[itemSetting.tooltip]"
                style="display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; word-wrap: anywhere"
                [ngStyle]="{ '-webkit-line-clamp': itemSetting.maxTextLines }"
                [ngClass]="{
                    'mobile-font': isMobile,
                    'web-width': !isMobile,
                    'mobile-title': itemSetting.isMobileBold && isMobile,
                    'web-bold': data.isBold,
                }"
                >{{
                    (itemSetting.translationPrefix
                        ? itemSetting.translationPrefix + data[itemSetting.prop]
                        : data[itemSetting.prop]
                    )
                        | curafidaTranslate
                        | handleEmptyValue
                        | dateTransform: itemSetting.format
                }}
            </div>
        </div>
    `,
    imports: [NgIf, NgStyle, NgClass, TranslateModule, PipeTableLib, CurafidaTranslatePipe, DateTransformPipe],
    standalone: true,
})
export class StringItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean; isBold: boolean };
    @Input() itemSetting: TableItem;
    isMobile: boolean;

    constructor(private styleService: StyleService) {
        this.isMobile = this.styleService.isMobile$;
    }
}
