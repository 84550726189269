import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@capgo/inappbrowser';
import { TranslateService } from '@ngx-translate/core';
import { AppInformation, AppQueryList } from '../../../../apps/generic/app-query-information.config';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { IonicColor } from '../../entities/toast/ionic-color';
import { ToastService } from '../toast-service/toast-service.service';

export interface UrlSchemaResult {
    isCustomUrlSchema: boolean;
    canBeOpened: boolean;
    appInformation?: AppInformation;
}
@Injectable({
    providedIn: 'root',
})
export class BrowserNavigationService {
    private readonly isNativePlatform: boolean;
    private readonly appList = AppQueryList;
    private platform?: string;
    private readonly log: Logger;

    constructor(
        private readonly router: Router,
        private readonly toastService: ToastService,
        private readonly loggingService: LoggingService,
        private readonly translateService: TranslateService,
    ) {
        this.isNativePlatform = Capacitor.isNativePlatform();
        if (this.isNativePlatform) {
            this.platform = Capacitor.getPlatform();
        }
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    /**
     * In the browser loads the URL in the current tab or window.
     * In a mobile app loads the URL in the InAppBrowser. Custom URL schemes can be configured to be opened in a
     * separate app on mobile, see `app-query-information.config.ts`.
     * @param url - the URL to load
     */
    openTargetSelf(url: string) {
        this.open(url, '_self');
    }

    /**
     * In the browser loads the URL in the current tab or window.
     * In a mobile app loads the URL in the InAppBrowser. Custom URL schemes can be configured to be opened in a
     * separate app on mobile, see `app-query-information.config.ts`.
     * @param url - the URL to load
     */
    openTargetBlank(url: string) {
        this.open(url, '_blank');
    }

    /**
     * Redirects in the current browsing context.
     * Mobile apps have https://localhost origin due to internal webview,
     * which is not resolvable on the internet and therefore are navigated by angular.
     */
    redirect(url: string) {
        const redirectionUrl = new URL(url);
        if (this.isNativePlatform && redirectionUrl.host?.includes('localhost')) {
            this.router
                .navigateByUrl(redirectionUrl.toString().replace(redirectionUrl.origin, ''))
                .catch(console.error);
        } else {
            this.openTargetSelf(redirectionUrl.toString());
        }
    }

    private async open(url: string, target: string) {
        if (!this.isNativePlatform) {
            window.open(url, target);
            return;
        }
        const urlSchemaResult = await this.checkForCustomUrlSchema(url);
        if (!urlSchemaResult || !urlSchemaResult.isCustomUrlSchema) {
            InAppBrowser.open({ url: url });
            return;
        }
        if (urlSchemaResult.canBeOpened) {
            AppLauncher.openUrl({ url: url });
            return;
        }
        if (urlSchemaResult.appInformation) {
            this.log.debug(`${urlSchemaResult.appInformation.name} app is missing on device`);
            this.toastService.showToast(
                this.translateService.instant('APP_NEEDED', { appName: urlSchemaResult.appInformation.name }),
                IonicColor.danger,
            );
            return;
        }
        this.log.debug(`URL: "${url}" is invalid and cannot be opened`);
        this.toastService.showToast('ERROR.LINK_INVALID', IonicColor.danger);
    }

    private async checkForCustomUrlSchema(url: string): Promise<UrlSchemaResult> {
        const whitelist = ['http://', 'https://'];
        const result: UrlSchemaResult = {
            isCustomUrlSchema: undefined,
            canBeOpened: undefined,
            appInformation: undefined,
        };
        result.isCustomUrlSchema = !whitelist.find((entry) => url.startsWith(entry));
        if (result.isCustomUrlSchema) {
            const urlSchema = url.split('://')[0];
            const appInformation = this.appList.find((app) => app.urlSchema === urlSchema);
            switch (this.platform) {
                case 'android':
                    result.canBeOpened = appInformation
                        ? await AppLauncher.canOpenUrl({ url: appInformation.androidPackageName }).then(
                              (result) => result.value,
                          )
                        : false;
                    break;
                case 'ios':
                    result.canBeOpened = appInformation
                        ? await AppLauncher.canOpenUrl({ url: url }).then((result) => result.value)
                        : false;
                    break;
                default:
                    this.log.error(`Invalid mobile platform: ${this.platform}`);
                    result.canBeOpened = false;
            }
            result.appInformation = appInformation;
            return result;
        }
        result.canBeOpened = true;
        return result;
    }
}
