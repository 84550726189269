import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    AnnouncementAssignableUserRoles,
    BasicAssignableUserRole,
    ChatAssignableUserRoles,
    KnowledgeAssignableUserRoles,
    MeetingAssignableUserRoles,
    NotesAssignableUserRoles,
    User,
    UserRoles,
} from '../../../auth/entities/user';
import { UsersService } from '../../services/user';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ItemType, TableConfig } from '../../../table/entities/table';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { RoleListModalComponent } from '../role-list-modal/role-list-modal.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ICardActionButton } from '../../../common/entities/card-action-button.interface';
import { throwError } from 'rxjs';
import { CommonComponentsModule } from '../../../common/components/common-components.module';
import { CurafidaTableComponent } from '../../../table/components/curafida-table/curafida-table.component';
import { FeatureConfigs } from '../../../common/entities/curafida-frontend-configuration';
import { PaginatedResponse } from '../../../common/entities/paginated-response';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'user-role-card',
    templateUrl: './user-role-card.component.html',
    styleUrls: ['./user-role-card.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, CurafidaTableComponent],
})
export class UserRoleCardComponent implements OnInit {
    @Input()
    isEditEnabled = false;
    @Input()
    isNewUser: boolean;
    @Input()
    featureConfigs: FeatureConfigs;
    @Input()
    isOwnDetails: boolean;
    @Input()
    hasRightToModifyUser: boolean;
    @Input()
    loggedInUser: User;
    @Input()
    url: string;
    @Input()
    user: User;
    @Input()
    isMobile: boolean;

    UserRoles = UserRoles;
    roleListConfig: TableConfig<{ role: string }[]> = new TableConfig<{ role: string }[]>();
    isRoleTableInit = false;
    rolesActionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;

    constructor(
        protected modalCtrl: ModalController,
        protected usersService: UsersService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.rolesActionButtons.push({
            icon: 'add',
            id: 'add-roles',
            isDisabled: false,
            isHidden: false,
            isIconButton: this.isMobile,
            title: 'USER.ASSIGN_ROLES',
        });
    }

    async ngOnInit(): Promise<void> {
        if (this.isNewUser) {
            this.user.roles = [this.usersService.readRoleFromUrl(this.url)];
        }
        await this.initRolesConfig();
    }

    initRoleList(): void {
        this.isRoleTableInit = false;
        if (this.user.roles) {
            this.roleListConfig.list.items = [];
            try {
                for (const role of this.user.roles) {
                    if (Object.keys(BasicAssignableUserRole).find((e) => role.toString() === e.toString())) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.featureConfigs?.announcement?.enabled &&
                        Object.keys(AnnouncementAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.featureConfigs?.chat?.enabled &&
                        Object.keys(ChatAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.featureConfigs?.note?.enabled &&
                        Object.keys(NotesAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.featureConfigs?.knowledge?.enabled &&
                        Object.keys(KnowledgeAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.featureConfigs?.course?.enabled &&
                        Object.keys(MeetingAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                }
            } catch (e) {
                this.log.error('Error in initRoleList', e);
                this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                this.roleListConfig.list = PaginatedResponse.init(this.roleListConfig.list.items);
                this.isRoleTableInit = true;
            }
        } else {
            this.roleListConfig.list = PaginatedResponse.init(this.roleListConfig.list.items);
            this.isRoleTableInit = true;
        }
    }

    async changeRole(): Promise<void> {
        const selectedRole: { name: string }[] = [];
        if (this.roleListConfig.list.items) {
            for (const role of this.roleListConfig.list.items) {
                const newRole = { name: role.role };
                selectedRole.push(newRole);
            }
        }
        const modal = await this.modalCtrl.create({
            component: RoleListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                alreadyAssignedUserRoles: selectedRole,
                isMultipleChoice: true,
                onlyGroupsWithMembership: true,
                showSelectedGroup: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.log.debug('data', data);
            try {
                this.changeUserRoles(data, this.roleListConfig.list.items, this.isNewUser);
            } catch (e) {
                this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                this.log.error('Error in changeRole', e);
            } finally {
                this.roleListConfig.list = PaginatedResponse.init(data);
            }
        }
    }

    changeUserRoles(newRolesList: { role: string }[], previousRoleList: { role: string }[], isNewUser: boolean) {
        const rolesToAdd: BasicAssignableUserRole[] = [];
        const rolesToRemove: BasicAssignableUserRole[] = [];
        for (const previousRole of previousRoleList) {
            if (!newRolesList.find((i) => i.role === previousRole.role)) {
                if (!isNewUser) {
                    rolesToRemove.push(previousRole.role as BasicAssignableUserRole);
                }
            }
        }
        for (const newRole of newRolesList) {
            if (!previousRoleList.find((i) => i.role === newRole.role)) {
                if (!isNewUser) {
                    rolesToAdd.push(newRole.role as BasicAssignableUserRole);
                }
            }
        }
        if (!isNewUser) {
            this.loadingService.startLoadingModal();
            try {
                if (rolesToAdd.length > 0) {
                    this.usersService.assignRolesToUser$(this.user.username, { roleNames: rolesToAdd }).subscribe();
                }
                if (rolesToRemove.length > 0) {
                    this.usersService
                        .removeRolesFromUser$(this.user.username, { roleNames: rolesToRemove })
                        .subscribe();
                }
            } catch (error) {
                this.log.error('Error in showDeletePrompt', error);
                this.toastService.showToast('USER.ROLLE.UPDATE_ERROR', IonicColor.danger);
                return throwError(() => error);
            } finally {
                this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                this.loadingService.stopLoadingModal();
            }
        }
    }

    private async initRolesConfig() {
        this.roleListConfig.emptyListLabel = 'USER.ROLLE.ANY_ASSIGN';
        this.roleListConfig.itemSettings = [
            {
                id: 'role',
                prop: 'role',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
            },
        ];
        this.initRoleList();
    }
}
