import { Component, OnInit } from '@angular/core';
import { Logger, LoggingService, LogObject } from '../../logging.service';

@Component({
    selector: 'lib-curafida-footer-console-log',
    templateUrl: './footer-console-log.component.html',
    styleUrls: ['./footer-console-log.component.scss'],
})
export class FooterConsoleLogComponent implements OnInit {
    logsObjects: LogObject[] = [];
    isLoggingViewEnabled = false;
    protected readonly log: Logger;

    constructor(private loggingService: LoggingService) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.loggingService
            .getSwitchLogView()
            .subscribe((isLoggingViewEnabled) => (this.isLoggingViewEnabled = isLoggingViewEnabled));
        this.loggingService.getLoggingSubject().subscribe(async (logObj) => {
            this.logsObjects.push(logObj);
        });
    }
}
