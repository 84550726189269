import { MigrationInterface, QueryRunner } from 'typeorm';

export class AddCreatedAtLocal1594910882080 implements MigrationInterface {
    name = 'AddCreatedAtLocal1594910882080';

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`ALTER TABLE Patient ADD createdAtLocal TIMESTAMP;`);
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query('ALTER TABLE Patient DROP COLUMN createdAtLocal;');
    }
}
