import { User } from '../../auth/entities/user';
import { ConsultationEvent } from './consultation-event';
import { Group } from '../../user/entities/group';
import { Chat } from '../../chat/entities/chat';
import { ConsultationContent } from './consultation-content';

export enum ConsultationStateEnum {
    CONSULTATION_CREATED = 'CONSULTATION_CREATED',
    REQUEST_IN_CREATION = 'REQUEST_IN_CREATION',
    REQUEST_UNDER_REVIEW = 'REQUEST_UNDER_REVIEW',
    FINDING_IN_CREATION = 'FINDING_IN_CREATION',
    CONSULTATION_IN_CLOSING = 'CONSULTATION_IN_CLOSING',
    CONSULTATION_FINISHED = 'CONSULTATION_FINISHED',
    CONSULTATION_CANCELED = 'CONSULTATION_CANCELED',
    CONSULTATION_DELETED = 'CONSULTATION_DELETED',
    CONSULTATION_DECLINED = 'CONSULTATION_DECLINED',
    CONSULTATION_REVOKED = 'CONSULTATION_REVOKED',
}

export class Consultation {
    uuid: string;
    requesterGroupUuid: string;
    requester: Group;
    consultantGroupUuid: string;
    consultant: Group;
    patientUsername: string;
    patient: User;
    chatId?: string;
    chat?: Chat;
    contents?: ConsultationContent[];
    state: ConsultationStateEnum;
    consultationEvents: ConsultationEvent[];
    consultationSubject: string;
    caseNumber?: string;
    justifyingIndicationNotes?: string;
    providedConsultantServices?: SelectedService[];
    created_at: string;
    updated_at: string;
}

export class SelectedService {
    name: string;
    description?: string;
    selectedAt?: string;
    selectedBy?: string;
}

export class CreateConsultationDto {
    requester: string;
    consultant: string;
    patient: string;
    consultationSubject: string;

    constructor(requester: string, consultant: string, patient: string, consultationSubject: string) {
        this.requester = requester;
        this.consultant = consultant;
        this.patient = patient;
        this.consultationSubject = consultationSubject;
    }
}

export class UpdateConsultationRequestDto {
    caseNumber?: string;
}
