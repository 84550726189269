import { BaseTag } from '../../therapy/entities/tag/base-tag.entity';
import { HypermediaResource } from '../../hateoas/hateoas.model';

export interface NoteTagsState {
    moduleConfig: NoteCharacteristicModuleResource;
    characteristic: NoteTagCategoriesResource;
    selected: NoteTagCategory;
}

export const noteTagsInitialState: NoteTagsState = {
    moduleConfig: { activatedGroupPath: undefined, _links: {} },
    characteristic: { categories: [], _links: {} },
    selected: undefined,
};

export interface NoteCharacteristicModuleResource extends HypermediaResource {
    activatedGroupPath: string;
}

export interface NoteTagCategoriesResource extends HypermediaResource {
    categories: NoteTagCategory[];
}

export interface NoteTagCategory extends HypermediaResource {
    label: string;
    tags?: NoteTag[];

    isDeleteIconDisabled: boolean;
    areSubLinesHidden: boolean;
    isTagsLoading: boolean;
}

export interface NoteTag extends HypermediaResource, BaseTag {
    areSubLinesHidden: boolean;
}

export class NoteTagLabelDto {
    label: string;
}

export class NoteTagDto {
    uuid: string;
    label: string;
}

export enum NoteSortProperty {
    UPDATED_AT = 'updated_at',
    CREATED_AT = 'created_at',
    USER_FULL_NAME = 'userFullName',
    USERNAME = 'username',
    CREATOR_FULL_NAME = 'creatorFullName',
    EDITOR_FULL_NAME = 'editorFullName',
}
