import { ExerciseSession } from './exercise-session';
import { ExerciseSessionUserState } from './exercise-session-state';
import { PhysicalExerciseGoalDto } from '../exerciseGoal/exerciseGoalDto';
import { ExerciseSessionUserResult } from './exercise-session-user-result';
import { FeedBack } from '../feedback';
import { User } from '../../../auth/entities/user';
import { FeedbackCommentDto } from '../feedback/feedback-comment';
import { HypermediaLinks, HypermediaResource } from '../../../hateoas/hateoas.model';
import { ParticipationDto } from './participation.dto';
import { Type } from 'class-transformer';

export enum ExerciseSessionOfUserSortProperties {
    START_TIME = 'esa.startTime',
    DELAYED_TIME = 'esa.delayedTime',
    END_TIME = 'esa.endTime',
    TITLE = 'es.title',
    DESCRIPTION = 'es.description',
    EXERCISE_SESSION_TYPE = 'es.exerciseSessionType',
    EXERCISE_TYPE = 'es.exerciseType',
    EXERCISE_SESSION_STATE = 'es.exerciseSessionState',
    RESPONSIBLE = 'es.responsible',
    LATEST_STATE_CHANGE = 'es.latestStateChange',
    START_EXERCISE_SESSION_CALENDAR_EVENT = 'esce.startTime',
    END_EXERCISE_SESSION_CALENDAR_EVENT = 'esce.endTime',
}

export class ExerciseSessionOfUser implements HypermediaResource {
    static userAttendanceRequiredStates = [
        ExerciseSessionUserState.NOT_PLANNED,
        ExerciseSessionUserState.SCHEDULED,
        ExerciseSessionUserState.PLANNED,
        ExerciseSessionUserState.ACTIVE,
        ExerciseSessionUserState.FINISHED,
        ExerciseSessionUserState.NO_SHOW,
        ExerciseSessionUserState.TECH_PROBLEM,
    ];

    static finishedStates = [
        ExerciseSessionUserState.FINISHED,
        ExerciseSessionUserState.CANCELLED,
        ExerciseSessionUserState.POSTPONED,
        ExerciseSessionUserState.PATIENT_CANCELLED,
        ExerciseSessionUserState.NO_SHOW,
    ];

    id: number;
    exerciseSession_id: string;
    exerciseGoal: PhysicalExerciseGoalDto;
    @Type(() => ExerciseSession)
    exerciseSession: ExerciseSession;
    @Type(() => ExerciseSession)
    originalExerciseSession: ExerciseSession;
    exerciseSessionUserState: ExerciseSessionUserState;
    exerciseSessionUserResults: ExerciseSessionUserResult[];
    @Type(() => FeedBack)
    feedback: FeedBack;
    username: string;
    @Type(() => User)
    user: User;
    _links: HypermediaLinks;
    participants?: ParticipationDto;

    static isExerciseSessionOfUserPlanned(userExerciseSession: ExerciseSessionOfUser): boolean {
        switch (userExerciseSession.exerciseSessionUserState) {
            case ExerciseSessionUserState.PLANNED:
            case ExerciseSessionUserState.ACTIVE:
                return true;
            default:
                return false;
        }
    }
}

export class FrontendExerciseSessionOfUser extends ExerciseSessionOfUser {
    exerciseName: string;
    exerciseSessionUpdateTime: string;
    borg: number;
    comment: FeedbackCommentDto;
    commentFlag: string;

    static compareFeedbackExerciseSessions(
        fes1: FrontendExerciseSessionOfUser,
        fes2: FrontendExerciseSessionOfUser,
    ): number {
        if (fes1.exerciseSession?.therapyExercise?.order < fes2.exerciseSession?.therapyExercise?.order) {
            return -1;
        }
        if (fes1.exerciseSession?.therapyExercise?.order > fes2.exerciseSession?.therapyExercise?.order) {
            return 1;
        }
        return 0;
    }
}
