import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutingSegment } from './entities/routing-segment';
import { RedirectGuard } from '../guard/redirect-guard.service';

const routes: Routes = [
    {
        path: RoutingSegment.MANUAL,
        component: RedirectGuard,
        canActivate: [RedirectGuard],
        data: {
            externalUrl: 'https://handbuch.ztm.de/curafida-ueberblick',
        },
    },
    {
        path: RoutingSegment.IMPRINT,
        loadComponent: () => import('./pages/imprint/imprint.page').then((m) => m.ImprintPage),
    },
    {
        path: RoutingSegment.USER_AGREEMENT,
        loadComponent: () => import('./pages/user-agreement/user-agreement.page').then((m) => m.UserAgreementPage),
    },
    {
        path: RoutingSegment.TERMS_AND_CONDITIONS,
        loadComponent: () =>
            import('./pages/terms-and-conditions/terms-and-conditions.page').then((m) => m.TermsAndConditionsPage),
    },
    {
        path: RoutingSegment.STANDARD_PRIVACY,
        loadComponent: () => import('./pages/privacy/privacy.page').then((m) => m.PrivacyPage),
    },
    {
        path: RoutingSegment.CONFORMITY,
        loadComponent: () => import('./pages/conformity/conformity.page').then((m) => m.ConformityPage),
    },
    {
        path: RoutingSegment.PRIVACY,
        redirectTo: `/${RoutingSegment.COMMON}/${RoutingSegment.STANDARD_PRIVACY}`,
        pathMatch: 'full',
    },
    {
        path: RoutingSegment.CONTACT,
        loadComponent: () => import('./pages/contact/contact.page').then((m) => m.ContactPage),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CommonModuleRoutingModule {}
