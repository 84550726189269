import { enableProdMode } from '@angular/core';
import { CurafidaFrontendConfiguration } from '../common/entities/curafida-frontend-configuration';
import { mergeDeep } from '../kc-auth/entities/curafida-keycloak-config.type';

export async function initEnvironment(environment: CurafidaFrontendConfiguration): Promise<void> {
    environment = await readRuntimeEnvironmentConfig(environment);
    if (environment.developmentStage === 'beta' || environment.developmentStage === 'prod') {
        enableProdMode();
    }
    initIndexHmtlFile(environment);
}

async function readRuntimeEnvironmentConfig(
    environment: CurafidaFrontendConfiguration,
): Promise<CurafidaFrontendConfiguration> {
    try {
        // 'assets/extra/environment.json' is the main file for runtime configuration
        const response = await fetch('assets/extra/environment.json');
        const runtimeEnvJson: CurafidaFrontendConfiguration = await response?.json();
        if (runtimeEnvJson) {
            Object.assign(environment, mergeDeep(environment, runtimeEnvJson));
        }
    } catch (err) {
        console.warn('Fetching assets/extra/environment.json failed');
    }
    return environment;
}

function initIndexHmtlFile(environment: CurafidaFrontendConfiguration) {
    // Set HTML title
    if (environment?.appDisplayName) {
        document.title = environment.appDisplayName;
    }
    // Set Browser Favicon
    if (environment?.theme?.faviconUrl) {
        const imgElem = document.createElement('link');
        imgElem.rel = 'icon';
        imgElem.type = 'image/png';
        imgElem.href = environment.theme.faviconUrl;
        document.head.appendChild(imgElem);
    }
}
