import { SafeUrl } from '@angular/platform-browser';
import { Url } from 'url';

export class ContentDto {
    mimeType: string;
    description: string;
    url: Url | SafeUrl | string;
    data: string;
    byteSize: number;
    jsonData: any;
}

export class ExerciseContentMetaData {
    order: number;
    contentMetaDataType: ContentMetaDataType | string;
    name?: string;

    constructor(contentMetaDataType: ContentMetaDataType | string, order: number, name: string) {
        this.order = order;
        this.contentMetaDataType = contentMetaDataType;
        this.name = name;
    }

    static isStandardDataType(contentMetaDataType: ContentMetaDataType) {
        return !!Object.values(ContentMetaDataType).find((i) => i === contentMetaDataType);
    }
}

export enum ContentMetaDataType {
    START = 'START',
    THUMBNAIL = 'THUMBNAIL',
    MIDDLE = 'MIDDLE',
    END = 'END',
    PLEASE_MIND = 'PLEASE_MIND',
    VIDEO = 'VIDEO',
    OTHER = 'OTHER',
}
