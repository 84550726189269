import { MigrationInterface, QueryRunner } from 'typeorm';

export class AddCurafidaQuestionnaire1631018203088 implements MigrationInterface {
    name = 'AddCurafidaQuestionnaire1631018203088';

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(
            `CREATE TABLE IF NOT EXISTS "CurafidaQuestionnaire" ("uuid" varchar PRIMARY KEY NOT NULL, "fhirQuestionnaire" text NOT NULL, "curafidaQuestionnaireType" varchar NOT NULL, "created_at" text NOT NULL, "updated_at" varchar NOT NULL)`,
            undefined,
        );
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`DROP TABLE "CurafidaQuestionnaire"`, undefined);
    }
}
