// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-header {
  --background: var(--ion-color-primary);
  --color: white;
}

.toolbar-button {
  min-width: 30%;
}

@media (min-width: 901px) {
  .wrapper-header {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    border: 0 solid transparent;
  }
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/toolbar-modal/toolbar-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI;IACI,kBAAA;IACA,iBAAA;IACA,kBAAA;IACA,gBAAA;IACA,2BAAA;EACN;AACF","sourcesContent":[".wrapper-header {\n    --background: var(--ion-color-primary);\n    --color: white;\n}\n\n.toolbar-button {\n    min-width: 30%;\n}\n\n@media (min-width: 901px) {\n    .wrapper-header {\n        position: relative;\n        margin-left: auto;\n        margin-right: auto;\n        margin-bottom: 0;\n        border: 0 solid transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
