import { NgModule } from '@angular/core';
import { FooterConsoleLogComponent } from './components/footer-console-log/footer-console-log.component';
import { UserComponentsModule } from '../user/components/user-components.module';
import { CurafidaCommonModule } from '../common/curafida-common.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [FooterConsoleLogComponent],
    imports: [IonicModule, FormsModule, UserComponentsModule, CommonModule, TranslateModule, CurafidaCommonModule],
    exports: [FooterConsoleLogComponent],
})
export class LoggingModule {}
