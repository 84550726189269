import { MigrationInterface, QueryRunner, TableColumn } from 'typeorm';

export class AddPdfContentUuid1591191931576 implements MigrationInterface {
    name = 'AddPdfContentUuid1591191931576';

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.addColumn(
            'Measurement',
            new TableColumn({
                name: 'pdfContentUuid',
                type: 'varchar',
                isNullable: true,
            }),
        );
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.dropColumn('Measurement', 'pdfContentUuid');
    }
}
