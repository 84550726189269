import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'curafida-text-area-full-height',
    templateUrl: './curafida-text-area-full-height.component.html',
    styleUrls: ['./curafida-text-area-full-height.component.scss'],
})
export class CurafidaTextAreaFullHeightComponent {
    @Input() text: string;
    @Output() updateText: EventEmitter<string> = new EventEmitter<string>();

    constructor(private modalController: ModalController) {}

    backToForm() {
        this.modalController.dismiss(this.text);
    }
}
