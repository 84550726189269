import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TutorialDefinition } from '../../../measurement/entities/tutorial-slide';
import { ModalController, NavParams } from '@ionic/angular';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    selector: 'loading-tutorial-modal',
    templateUrl: './loading-tutorial-modal.component.html',
    styleUrls: ['./loading-tutorial-modal.component.scss'],
})
export class LoadingTutorialModalComponent implements OnInit {
    tutorialDefinition: TutorialDefinition;
    title = '';
    @ViewChild('swiper')
    private _swiperRef: ElementRef | undefined;
    swiper?: Swiper;
    options: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: false,
        pagination: false,
    };
    isBeginning = true;
    isEnd = false;
    currentIndex = 0;
    isTutorialChecked = false;

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
    ) {}

    async ngOnInit() {
        this.tutorialDefinition = this.params.get('modalDefinition');
        if (this.tutorialDefinition) this.title = this.tutorialDefinition.title;
        const swiperEl = this._swiperRef?.nativeElement;
        if (swiperEl) {
            Object.assign(swiperEl, this.options);
            swiperEl.initialize();
        }
    }
}
