// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100% !important;
  flex-direction: row;
}

.mobile-width {
  max-width: 320px;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-save-cancel-button/curafida-save-cancel-button.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".button-container {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: flex-end;\n    justify-content: flex-end;\n    width: 100% !important;\n    flex-direction: row;\n}\n\n.mobile-width {\n    max-width: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
