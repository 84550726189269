export enum ModalTyp {
    INFORMATION = 'modal-information',
    INFORMATION_EXPANDING = 'modal-information-expanding',
    INFORMATION_REDUCED = 'modal-information-reduced',
    STANDARD = 'modal-standard',
    MEASUREMENT_INPUT = 'modal_measurement_input',
    DISCOVER_DEVICE = 'modal-discover-devices',
}

export enum ModalMessage {
    DISCONNECT_DEVICE = 'DISCONNECT_DEVICE',
    SEND_MEASUREMENT = 'SEND_MEASUREMENT',
    ENABLE_BLUETOOTH_SETTINGS = 'ENABLE_BLUETOOTH_SETTINGS',
    ENABLE_LOCATION_SETTINGS = 'ENABLE_LOCATION_SETTINGS',
    IS_NOT_CONNECTED = 'IS_NOT_CONNECTED',
    DEVICE_INFO = 'DEVICE_INFO',
}
