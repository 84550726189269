export class ExerciseSessionCalendarEvent {
    exerciseSessionId: number;
    // ISO8601 string
    startTime: string;
    // ISO8601 string
    endTime: string;
    // username
    lastEditedBy?: string;

    constructor(exerciseSessionId: number, startTime: string, endTime: string, lastEditedBy?: string) {
        this.exerciseSessionId = exerciseSessionId;
        this.startTime = startTime;
        this.endTime = endTime;
        this.lastEditedBy = lastEditedBy;
    }
}
